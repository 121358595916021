import React from 'react';
import tw from 'twin.macro'
import styled from 'styled-components/macro'
import {
    COPYRIGHT,
    CONTACT_US,
    CLOUD8_EMAIL,
    HELP_LINK,
    HELP_LINK_LABEL
} from 'utils/messages';

const Container = styled.div`
    ${tw`h-68 font-secondary text-dark-gray text-small px-16 pb-6`}
`

const InternalContainer = styled.div`
    ${tw`mb-3 flex justify-between`}
`

const CopyrightContainer = styled.div`
    ${tw`text-center`}
`

export const Footer = () => (
    <Container>
        <InternalContainer>
            <a href={`mailto:${CLOUD8_EMAIL}`}>
                {CONTACT_US}
            </a>
            <a href={HELP_LINK} target='_blank' rel='noopener noreferrer'>
                {HELP_LINK_LABEL}
            </a>
        </InternalContainer>
        <CopyrightContainer>
            {COPYRIGHT}
        </CopyrightContainer>
    </Container>
);