import React from 'react';
import './TableRow.css';
import PropTypes from 'prop-types';
import {TableRowMenu} from "components/global/Table2/TableRowMenu/TableRowMenu";

const TableRow = ({
	rowData,
	rows,
	linkIndex,
    menuItems,
    isRowMenuCollapsed,
	contextType,
	highlightedRowId,
    handleTableRowClick,
    dataTestId
}) => {
    const preparedRow = Object.entries(rowData)
        .filter(item => rows.includes(item[0]))
        .sort((a, b) => rows.indexOf(a[0]) - rows.indexOf(b[0]));

    const isRowHighlighted = (Boolean(highlightedRowId) && highlightedRowId === rowData.user_id) 
		? 'highlighted-row'
        : ''

    return (
        <div
            className={`tableRowContainer ${isRowHighlighted} ${preparedRow.length === 4 ? 'tableRowContainer-4' : 'tableRowContainer-6'}`}
            data-testid="table-row"
        >
            {preparedRow.map(([key, value], index) =>
                linkIndex && index === linkIndex ?
                    <div key={key} className="tableRowLinkItem" data-testid={`table-row-${index}`}>
                        <a id="table-row-item" onClick={(event) => handleTableRowClick(event, contextType, rowData)}>
                            {value}
                        </a>
                    </div>
                    : <div
                        key={key}
                        className='tableRowItem'
                        onClick={(event) => handleTableRowClick(event, contextType, rowData)}
                        data-testid={`table-row-${index}`}
                    >{value}</div>)
            }
            {
                menuItems?.length ?
                    <div className='table-row-menu'>
                        <TableRowMenu
                            items={menuItems}
                            rowData={rowData}
                            isCollapsed={isRowMenuCollapsed}
                            dataTestId={dataTestId}
                        />
                    </div>
                    : null
            }
        </div>
    );
};

export default TableRow;

TableRow.propTypes = {
    rowData: PropTypes.object.isRequired,
    rows: PropTypes.arrayOf(PropTypes.string).isRequired,
    highlightedRowId: PropTypes.number,
    menuItems: PropTypes.arrayOf(PropTypes.object),
    isRowMenuCollapsed: PropTypes.bool,
    dataTestId: PropTypes.string
}
