import React from 'react'
import { Accordion } from 'components/commonFields/Accordion'
import PropTypes from 'prop-types'

export const Body = ({ template, dataTestId }) => {
	return (
		<Accordion
			accordionElements={template}
			dataTestId={`${dataTestId}-accordion`}
		/>
	)
}

Body.propTypes = {
	template: PropTypes.array,
	dataTestId: PropTypes.string,
}
