import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import './EditFeatures.css';
import { SwitcherItemsContainer } from '../../global/SwitcherComponent/SwitcherItemsContainer/SwitcherItemsContainer';
import {
    BENEFIT_CONFIGURATION,
    CLIENT_MANAGEMENT,
    USER_MANAGEMENT,
    ADD_CHECKBOX,
    EDIT_CHECKBOX,
    READ_CHECKBOX,
    DELETE_CHECKBOX,
    USERS_GROUP,
    ROLES_GROUP,
    EDIT_FEATURES,
    BENEFITS_GROUP
} from 'utils/messages';
import { editRoleErrors } from 'store/selectors/selectors';
import { getSwitcherComponents } from 'utils/helpers/features.helper';
import {ErrorMessage} from "components/global/ErrorMessage/ErrorMessage";

const getPreparedData = (checkedArray) => {
    if (!checkedArray.length) return {
        view: 0,
        create: 0,
        update: 0,
        delete: 0
    };

    return checkedArray?.reduce((acc, item) => {
        if (item === ADD_CHECKBOX) acc.create = 1;
        if (item === EDIT_CHECKBOX) acc.update = 1;
        if (item === READ_CHECKBOX) acc.view = 1;
        if (item === DELETE_CHECKBOX) acc.delete = 1;

        if (!acc.create) acc.create = 0;
        if (!acc.update) acc.update = 0;
        if (!acc.view) acc.view = 0;
        if (!acc.delete) acc.delete = 0;

        return acc;
    }, {});
};

const getDefaultCheckedData = (actions) => {
    const defaultChecked = [];

    if (actions?.create === 1) defaultChecked.push(ADD_CHECKBOX);
    if (actions?.update === 1) defaultChecked.push(EDIT_CHECKBOX);
    if (actions?.view === 1) defaultChecked.push(READ_CHECKBOX);
    if (actions?.delete === 1) defaultChecked.push(DELETE_CHECKBOX);

    return defaultChecked;
};

const getExpandedItems = ({ actions, errors }) => {
    const expanded = [];
    if (actions?.benefits) expanded.push(1);
    if (actions?.users || actions?.roles || errors?.length) expanded.push(2);
    return expanded;
}

export const EditFeatures = ({ actions }) => {
    const dispatch = useDispatch();
    const { actions: errors } = useSelector(editRoleErrors);
    const [checkedUsers, setCheckedUsers] = useState([]);
    const [checkedRoles, setCheckedRoles] = useState([]);
    const [checkedBenefits, setCheckedBenefits] = useState([]);

    useEffect(() => {
        if (actions && !errors?.length) {
            setCheckedUsers(getDefaultCheckedData(actions?.users?.actions));
            setCheckedRoles(getDefaultCheckedData(actions?.roles?.actions));
            setCheckedBenefits(getDefaultCheckedData(actions?.benefits?.actions));
        }
    }, [actions, errors]);

    useEffect(() => {
        Object.entries(getPreparedData(checkedUsers)).map(([key, item]) => {
            return dispatch({
                type: 'editRole/setUsersActions',
                payload: { [key]: item }
            })
        });

        Object.entries(getPreparedData(checkedRoles)).map(([key, item]) => {
            return dispatch({
                type: 'editRole/setRolesActions',
                payload: { [key]: item }
            })
        });

        Object.entries(getPreparedData(checkedBenefits)).map(([key, item]) => {
            return dispatch({
                type: 'editRole/setBenefitsActions',
                payload: { [key]: item }
            })
        });
    }, [checkedUsers, checkedRoles, checkedBenefits]);

    const handleUsersChanged = checked => {
        dispatch({
            type: 'editRole/setUsersActions',
            payload: getPreparedData(checked)
        });
    };

    const handleRolesChanged = checked => {
        dispatch({
            type: 'editRole/setRolesActions',
            payload: getPreparedData(checked)
        });
    };

    const handleBenefitsChanged = checked => {
        dispatch({
            type: 'editRole/setBenefitsActions',
            payload: getPreparedData(checked)
        });
    };

    const items = [
        {
            label: BENEFIT_CONFIGURATION,
            content: null
        },
        {
            label: CLIENT_MANAGEMENT,
            content: getSwitcherComponents({
                items: [
                    {
                        checked: checkedBenefits,
                        name: BENEFITS_GROUP,
                        onChange: handleBenefitsChanged
                    }
                ]
            })
        },
        {
            label: USER_MANAGEMENT,
            content: getSwitcherComponents({
                items: [
                    {
                        checked: checkedUsers,
                        name: USERS_GROUP,
                        onChange: handleUsersChanged
                    },
                    {
                        checked: checkedRoles,
                        name: ROLES_GROUP,
                        onChange: handleRolesChanged
                    }
                ],
                errors
            })
        }
    ];

    const handleOnSwitcherContainerChange = ({ isActivated }) => {
        if (!isActivated) {
            dispatch({
                type: 'editRole/setUsersActions',
                payload: getPreparedData([])
            });
            dispatch({
                type: 'editRole/setRolesActions',
                payload: getPreparedData([])
            });
        }
    }

    return (
        <div className='edit-role-features-container'>
            <div className='edit-role-features-header' data-testid='edit-role-features-header'>{EDIT_FEATURES}</div>
            <SwitcherItemsContainer 
                items={items} 
                checkedItems={getExpandedItems({ actions, errors })}
                onSwitcherContainerChanged={handleOnSwitcherContainerChange}
            />
            {errors?.length ?
                errors.map((item, index) => {
                    return (
                        <div className='edit-role-features-errors-container'>
                            <ErrorMessage key={index} message={item} />
                        </div>
                    )
                }) : null
            }
        </div>
    );
};

EditFeatures.propTypes = {
    actions: PropTypes.object
}