import React, { useEffect } from 'react'
import Loader from 'components/global/Loader/Loader'
import { FeaturesDataAccessContent } from './FeatureDataAccessContent/FeatureDataAccessContent'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import {ButtonLabels, ButtonSizes, ButtonTypes} from 'utils/constants'
import { navigate } from 'gatsby'
import { toast } from 'react-toastify'
import { newUser } from 'store/selectors/selectors'
import './FeatureDataAccess.css'
import {Button} from "components/buttons/Button/Button";

export const FeatureDataAccess = () => {
    const dispatch = useDispatch()
    const { handleSubmit } = useForm()
	
    const { 
        isSending,
		isLoading,
        isError, 
        data, 
        user_type_id, 
        role_name,
		permissions,
        successMessage 
    } = useSelector(newUser)

	const permissionsData = {};
	permissions?.forEach(section => {
		const sectionFeatures = section?.features;
		const sectionPermissions = {};

		sectionFeatures.forEach(permission => {
			sectionPermissions[permission?.label?.toLowerCase()] = permission?.content;
		})

		permissionsData[section?.label] = sectionPermissions;
	})
    
	useEffect(() => {
		if (!isError && Boolean(successMessage)) {
			navigate('/app/users');
			toast.success(successMessage)
		}
	}, [isError, successMessage])

	const onSubmit = async () => {
		dispatch({
			type: 'newUser/setSubmittedData',
			payload: {
				data,
				user_type_id,
				role_name,
			},
		})
	}

	const handleFeatureSwitcherChange = (features) => {
		dispatch({
			type: 'newUser/setStepData',
			payload: features,
		})
	}

	const handleReturnBack = (event) => {
		event.preventDefault()
		dispatch({
            type: 'newUser/closeStep',
            payload: { step: 2 }
        });
        dispatch({
            type: 'newUser/openStep',
            payload: { step: 1 }
        });
	}

	return (!isSending && !isLoading) ? (
		<form>
			<FeaturesDataAccessContent
				handleFeatureSwitcherChange={handleFeatureSwitcherChange}
				featureData={permissionsData}
			/>
			<div className="mb-14 mt-11 flex">
				<Button
					handleOnClick={handleReturnBack}
					label={ButtonLabels.Back}
					type={ButtonTypes.Secondary}
					size={ButtonSizes.Medium}
					dataTestId='button-step-back'
				/>
				<div className='ml-2'>
					<Button
						handleOnClick={handleSubmit(onSubmit)}
						label={ButtonLabels.Save}
						type={ButtonTypes.Primary}
						size={ButtonSizes.Medium}
						dataTestId='button-step-next'
					/>
				</div>
			</div>
		</form>
	) : (
		<div className="loader-container">
			<Loader />
		</div>
	)
}
