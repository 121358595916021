import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from 'gatsby';
import { Link, Element } from 'react-scroll'
import './editRole.css';
import Layout from 'components/container/Layout';
import PageHeader from 'components/global/PageHeader/PageHeader';
import { commonUserTypes, roleData } from 'store/selectors/selectors';
import { useGetRoleData } from 'hooks/useGetRoleData';
import Loader from 'components/global/Loader/Loader';
import { useForm } from 'react-hook-form';
import { EDIT_ROLE } from 'common/endpoints';
import { EditRoleInfo } from 'components/forms/EditRoleInfo/EditRoleInfo';
import {ButtonLabels, ButtonSizes, ButtonTypes} from 'utils/constants';
import {
    EDIT_ROLE_INFO,
    EDIT_FEATURES,
    EDIT_USERS
} from 'utils/messages';
import { EditFeatures } from 'components/forms/EditFeatures/EditFeatures';
import { useUpdateForm } from 'hooks/useUpdateForm';
import { EditUsers } from 'components/forms/EditUsers';
import {Button} from "components/buttons/Button/Button";
import {Icons, IconsNames} from "utils/icons";

export const EditRolePage = () => {
    const dispatch = useDispatch();
    const { handleSubmit, register } = useForm();
    const role = useSelector(roleData);
    const userTypes = useSelector(commonUserTypes);
    const { loaded: isRoleLoaded } = useGetRoleData({ id: role?.id });
    const { callback: updateForm } = useUpdateForm();
    const [showLoader, setShowLoader] = useState(false);
    const [activeLink, setActiveLink] = useState('info');

    useEffect(() => {
        return () =>
            dispatch({
                type: 'editRole/setClearStore'
            });
    }, []);

    const handleCLick = (e) => {
        setActiveLink(e.target.id);
    };

    const handleBack = async (event) => {
        event.preventDefault()
        dispatch({
            type: 'editRole/setClearStore'
        });
        await navigate('/app/roles');
    };

    const onSubmit = async data => {
        setShowLoader(true);

        dispatch({
            type: 'editRole/setEditedRoleData',
            payload: data
        });

        const selectedUsers = role.checkboxes.map((checkbox) => checkbox.id);

        await updateForm({
            path: `${EDIT_ROLE}/${role?.id}`,
            data: {...role, users: selectedUsers, ...data},
            errorsActionType: 'editRole/setErrors',
            navigatePath: '/app/roles'
        });

        setShowLoader(false);
    };

    const EditRole = (
        <div className='edit-role-main-container'>
            <div className='edit-role-header-container'>
                <PageHeader title={role?.oldName} />
                <ul className='edit-role-navigation-container' data-testid='edit-role-navigation-container'>
                    <Link
                        id='info'
                        to='info'
                        smooth={true}
                        duration={250}
                        containerId='containerElement'
                        onClick={handleCLick}
                        className={activeLink === 'info' ? 'active' : 'edit-role-navigation-link'}
                    >
                        <li data-testid='edit-role-navigation-info'>
                                {EDIT_ROLE_INFO}
                        </li>
                    </Link>
                    <Link
                        id='features'
                        to='features'
                        smooth={true}
                        duration={250}
                        containerId='containerElement'
                        onClick={handleCLick}
                        className={activeLink === 'features' ? 'active' : 'edit-role-navigation-link'}
                    >
                        <li data-testid='edit-role-navigation-features'>
                                {EDIT_FEATURES}
                        </li>
                    </Link>
                    <Link
                        id='users'
                        to='users'
                        smooth={true}
                        duration={250}
                        containerId='containerElement'
                        onClick={handleCLick}
                        className={activeLink === 'users' ? 'active' : 'edit-role-navigation-link'}
                    >
                        <li data-testid='edit-role-navigation-users'>
                                {EDIT_USERS}
                        </li>
                    </Link>
                </ul>
            </div>
            {isRoleLoaded && !showLoader ?
                <Element id='containerElement' className='edit-role-scroll-container'>
                    <form
                        className='edit-role-form-container'
                        data-testid='edit-role-form'
                    >
                        <>
                            <Element name='info'>
                                <EditRoleInfo
                                    role={role}
                                    userTypes={userTypes}
                                    register={register}
                                />
                            </Element>
                            <Element name='features'>
                                <EditFeatures actions={role?.permissions} />
                            </Element>
                            <Element name='users'>
                                <EditUsers users={role?.users} />
                            </Element>
                            <div className='edit-role-buttons-container'>
                                <Button
                                    handleOnClick={handleSubmit(onSubmit)}
                                    label={ButtonLabels.Save}
                                    type={ButtonTypes.Primary}
                                    size={ButtonSizes.Medium}
                                    icon={Icons[IconsNames.Check]}
                                    dataTestId='button-step-next'
                                />
                                <div className='edit-role-back-button'>
                                    <Button
                                        handleOnClick={handleBack}
                                        label={ButtonLabels.Back}
                                        type={ButtonTypes.Secondary}
                                        size={ButtonSizes.Medium}
                                        dataTestId='button-step-back'
                                    />
                                </div>
                            </div>
                        </>
                    </form>
                </Element>
                :
                <div className='h-screen'>
                    <Loader />
                </div>
            }
        </div>
    );

    return <Layout component={EditRole} />;
}