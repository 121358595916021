import React, { useEffect, useState } from 'react'
import { getObjectKeys } from 'utils/helpers/actions.helper'
import { isObjectEmpty } from 'utils/helpers/common.helper'
import { SwitcherItemsContainer } from 'components/global/SwitcherComponent/SwitcherItemsContainer/SwitcherItemsContainer'
import { FeatureSwitcher } from 'components/forms/addUser/FeatureDataAccess/FeatureSwitcher/FeatureSwitcher'

const getFeatureNames = (features) => getObjectKeys(features)

export const FeaturesDataAccessContent = ({ featureData, isLoading, error, handleFeatureSwitcherChange }) => {
	const [features, setFeatures] = useState(null)
	const [isPreparing, setIsPreparing] = useState(true)

	useEffect(() => {
		const prepareFeatures = () => {
			const featureNames = getFeatureNames(featureData)
			const features = featureNames.map((name) => {
				const actionGroups = featureData[name];
				
				return {
					label: name,
					checked: Boolean(name) && !isObjectEmpty(actionGroups),
					content: (
						<FeatureSwitcher
							features={featureData[name]}
							featureSwitcherChanged={handleFeatureSwitcherChange}
						/>
					),
				}
			})
			
			setFeatures(features)
			setIsPreparing(false)
		}

		prepareFeatures()
	}, [])

	return (
		!isLoading && !isPreparing &&  <SwitcherItemsContainer items={features} />
	)
}
