import React from 'react';
import Logo from './Logo';
import Notifications from './Notifications';

const Header = () => (
    <div className="font-base">
        <div className="h-[72px] p-[24px] flex justify-around items-center
        border-solid border-b border-light-gray">
            <Logo />
            <Notifications />
        </div>
    </div>
);

export default Header;