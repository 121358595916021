import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import './SearchInput.css';
import search from '../../../icons/search.svg';

export const SearchInput = ({ actionType, id, placeholder, minLength }) => {
	const [term, setTerm] = useState()
	const [debouncedTerm, setDebouncedTerm] = useState(term)

	const dispatch = useDispatch()

	useEffect(() => {
		const timer = setTimeout(() => setTerm(debouncedTerm), 500)
		return () => clearTimeout(timer)
	}, [debouncedTerm])

	useEffect(() => {
		if (term === '' || term?.length >= minLength)
			dispatch({
				type: actionType,
				payload: term,
			})
	}, [term])

	return (
		<div className='search-input-main-container'>
			<div className='search-input-icon-container'>
				<img
					alt='search'
					src={search}
					className='search-input-icon'
				/>
			</div>
			<input
				className='search-input common-input'
				placeholder={placeholder}
				id={id}
				name={id}
				data-testid={`input-${id}`}
				value={debouncedTerm}
				onChange={(e) => setDebouncedTerm(e.target.value)}
			/>
		</div>
	)
}

SearchInput.defaultProps = {
	defaultSearchValue: ''
}
