import React, { useLayoutEffect, useState } from 'react'
import './StepperItem.css'
import PropTypes from 'prop-types'
import { StepperHeader } from 'components/global/Stepper2/StepperHeader/StepperHeader'
import { StepperCircle } from 'components/global/Stepper2/StepperCircle/StepperCircle'
import { StepperContent } from 'components/global/Stepper2/StepperContent/StepperContent'

export const StepperItem = ({
	step,
	activeStep,
	previousStep,
	nextStep,
	dispatch,
	handleOnStepRemove,
	handleOnNext,
	isErrors,
	dataTestId,
}) => {
	const [stepState, setStepState] = useState()

	useLayoutEffect(() => {
		setStepState(step)

		if (step.isOpen) {
			dispatch.stepper.setActiveStep({ activeStep: step })
		}
	}, [step])

	useLayoutEffect(() => {
		if (Boolean(activeStep) && Boolean(stepState)) {
			if (activeStep.label === stepState.label) {
				setStepState({
					...activeStep,
				})
			} else {
				setStepState({
					...stepState,
					isOpen: false,
					isCompleted: activeStep?.isOpen ? activeStep.index > stepState.index : true,
				})
			}
		}
	}, [activeStep])

	const onHeaderClick = () => {
		if (stepState?.isDisabled) {
			return
		}

		if (activeStep?.label === stepState.label) {
			return
		}

		const newActiveStep = {
			...stepState,
			isOpen: true,
		}

		dispatch.stepper.setActiveStep({ activeStep: newActiveStep })
	}

	const handleOnNextNavigate = (nextOpenedStep) => {
		if (stepState?.isDisabled) {
			return
		}

		const currentStep = {
			...stepState,
			isOpen: false,
		}

		const newActiveStep = {
			...nextOpenedStep,
			isOpen: !!nextOpenedStep,
		}

		handleOnNext(currentStep, newActiveStep)
	}

	const handleOnBackNavigate = (nextOpenedStep) => {
		if (stepState?.isDisabled) {
			return
		}

		const currentStep = {
			...stepState,
			isOpen: false,
		}

		const newActiveStep = {
			...nextOpenedStep,
			isOpen: true,
		}

		setStepState(currentStep)
		dispatch.stepper.setActiveStep({ activeStep: newActiveStep })
	}

	const handleOnStepRemoved = () => {
		dispatch.stepper.setActiveStep({ activeStep: previousStep })
		handleOnStepRemove(stepState)
	}

	return Boolean(stepState) ? (
		<div className="stepper-item-container">
			<StepperCircle step={stepState} isErrors={isErrors} dataTestId={`${dataTestId}-circle`} />
			<div className="stepper-item-content">
				{stepState?.isOpen ? (
					<StepperContent
						step={stepState}
						headerText={step.label}
						handleOnRemove={handleOnStepRemoved}
						handleOnBack={() => handleOnBackNavigate(previousStep)}
						handleOnNext={() => handleOnNextNavigate(nextStep)}
						dataTestId={`${dataTestId}-content`}
					> 
						{step.component}
					</StepperContent>
				) : (
					<StepperHeader
						step={stepState}
						headerText={step.label}
						onHeaderClick={onHeaderClick}
						dataTestId={`${dataTestId}-header`}
					/>
				)}
			</div>
		</div>
	) : null
}

StepperItem.propTypes = {
	step: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	handleOnStepRemove: PropTypes.func.isRequired,
	activeStep: PropTypes.object,
	previousStep: PropTypes.object,
	nextStep: PropTypes.object,
	isErrors: PropTypes.bool
}
