import React from 'react';
import Select, { components } from 'react-select';
import './Dropdown.css';
import PropTypes from 'prop-types';

const isStringIncludes = (searchString, inputValue) => {
    return searchString.toString().toLowerCase().includes(inputValue.toLowerCase())
}

export const Dropdown = ({
   options,
   label,
   isSearchable = false,
   placeholder = '',
   isDisabled,
   isReadOnly,
   isMulti,
   defaultValue,
   onChange,
   width = '360px',
   height = '48px',
   dataTestId,
}) => {
    const customFilter = (option, inputValue) => {
			const { label, additional } = option.data

			return (
				isStringIncludes(label, inputValue) ||
				isStringIncludes(additional, inputValue)
			)
		}

    const customStyles = {
        container: (provided) => ({
            ...provided,
            width: width,
            height: height
        }),
        control: (provided, state) => ({
            ...provided,
            cursor: 'pointer',
            width: width,
            height: isMulti ? 'auto' : height,
            minHeight: height,
            borderRadius: '8px',
            display: 'flex',
            border: state.isDisabled || isReadOnly ? '1px solid #BAC3D2' : state.isFocused ? '1px solid #0C2146' : '1px solid #BAC3D2',
            ':hover': {
                borderColor: state.isDisabled || isReadOnly ? '1px solid #BAC3D2' : '#7E22D6'
            },
            outline: state.isFocused ? 'none' : 'none',
            boxShadow: state.isFocused ? 'none' : 'none',
            backgroundColor: isReadOnly ? '#F7F7FA' : 'white'
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            width: 0
        }),
        singleValue: (provided, state) => ({
            ...provided,
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '32px',
            color: state.isDisabled || isReadOnly ? '#BAC3D2' : '#071B3F'
        }),
        placeholder: (provided) => ({
            ...provided,
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '32px',
            color: '#818A9C'
        }),
        menu: (provided) => ({
            ...provided,
            border: '1px solid #BAC3D2',
            backgroundColor: 'white',
            borderRadius: '8px',
            marginTop: '4px'
        }),
        menuList: (provided) => ({
            ...provided,
            boxShadow: '0px 4px 8px rgba(27, 78, 163, 0.04), 0px 8px 16px rgba(41, 121, 255, 0.04)'
        }),
        option: (provided, state) => ({
            ...provided,
            cursor: 'pointer',
            backgroundColor: state.isSelected ? '#DBDBE1' : 'white'
        }),
        ValueContainer: (provided) => ({
            ...provided,
            padding: '2px 15px'
        })
    };

    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
                <div className={isDisabled || isReadOnly ? 'arrowContainerDisabled' : 'arrowContainer'} />
            </components.DropdownIndicator>
        );
    };

    const formatOptionLabel = ({ label, additional }) => (
        <div className='optionContainer'>
            <div className='optionLabel'>{label}</div>
            {additional && <div>{additional}</div>}
        </div>
    );

    const SingleValue = ({...props}) => {
        return (
            <div>
                {props.data.label}
            </div>
        );
    };

    const handleChange = (selectedOption) => {
        onChange(selectedOption);
    };

    return (
            <div className='dropdownContainer' data-testid={dataTestId}>
                {label && <div className='dropdownLabel'> {label}</div>}
                <Select
                    styles={customStyles}
                    components={{ DropdownIndicator, SingleValue }}
                    formatOptionLabel={formatOptionLabel}
                    options={options}
                    filterOption={customFilter}
                    placeholder={placeholder}
                    isSearchable={isSearchable}
                    isDisabled={isDisabled || isReadOnly}
                    isMulti={isMulti}
                    defaultValue={defaultValue}
                    onChange={handleChange}
                />
            </div>
    );
};

Dropdown.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]).isRequired,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]).isRequired
    })),
    isSearchable: PropTypes.bool,
    placeholder: PropTypes.string,
    isDisabled: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    isMulti: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    width: PropTypes.string,
    height: PropTypes.string,
    label: PropTypes.string,
    dataTestId: PropTypes.string
};

Dropdown.defaultProps = {
    options: []
}