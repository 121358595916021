import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import StepLabel from './StepLabel';
import editIcon from '../../../icons/edit.svg';
import { EDIT_STEP, EDIT } from '../../../utils/messages';

const StepItem = ({ step, header, storeLocation, Component}) => {
    const dispatch = useDispatch();
    const stepData = useSelector(state => state[storeLocation].steps)[step-1];

    const handleClick = useCallback(() => {
        dispatch({
            type: `${storeLocation}/setCollapseAll`,
            payload: {step}
        });
    }, [dispatch, step])

    return (
        <>
            <div className={`${!stepData?.isExpanded && 'hidden'} ml-[16px] pl-[32px] pt-[16px] flex flex-col 
                bg-white rounded-lg grow shadow-table`}>
                <StepLabel step={step} />
                <div className="font-base font-semibold text-big leading-big">{header}</div>
                <Component step={step} />
            </div>
            <div className={`${stepData?.isExpanded && 'hidden'} ml-[16px] pl-[32px] flex bg-white items-center rounded-lg 
                shadow-table justify-between w-full hover:cursor-pointer`}
                data-testid={`menu-collapsed-step-${step}`} onClick={handleClick}
            >
                <div className="flex items-center">
                    <StepLabel step={step} />
                    <div className="font-base font-medium text-medium
                            leading-medium text-dark-black
                            pl-[33px] py-[12px]"
                    >
                        {header}
                    </div>
                </div>
                <div className="flex pr-[55px]">
                    <img
                        alt={EDIT_STEP}
                        src={editIcon}
                        className="h-[18px] w-[18px]"
                    />
                    <div className="font-secondary font-semibold ml-[11px]
                            text-medium leading-medium text-bright-purple"
                    >
                        {EDIT}
                    </div>
                </div>
            </div>
        </>
    )
}

export default StepItem;

StepItem.propTypes = {
    step: PropTypes.number.isRequired,
    header: PropTypes.string.isRequired,
    storeLocation: PropTypes.string.isRequired,
    Component: PropTypes.func.isRequired
}