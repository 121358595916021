import React from 'react'
import './MenuItem.css'
import PropTypes from 'prop-types';

export const MenuItem = ({ content, onClick, dataTestId }) => {
    const handleClick = () => {
        if (content.isDisabled || !content.enabled || !onClick) {
            return
        }
       onClick(content?.label);
    }

    return (
        <div
            className={`menu-item ${content.isDisabled || !content.enabled ? 'disabled' : ''}`}
            onClick={handleClick}
            data-testid={`${dataTestId}-menuItem-${content?.label}`}
        >
            {content?.label}
            <div className='menu-item-icon' />
        </div>
    );
}

MenuItem.propTypes = {
    content: PropTypes.shape({
        label: PropTypes.string.isRequired
    }).isRequired,
    onClick: PropTypes.func.isRequired,
    dataTestId: PropTypes.string
}
