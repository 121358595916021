import React from 'react'
import './BenefitComponent.css'
import tw from 'twin.macro'
import styled from 'styled-components/macro'
import PropTypes from 'prop-types';
import { BenefitRowsList } from 'components/benefit/BenefitRowsList/BenefitRowsList'
import { BenefitTable } from 'components/benefit/BenefitTable/BenefitTable';
import {Button} from 'components/buttons/Button/Button';
import {ButtonTypes} from 'utils/constants';
import {Icons, IconsNames} from 'utils/icons';
import {navigate} from 'gatsby';
import {useDispatch} from 'react-redux';
import {Tooltip} from 'components/global/Tooltip/Tooltip';
import {AUDIT_LOG_TOOLTIP} from 'utils/messages';

const BenefitBlockLabelContainer = styled.div`
	${tw`flex items-center`}
`

const BenefitSection = ({ label, blockLabel, rows, table, dataTestId }) => {
	const dataTestIdLabel = `${dataTestId}-${label}`;
	return (
		<div className="benefit-section-container">
			<div
				className="benefit-section-label"
				data-testid={`${dataTestIdLabel}-component-label`}
			>
				{label}
			</div>
			<div>
				{table && <BenefitTable tableName={blockLabel} endpoint={table} />}
				{rows && <BenefitRowsList content={rows} dataTestId={`${dataTestIdLabel}-view-benefit`} />}
			</div>
		</div>
	)
}


const BenefitBlock = ({ blockLabel, content, dataTestId, endpoint, viewId, header }) => {
	const dispatch = useDispatch();
	const dataTestIdLabel = `${dataTestId}-${blockLabel}`;

	const handleOnClick = async () => {
		dispatch.auditLogs.setAuditData({ data: {
				viewId,
				headerLabel: header,
				endpoint,
				backLink: '/app/benefits/viewBenefit',
				contextType: 'benefits'
			}
		})
		await navigate('/app/audit')
	}
	
	return (
		<div className="benefit-block">
			<BenefitBlockLabelContainer>
				<div
					className="benefit-block-label"
					data-testid={`${dataTestIdLabel}-component-label`}
				>
					{blockLabel}
				</div>
				<Tooltip
					label={AUDIT_LOG_TOOLTIP}
					Component={
						<Button
							handleOnClick={handleOnClick}
							icon={Icons[IconsNames.AuditLog]}
							type={ButtonTypes.Audit}
							dataTestId={dataTestId}
						/>
					}
				/>
			</BenefitBlockLabelContainer>
			<div className="benefit-block-content">
				{content.map(({ label, rows, table }) => (
					<BenefitSection
						key={label}
						label={label}
						blockLabel={blockLabel}
						rows={rows}
						table={table}
						dataTestId={dataTestId}
					/>
				))}
			</div>
		</div>
	)
}

export const BenefitComponentContainer = ({ components, dataTestId, viewId, header }) => {
	return (
		<div className="benefit-component-container">
			{components.map(({ label, endpoint = '', content }) => (
				<BenefitBlock
					key={label}
					blockLabel={label}
					endpoint={endpoint}
					content={content}
					viewId={viewId}
					header={header}
					dataTestId={dataTestId}
				/>
			))}
		</div>
	)
}

BenefitSection.propTypes = {
	label: PropTypes.string,
	blockLabel: PropTypes.string,
	rows: PropTypes.array,
	table: PropTypes.string,
	dataTestId: PropTypes.string
}

BenefitBlock.propTypes = {
	blockLabel: PropTypes.string.isRequired,
	content: PropTypes.array,
	dataTestId: PropTypes.string,
	viewId: PropTypes.string.isRequired,
	header: PropTypes.string.isRequired,
	endpoint: PropTypes.string
}

BenefitComponentContainer.propTypes = {
	components: PropTypes.array.isRequired,
	dataTestId: PropTypes.string,
	viewId: PropTypes.string.isRequired,
	header: PropTypes.string.isRequired
}
