import React, { useEffect, useState } from 'react'
import './viewBenefit.css'
import Layout from 'components/container/Layout'
import PageHeader from 'components/global/PageHeader/PageHeader'
import { benefits } from 'store/selectors/benefits'
import { useDispatch, useSelector } from 'react-redux'
import { BenefitCategoryContainer } from 'components/benefit/BenefitCategory/BenefitCategory'
import Loader from 'components/global/Loader/Loader'
import { Link, Element } from 'react-scroll'
import { camelize } from 'utils/helpers/tables.helper'
import GoBackButton from 'components/buttons/GoBackButton/GoBackButton'
import { BACK_TO_BENEFITS } from 'utils/messages'
export const ViewBenefitPage = ({ location }) => {
	const dispatch = useDispatch()
	const isLoading = useSelector(benefits.isLoading)
	const benefitViewId = useSelector(benefits.viewId)
	const benefitHeaderLabel = useSelector(benefits.headerLabel)
	const benefitCategories = useSelector(benefits.categories)
	const [activeLink, setActiveLink] = useState('coreComponents')

	useEffect(() => {
		if (!isLoading && benefitViewId) {
			dispatch({
				type: 'benefits/setViewPage',
				payload: {
					viewId: benefitViewId,
					viewHeader: benefitHeaderLabel,
				},
			})
		}
	}, [benefitViewId])

	const handleCLick = (e) => {
		setActiveLink(camelize(e.target.innerText))
	}

	const ViewBenefit = (
		<div className="view-benefit-main-container">
			<div className="go-back-button-wrapper">
				<GoBackButton
					title={BACK_TO_BENEFITS}
					link='/app/benefits'
					location={location}
				/>
			</div>
			<PageHeader title={benefitHeaderLabel} />
			<ul
				className="view-benefit-navigation-container"
				data-testid="view-benefit-navigation-container"
			>
				{benefitCategories.map((category) => {
					return (
						<Link
							id={camelize(category.label)}
							to={camelize(category.label)}
							smooth={true}
							duration={250}
							containerId="containerElement"
							onClick={handleCLick}
							className={
								activeLink === camelize(category.label)
									? 'active'
									: 'view-benefit-navigation-link'
							}
						>
							<li data-testid="view-benefit-navigation-info">
								{category.label}
							</li>
						</Link>
					)
				})}
			</ul>
			{!isLoading ? (
				<Element
					id="containerElement"
					className="view-benefit-scroll-container"
				>
					<BenefitCategoryContainer
						categories={benefitCategories}
						viewId={benefitViewId}
						header={benefitHeaderLabel}
					/>
				</Element>
			) : (
				<Loader />
			)}
		</div>
	)

	return <Layout component={ViewBenefit} />
}
