import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { rightPanel } from 'store/selectors/selectors'
import Loader from 'components/global/Loader/Loader'
import {
	RIGHT_PANEL_ABOUT_THIS_ROLE,
	RIGHT_PANEL_FEATURES_DATA_ACCESS,
	RIGHT_PANEL_USERS,
	RIGHT_PANEL_ABOUT_THIS_USER,
	AUDIT_LOG_TOOLTIP
} from 'utils/messages'
import { Users } from 'components/rightPanel/Users'
import { AboutThisRole } from 'components/rightPanel/AboutThisRole'
import { AboutThisUser } from 'components/rightPanel/AboutThisUser'
import { FeaturesDataAccessUser } from 'components/rightPanel/FeatureDataAccessUser/FeaturesDataAccessUser'
import { FeaturesDataAccessRole } from 'components/rightPanel/FeatureDataAccessRole/FeaturesDataAccessRole'
import {Icons, IconsNames} from 'utils/icons';
import {Button} from 'components/buttons/Button/Button';
import {ButtonTypes} from 'utils/constants';
import {navigate} from 'gatsby';
import {Tooltip} from 'components/global/Tooltip/Tooltip';
import { Body } from './Body'

const AdditionalComponent = () => {
	const dispatch = useDispatch();
	const { contextType, data, rowData } = useSelector(rightPanel)

	const handleOnClick = async () => {
		dispatch.auditLogs.setAuditData({ data: {
				...data,
				rowData,
				// todo get endpoint for logs table from user/role row and dispatch it here
				backLink: `/app/${contextType}`,
				contextType
			}
		})
		await navigate('/app/audit')
	}
	
	return (
		<Tooltip
			label={AUDIT_LOG_TOOLTIP}
			Component={
				<Button
					handleOnClick={handleOnClick}
					icon={Icons[IconsNames.AuditLog]}
					type={ButtonTypes.Audit}
					dataTestId='right-panel-accordion'
				/>
			}
		/>
	)
}

const templates = {
	roles: [
		{
			label: RIGHT_PANEL_ABOUT_THIS_ROLE,
			additional: <AdditionalComponent />,
			bodyContent: <AboutThisRole />,
		},
		{
			label: RIGHT_PANEL_FEATURES_DATA_ACCESS,
			bodyContent: <FeaturesDataAccessRole />,
		},
		{
			label: RIGHT_PANEL_USERS,
			bodyContent: <Users />,
		},
	],
	users: [
		{
			label: RIGHT_PANEL_ABOUT_THIS_USER,
			additional: <AdditionalComponent />,
			bodyContent: <AboutThisUser />,
		},
		{
			label: RIGHT_PANEL_FEATURES_DATA_ACCESS,
			bodyContent: <FeaturesDataAccessUser />,
		},
	],
}

export const RightPanelBody = () => {
	const { contextType, isLoading } = useSelector(rightPanel)

	return !isLoading ? (
		<Body template={templates[contextType]} dataTestId={'right-panel'} />
	) : (
		<Loader />
	)
}
