import React, {useEffect, useState} from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import moment from 'moment';
import './FormFields.css';
import { getLocatorId } from '../../utils/locator';
import { DatePickerComponent } from '../commonFields/DatePicker/DatePicker';

export const FormDatePicker = ({
   label,
   isRequired,
   content,
   control,
   fieldName,
   errors = []
}) =>
{
    const [defaultDate, setDefaultDate] = useState('');

    useEffect(() => {
        if (content) {
            const date = moment(content, 'DD/MM/YYYY');
            const formattedDate = moment(date).format('MM/DD/YYYY');
            setDefaultDate(new Date(formattedDate));
        }
    }, [content]);

    return (
        <div className='form-field-container'>
            <div className='form-field-label'>
                <span>{label}</span>
                {isRequired && <sup>*</sup>}
            </div>
            <Controller
                control={control}
                name={fieldName}
                render={({ field }) => (
                    <DatePickerComponent
                        className={errors.length ? 'input-error' : 'common-input'}
                        defaultValue={defaultDate || ''}
                        placeholderText='dd/mm/yyyy'
                        onChange={(date) => field.onChange(date)}
                        dataTestId={getLocatorId('input', label)}
                    />
                )}
            />
            {errors?.length ? errors.map((item, index) => {
                return (
                    <div
                        key={index}
                        className='form-field-error-container'
                    >
                        {item}
                    </div>
                )}) : null
            }
        </div>
    );
}

FormDatePicker.propTypes = {
    control: PropTypes.object.isRequired,
    fieldName: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    isRequired: PropTypes.bool,
    content: PropTypes.string,
    placeholder: PropTypes.string,
    errors: PropTypes.arrayOf(PropTypes.string)
};