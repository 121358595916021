import React, { useEffect, useState } from 'react'
import tw from 'twin.macro'
import styled from 'styled-components/macro'
import { useSelector } from 'react-redux'
import { HyperlinkLabel } from 'components/commonFields/HyperlinkLabel/HyperlinkLabel'
import { rightPanel } from 'store/selectors/selectors'
import { CollapsibleContainer } from 'components/global/CollapsibleContainer/CollapsibleContainer'
import { RIGHT_PANEL_EMPTY_LABEL } from 'utils/messages'

const StyledEmptyLayout = styled.div`
	${tw`text-dark-gray text-14-24 font-normal`}
`

export const Users = () => {
	const { data, isLoading } = useSelector(rightPanel)
	const [users, setUsers] = useState([])
	const [isEmpty, setIsEmpty] = useState(false)

	useEffect(() => {
		if (!isLoading) {
			fetchUser()
		}
	}, [isLoading])

	const fetchUser = () => {
		const {
			role: { users },
		} = data

		if (users && !users.length) {
			setIsEmpty(true)

			return
		}

		const userElements = users.map((user) => {
			return (
				<HyperlinkLabel
					key={user.person_id}
					id={user.person_id}
					label={`${user?.person_fname} ${user?.person_sname}`}
					additional={user?.person_email}
					handleClick={handleClickOnLink}
				/>
			)
		})

		setUsers(userElements)
	}

	const handleClickOnLink = () => {}

	return (
		!isLoading &&
		(!isEmpty ? (
			<div data-testid="hidden-list-container">
				<CollapsibleContainer
					entries={users}
					visibleEntriesAmount={10}
					renderList={(items) => items}
				/>
			</div>
		) : (
			<StyledEmptyLayout data-testid="empty-user-label">
				{RIGHT_PANEL_EMPTY_LABEL}
			</StyledEmptyLayout>
		))
	)
}
