import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './UserInfo.css'
import { auth } from 'store/selectors/selectors'
import { MY_PROFILE, LOGOUT } from 'utils/messages'
import { CollapsedMenu } from 'components/global/CollapsedMenu/CollapsedMenu'
import { AiFillCaretDown } from 'react-icons/ai'
import { Icons, IconsNames } from 'utils/icons'
import { navigate } from 'gatsby'

const getInitials = ({ firstname, surname }) => {
	if (!firstname && !surname) {
		return ''
	}
	if (!surname) {
		return firstname.substring(0, 1)
	}
	return `${firstname.substring(0, 1)}${surname.substring(0, 1)}`
}

const UserInitials = ({ firstname, surname }) => {
	return (
		<span className="user-info-initials" data-testid="user-info-initials">
			{getInitials({ firstname, surname })}
		</span>
	)
}

export const UserInfo = () => {
	const { first_name, surname, email } = useSelector(auth)

	const dispatch = useDispatch()

	const menuItems = [
		{
			label: MY_PROFILE,
			icon: Icons[IconsNames.Profile],
			onClick: async () => await navigate('/app/myProfile'),
		},
		{
			label: LOGOUT,
			icon: Icons[IconsNames.Logout],
			onClick: () => dispatch.auth.setLogOut()
		},
	]

	return (
		<div className="user-info-container">
			<div className="user-info-initials-container">
				<UserInitials firstname={first_name} surname={surname} />
			</div>
			<div className="user-info-text-container">
				<div className="user-info-name" data-testid="user-info-name">
					{first_name}
					<div className="user-info-menu">
						<CollapsedMenu
							items={menuItems}
							buttonIcon={AiFillCaretDown}
							dataTestId="user-info-menu"
						/>
					</div>
				</div>
				<div className="user-info-email" data-testid="user-info-email">
					{email}
				</div>
			</div>
		</div>
	)
}
