import React, { useState } from 'react'
import './MyProfileSection.css'
import PropTypes from 'prop-types'
import { Button } from 'components/buttons/Button/Button'
import { Icons, IconsNames } from 'utils/icons'
import { ButtonSizes, ButtonTypes } from 'utils/constants'
import { PasswordSectionForm } from 'components/forms/PasswordSectionForm/PasswordSectionForm'
import { UserInfoSectionForm } from 'components/forms/UserInfoSectionForm/UserInfoSectionForm'
import { AuthenticationSectionForm } from 'components/forms/AuthenticationSectionForm/AuthenticationSectionForm'

const ProfileSectionIds = {
	Auth: 'auth',
	Password: 'password',
	Info: 'info',
}

const ProfileSections = {
	[ProfileSectionIds.Info]: <UserInfoSectionForm />,
	[ProfileSectionIds.Auth]: <AuthenticationSectionForm />,
	[ProfileSectionIds.Password]: <PasswordSectionForm />,
}

const MyProfileSection = ({ sectionId, section }) => {
	const [isEdit, setIsEdit] = useState(false)

	const onChange = () => {
		setIsEdit(false)
	}

	return (
		<div className="my-profile-form-container">
			<div key={section.label} className="my-profile-section">
				<div className="my-profile-section-container">
					<div
						className="my-profile-section-label"
						data-testid={`${section.label}-section-label`}
					>
						{isEdit ? section?.subSection.label : section.label}
					</div>
					<div className="my-profile-section-toggle">
						{!isEdit && (
							<Button
								size={ButtonSizes.Small}
								icon={Icons[IconsNames.Edit]}
								type={ButtonTypes.Edit}
								handleOnClick={() => setIsEdit(!isEdit)}
							/>
						)}
					</div>
				</div>

				<div className="my-profile-section-content">
					{React.cloneElement(ProfileSections[sectionId], {
						section,
						isEdit,
						onChange,
						dataTestId: sectionId
					})}
				</div>
			</div>
		</div>
	)
}

export const MyProfileSectionContainer = ({ sections }) => {
	return (
		<>
			{Object.keys(sections).map((item) => {
				return (
					<MyProfileSection
						key={item}
						sectionId={item}
						section={sections[item]}
					/>
				)
			})}
		</>
	)
}

MyProfileSection.propTypes = {
	section: PropTypes.object.isRequired,
}

MyProfileSectionContainer.propTypes = {
	sections: PropTypes.object.isRequired,
}
