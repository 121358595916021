import React, { useEffect, useState } from 'react'
import { getObjectKeys } from 'utils/helpers/actions.helper'
import { Feature } from 'components/forms/addUser/FeatureDataAccess/Feature/Feature'
import PropTypes from 'prop-types';

export const FeatureSwitcher = ({ features, featureSwitcherChanged }) => {
	const [featureGroupNames, setFeatureGroupNames] = useState([])
	const [featuresState, setFeaturesState] = useState()

	useEffect(() => {
		if (features) {
			setFeaturesState(features)
			setFeatureGroupNames(getObjectKeys(features))
		}
	}, [features])

	const handleFeatureChange = (groupName, permissionActions) => {
		const groups = {
			...featuresState,
			[groupName]: {
				...featuresState[groupName],
				actions: {
					...permissionActions,
				}
			},
		}
		setFeaturesState(groups)
		featureSwitcherChanged(groups)
	}

	return featureGroupNames.map((groupName) => (
		<Feature
			key={groupName}
			feature={features[groupName]}
			checkboxGroupName={groupName}
			featureChanged={handleFeatureChange}
		/>
	))
}

FeatureSwitcher.propTypes = {
	features: PropTypes.object,
	featureSwitcherChanged: PropTypes.func.isRequired
}