import React from 'react'
import tw from 'twin.macro'
import styled from 'styled-components/macro'
import { useSelector } from 'react-redux'
import { rightPanel } from '@/store/selectors/selectors'
import { TitleDescriptionLabel } from '@/components/commonFields/TitleDescriptionLabel/TitleDescriptionLabel'
import {
	USER_EMAIL,
	USER_FIRSTNAME,
	USER_LASTNAME,
	NEW_USER_BIRTHDAY,
	ROLES_GROUP,
	STATUS
} from 'utils/messages'

const Container = styled.div`
	${tw`items-center justify-start flex-col w-full h-full flex`}
`

const titles = [
	{
		label: USER_FIRSTNAME,
		field: 'firstname',
	},
	{
		label: USER_LASTNAME,
		field: 'surname',
	},
    {
		label: NEW_USER_BIRTHDAY,
		field: 'date_of_birth',
	},
    {
		label: USER_EMAIL,
		field: 'email',
	},
	{
		label: ROLES_GROUP,
		field: 'roles',
	},
	{
		label: STATUS,
		field: 'status'
	}
]

export const AboutThisUser = () => {
	const { data, isLoading } = useSelector(rightPanel)

	return !isLoading && 
		<Container>
			{titles.map((title) => {
				return (
					<TitleDescriptionLabel
						key={title.label}
						title={title.label}
						description={data[title.field]}
						dataTestId={`title-description-label-${title.label}`}
					/>
				)
			})}
		</Container>
}