import React from 'react'
import './CoupleButtons.css'
import { Button } from 'components/buttons/Button/Button'
import { ButtonTypes } from 'utils/constants'
import PropTypes from 'prop-types';

export const CoupleButtons = ({
	primaryButtonLabel,
	secondButtonLabel,
	primaryButtonIcon,
	secondButtonIcon,
	handlePrimaryButtonClick,
	handleSecondButtonClick,
	dataTestId
}) => {
	return (
		<div className="buttons-container">
			<Button 
				label={primaryButtonLabel} 
				icon={primaryButtonIcon} 
				type={ButtonTypes.Primary} 
				handleOnClick={handlePrimaryButtonClick}
				dataTestId={dataTestId}
			/>
			<Button 
				label={secondButtonLabel}
				icon={secondButtonIcon}
				type={ButtonTypes.Secondary} 
				handleOnClick={handleSecondButtonClick}
				dataTestId={dataTestId}
			/>
		</div>
	)
}

CoupleButtons.propTypes = {
	primaryButtonLabel: PropTypes.string.isRequired,
	secondButtonLabel: PropTypes.string.isRequired,
	primaryButtonIcon: PropTypes.string,
	secondButtonIcon: PropTypes.string,
	handlePrimaryButtonClick: PropTypes.func.isRequired,
	handleSecondButtonClick: PropTypes.func.isRequired,
	dataTestId: PropTypes.string
}
