import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import fetchApi from '../common/fetchApi';
import { GET_USER_TYPES } from '../common/endpoints';
import { FetchMethods } from 'utils/constants';

export const useGetUserTypes = () => {
    const [isLoaded, setIsLoaded] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
            setIsLoaded(false);

            fetchApi(GET_USER_TYPES, {
                method: FetchMethods.Get
            })
                .then((response) => {
                    dispatch({
                        type: 'common/setUserTypes',
                        payload: response.data
                    });
                    setIsLoaded(true);
                })
                .catch((error) => {
                    setIsLoaded(true);
                });
    }, [dispatch]);

    return { isLoaded };
};