import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import completedStepIcon from '../../../icons/completed_step.svg';
import { getLocatorId } from '../../../utils/locator';
import { COMPLETED_STEP_ALT } from '../../../utils/messages';

const StepCircle = ({ step, storeLocation }) => {
    const stepData = useSelector(state => state[storeLocation].steps)[step-1];

    return (
        <div className="flex flex-col">
            {stepData?.isCompleted ? (
                <img
                    alt={COMPLETED_STEP_ALT}
                    src={completedStepIcon}
                    className='h-[32px] w-[32px] my-[8px]'
                    data-testid={getLocatorId('step-icon-completed')}
                />
            ) : (
                <div className="w-[32px] h-[32px] my-[8px] bg-dark-blue rounded-[50%]
                     text-center text-white font-secondary text-medium leading-medium pt-[3px]"
                     data-testid={getLocatorId('step-icon-inProgress')}
                >
                    {step}
                </div>
            )}
            <div className="ml-[16px] h-full border-solid border-l-2 border-l-light-beige" />
        </div>
    )
};

export default StepCircle;

StepCircle.propTypes = {
    step: PropTypes.number.isRequired,
    storeLocation: PropTypes.string.isRequired
}
