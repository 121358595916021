import fetchApi from "common/fetchApi";
import {GET_ROLES} from "common/endpoints";
import {FetchMethods, Sorting} from 'utils/constants';
import {prepareDropdownList} from "components/forms/common/PrepareDataHelpers";

const timeout = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const loadCallback = (userTypeId) => async (searchQuery, loadedOptions, { page }) => {
    if (searchQuery) {
        await timeout(1000);
    }

    const params = {
        search: searchQuery && searchQuery.length >= 3 ? searchQuery : '',
        page: searchQuery ? '' : page,
        user_type_id: userTypeId,
        sort_by: 'name',
        range: Sorting.Asc
    }

    const response = await fetchApi(GET_ROLES, {
        method: FetchMethods.Get,
        params
    });
    const roles = response.data?.data;
    const preparedRoles = prepareDropdownList({ array: roles });
    
    return {
        options: preparedRoles,
        hasMore: response.data?.last_page > response.data?.current_page,
        additional: {
            page: searchQuery ? page : page + 1
        },
    };
};