import React from 'react'
import PropTypes from 'prop-types'
import './HyperlinkLabel.css'

export const HyperlinkLabel = ({ id, label, additional, handleClick }) => {
	return (
		<div className="hyperlink-label-container">
			<div className="hyperlink-main-label-container">
				<a
					className="hyperlink-main-label"
					onClick={(id) => handleClick(id)}
					data-testid={`hyperlink-main-${id}-${label}`}
				>
					{label}
				</a>
			</div>
			{additional && (
				<div className="hyperlink-additional-label-container">
					<label
						className="hyperlink-additional-label"
						data-testid={`hyperlink-additional-${id}-${additional}`}
					>
						({additional})
					</label>
				</div>
			)}
		</div>
	)
}

HyperlinkLabel.propTypes = {
	id: PropTypes.number.isRequired,
	label: PropTypes.string.isRequired,
	additional: PropTypes.string,
	handleClick: PropTypes.func,
}
