import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import './EditUserFeatures.css';
import { FeaturesDataAccessContent } from '../addUser/FeatureDataAccess/FeatureDataAccessContent/FeatureDataAccessContent';
import { EDIT_USER_FEATURES } from 'utils/messages';
import { editUser } from 'store/selectors/selectors';
import Loader from '../../global/Loader/Loader';

export const EditUserFeatures = () => {
    const { permissions, isLoading } = useSelector(editUser);
    let permissionsData = {};

    const handleFeatureSwitcherChange = () => {
        console.log() // todo in the next sprint
    }

    permissions?.forEach(section => {
        const sectionFeatures = section?.features;
        const sectionPermissions = {};

        sectionFeatures.forEach(permission => {
            sectionPermissions[permission?.label?.toLowerCase()] = permission?.content;
        })

        permissionsData[section?.label] = sectionPermissions;
    })

    return (
        <>
            <div className='edit-user-features-header' data-testid='edit-user-features-header'>{EDIT_USER_FEATURES}</div>
            {
                isLoading ?
                    <div className="loader-container">
                        <Loader />
                    </div>
                    :
                    <FeaturesDataAccessContent
                        handleFeatureSwitcherChange={handleFeatureSwitcherChange}
                        featureData={permissionsData}
                    />
            }
        </>
    )
}

EditUserFeatures.propTypes = {
    actions: PropTypes.object
}