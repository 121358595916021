import React, {useEffect, useState} from 'react'
import './DashboardPage.css'
import PropTypes from 'prop-types'
import {navigate} from "gatsby";
import Layout from 'components/container/Layout'
import { Page } from 'components/global/Page/Page'
import DashboardIcon from 'icons/dashboard_item_woman.svg'
import { DASHBOARD_ITEM_DESCRIPTION, DASHBOARD_ITEM_LABEL } from 'utils/messages'
import { PageTitles } from 'utils/constants'

const DashboardItem = ({ label, description }) => {
	const splash = 'dashboard-item-splash'

	const [state, setState] = useState(false)

	useEffect(() => {
		if (state) {
			navigate('/app/legacy-admin')
		}
	}, [state])

	return (
		<div className="dashboard-item-container" onClick={() => setState(!state)}>
			<div className={`dashboard-item-radio-wrapper ${splash}`}>
				<input
					className="dashboard-item-radio-input"
					type="radio"
					checked={state}
					onChange={() => {}}
				/>
			</div>
			<div className={`dashboard-item-image ${splash}`}>
				<img
					src={DashboardIcon}
					alt={DASHBOARD_ITEM_LABEL}
					className="test-image"
				/>
			</div>
			<div className={`dashboard-item-content ${splash}`}>
				<div className="dashboard-item-content-label">{label}</div>
				<div className="dashboard-item-content-description">{description}</div>
			</div>
		</div>
	)
}

export const DashboardPage = () => {
	const dashboardPage = (
		<Page title={PageTitles.Dashboard}>
			<div className="dashboard-items-container">
				<DashboardItem
					label={DASHBOARD_ITEM_LABEL}
					description={DASHBOARD_ITEM_DESCRIPTION}
				/>
			</div>
		</Page>
	)

	return <Layout component={dashboardPage} />
}

DashboardPage.propTypes = {
	context: PropTypes.string,
}
