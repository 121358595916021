import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import FormInput from 'components/formFields/FormInput';
import FormDropdown from 'components/formFields/FormDropdown';
import FormTextArea from 'components/formFields/FormTextArea';
import {ButtonLabels, ButtonSizes, ButtonTypes} from 'utils/constants';
import {
    NEW_ROLE_NAME,
    NEW_ROLE_USER_TYPE,
    NEW_ROLE_DESCRIPTION,
    NEW_ROLE_USER_TYPE_PLACEHOLDER
} from 'utils/messages';
import { commonUserTypes } from 'store/selectors/selectors';
import { newRoleErrors } from 'store/selectors/selectors';
import { prepareDropdownList } from 'components/forms/common/PrepareDataHelpers';
import {Button} from "components/buttons/Button/Button";

const AddRoleInfo = () => {
    const userTypes = useSelector(commonUserTypes);
    const dispatch = useDispatch();
    const { handleSubmit, register } = useForm();

    const preparedOptions = prepareDropdownList({ array: userTypes });

    const onSubmit = async data => {
        dispatch({
            type: 'newRole/setStepData',
            payload: data
        });
        dispatch({
            type: 'newRole/setStepInfo',
            payload: {
                step: 1,
                isExpanded: false,
                isCompleted: true
            }
        });
        dispatch({
            type: 'newRole/setStepInfo',
            payload: {
                step: 2,
                isExpanded: true
            }
        });
    };

    return (
        <form>
            <FormInput
                label={NEW_ROLE_NAME}
                isRequired
                register={register}
                fieldName="name"
                errorsSelector={newRoleErrors}
            />
            <FormDropdown
                label={NEW_ROLE_USER_TYPE}
                content={preparedOptions}
                placeholder={NEW_ROLE_USER_TYPE_PLACEHOLDER}
                register={register}
                fieldName="user_type_id"
                actionType="newRole/setNewRoleUserType"
                errorsSelector={newRoleErrors}
                isRequired
            />
            <FormTextArea label={NEW_ROLE_DESCRIPTION} register={register} fieldName="description" />
            <div className='my-6'>
                <Button
                    handleOnClick={handleSubmit(onSubmit)}
                    label={ButtonLabels.Next}
                    type={ButtonTypes.Primary}
                    size={ButtonSizes.Medium}
                    dataTestId='button-step-next'
                />
            </div>
        </form>
    );
};


export default AddRoleInfo;