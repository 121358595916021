import React from 'react';
import { navigate } from 'gatsby';
import './NavigationItem.css';
import {useDispatch} from "react-redux";
import PropTypes from "prop-types";

export const IconComponent = ({ icon, alt }) => {
    const classNames = 'button-icon navigation-item-icon'
    
	return typeof icon === 'function' 
        ? <span className={classNames}>{icon()}</span>
		: <img src={icon} alt={alt} className={classNames} />
};

export const NavigationItem = ({ icon, navigateLink, label, currentItem }) => {
	const dispatch = useDispatch();

	const className = icon ? 'navigation-item-with-icon' : 'navigation-item';

	const handleClick = async () => {
		if (currentItem !== label) {
			dispatch.mainNavigation.setCurrentMenuItem(label)
		}
		await navigate(navigateLink);
	}

	 return (
		<div className='navigation-item-container'>
			{icon && <IconComponent icon={icon} alt={label} />}

			<div
				className={label === currentItem ? className + '-current' : className}
				onClick={handleClick}
				data-testid={`navigation-to-${label}`}
			>
				{label}
			</div>
		</div>
	)
};

NavigationItem.propTypes = {
	label: PropTypes.string.isRequired,
	icon: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
	navigateLink: PropTypes.string.isRequired,
	currentItem: PropTypes.string.isRequired
}