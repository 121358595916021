import React from 'react';
import logo from '../../../../static/media/logo.png';
import { LOGO_ALT } from '../../../utils/messages';

const Logo = () => (
    <div className="min-w-[136px]">
        <img
            alt={LOGO_ALT}
            src={logo}
            className="h-[24px] w-[136px]"
        />
    </div>
);

export default Logo;