import React, { useEffect } from 'react'
import '../style/app.css'
import 'react-toastify/dist/ReactToastify.css'
import { Router } from '@reach/router'
import '@/style/output.css'
import PrivateRoute from '../common/PrivateRoute'
import Login from './login'
import ActivateAccount from './activate'
import SetupPasswordForm from './setupPassword'
import RolesPage from '../templates/roles'
import HomePage from '../templates/home'
import NotFound from './404'
import AddRolePage from '../templates/addRole'
import { AddUserPage } from 'templates/addUser/addUser'
import { UsersPage } from 'templates/users'
import { BenefitsPage } from 'templates/benefits/benefits'
import { EditRolePage } from 'templates/editRole/editRole'
import { EditUserPage } from 'templates/editUser/editUser'
import { ViewBenefitPage } from 'templates/viewBenefit/viewBenefit'
import { LegacyAdminPage } from 'templates/legacy/legacyAdminPage'
import { RightPanel } from 'components/global/RightPanel/RightPanel'
import { ToastContainer } from 'react-toastify'
import { BenefitBuilderTemplate } from 'templates/benefitBuilder/benefitBuilder'
import { DashboardPage } from 'templates/dashboard/DashboardPage'
import { ContextTypes, Paths } from 'utils/constants'
import ResetPassword from "pages/resetPassword";
import ResetPasswordSuccess from "pages/resetPasswordSuccess";
import { Modal } from 'components/global/Modal/Modal'
import { MyProfilePage } from 'templates/myProfile/myProfile'
import { useDispatch, useSelector } from 'react-redux'
import { auth } from 'store/selectors/auth'
import {AuditLogsPage} from 'templates/auditLogs';
import TwoFA from 'pages/twoFA'

const App = () => {
	const dispatch = useDispatch()

	const authSessionLogoutTimer = useSelector(auth.sessionLogoutTimer)
	const authSessionTimeoutTimer = useSelector(auth.sessionTimeoutTimer)

	// remove after correct page refresh + check session timeout
	useEffect(() => {
		dispatch.modal.hideModal()

		return () => {
			clearTimeout(authSessionLogoutTimer)
			clearTimeout(authSessionTimeoutTimer)
		}
	}, [])

	return (
		<>
			<Router basepath="app">
				<NotFound default />
				<PrivateRoute path="/roles" component={RolesPage} />
				<PrivateRoute path="/roles/addRole" component={AddRolePage} />
				<PrivateRoute path="/roles/editRole" component={EditRolePage} />
				<PrivateRoute path="/users" component={UsersPage} />
				<PrivateRoute path="/users/addUser" component={AddUserPage} />
				<PrivateRoute path="/users/editUser" component={EditUserPage} />
				<PrivateRoute path="/benefits" component={BenefitsPage} />
				<PrivateRoute path="/home" component={HomePage} />
				<PrivateRoute path="/legacy-admin" component={LegacyAdminPage} />
				<PrivateRoute path="/dashboard" component={DashboardPage} />
				<PrivateRoute path="/myProfile" component={MyProfilePage} />
				<PrivateRoute path="/audit" component={AuditLogsPage} />
				<PrivateRoute
					path={Paths.ViewBenefit}
					component={ViewBenefitPage}
				/>
				<PrivateRoute
					path={Paths.AddBenefit}
					component={BenefitBuilderTemplate}
                    context={ContextTypes.AddBenefit}
				/>
				<PrivateRoute
					path={Paths.EditBenefit}
					component={BenefitBuilderTemplate}
                    context={ContextTypes.EditBenefit}
				/>
				<Login path="/login" />
				<ActivateAccount path={Paths.Activation} />
				<TwoFA path={Paths.TwoFA} />
				<SetupPasswordForm path="/setupPassword" />
				<ResetPassword path={Paths.ResetPassword} />
				<ResetPasswordSuccess path="/resetPasswordSuccess" />
			</Router>
			<ToastContainer
				className="toaster-container"
				toastClassName="toast-container"
				autoClose={5000}
				hideProgressBar={true}
				bodyClassName="toast-body"
				theme="dark"
			/>
			<RightPanel />
			<Modal />
		</>
	)
}
export default App
