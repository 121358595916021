import React, {useRef, useState} from 'react';
import {
    AUTHENTICATION_CODE_MESSAGE, AUTHENTICATION_CODE_MODAL_APPLY,
    AUTHENTICATION_CODE_MODAL_CANCEL,
    AUTHENTICATION_CODE_MODAL_NEXT, AUTHENTICATION_MODAL_APPLY, AUTHENTICATION_MODAL_CANCEL, AUTHENTICATION_TITLE
} from 'utils/messages';
import {Button} from 'components/buttons/Button/Button';
import {ButtonTypes, FetchMethods, Order} from 'utils/constants';
import styled from 'styled-components/macro';
import tw from 'twin.macro';
import PropTypes from 'prop-types';
import fetchApi from 'common/fetchApi';
import {VALIDATE_MFA} from 'common/endpoints';
import Loader from 'components/global/Loader/Loader';
import {useDispatch, useSelector} from 'react-redux';
import {auth} from 'store/selectors/auth';
import {toast} from 'react-toastify';
import {QrModalMessage} from 'components/forms/AuthenticationSectionForm/QrModalMessage';

const InputsContainer = styled.div`
	${tw`my-4`}
`

export const CodeModalMessage = ({ onClose, order }) => {
    const dispatch = useDispatch()
    const [code, setCode] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { id: userID } = useSelector(auth.userInfo);
    const ref = useRef([]);

    const inputIds = [
        'code-0',
        'code-1',
        'code-2',
        'code-3',
        'code-4',
        'code-5',
    ]

    const handleNextInput = (e) => {
        const fieldIndex = +e.target.id.split('-')[1];
        if (code[fieldIndex]) {
            const copy = [...code];
            copy[fieldIndex] = e.target.value;
            setCode(copy);
        } else {
            setCode(prev => [...prev, e.target.value])
        }
        ref?.current[fieldIndex+1]?.focus();
    };

    const showCodeModal = (order) => {
        dispatch.modal.showModal({
            data: {
                title: AUTHENTICATION_TITLE,
                body: <CodeModalMessage onClose={onClose} order={order} />,
                withFooterButtons: false,
                onClose: () => onClose(false),
            }
        })
    }

    const onApply = async () => {
        setIsLoading(true);
        try {
            const response = await fetchApi(VALIDATE_MFA(userID), {
                method: FetchMethods.Post,
                data: {
                    code: +code?.join('')
                }
            });
            toast.success(response.data.message);
            if (order === Order.First) {
                showCodeModal(Order.Second)
            } else {
                onClose()
                dispatch.myProfile.setIsMFASending({ isSending: true })
            }
        } catch (error) {
            toast.error(error.response.data?.message);
            if (order === Order.Second) {
                showCodeModal(Order.First)
            }
        }
        finally {
            setIsLoading(false);
        }
    }

    const onGoBack = () => {
        if (order === Order.First) {
            dispatch.modal.showModal({
                data: {
                    title: AUTHENTICATION_TITLE,
                    body: <QrModalMessage />,
                    primaryButtonLabel: AUTHENTICATION_MODAL_APPLY,
                    onPrimary: () => showCodeModal(Order.First),
                    secondaryButtonLabel: AUTHENTICATION_MODAL_CANCEL,
                    onClose: () => onClose(false),
                    onSecondary: () => onClose(false)
                },
            })
        } else {
            showCodeModal(Order.First)
        }
    }

    return (
        <div className="auth-modal">
            <div>{AUTHENTICATION_CODE_MESSAGE(order)}</div>
            <InputsContainer>
                {isLoading ?
                    <Loader />
                    : inputIds.map((id, index) => {
                        return (
                            <input
                                key={id}
                                className="auth-modal-input"
                                id={id}
                                type="text"
                                placeholder="_"
                                maxLength="1"
                                ref={inputId => ref.current[index] = inputId}
                                onChange={(e) => handleNextInput(e)}
                            />
                        )
                })}
            </InputsContainer>
            <div className={`modal-footer-border modal-footer`}>
                <div className="modal-footer-buttons-container">
                    <div className="modal-footer-buttons-right-container">
                        <Button
                            label={AUTHENTICATION_CODE_MODAL_CANCEL}
                            type={ButtonTypes.Secondary}
                            handleOnClick={onGoBack}
                        />
                        <Button
                            label={order === Order.First ?
                                    AUTHENTICATION_CODE_MODAL_NEXT
                                    : AUTHENTICATION_CODE_MODAL_APPLY}
                            type={ButtonTypes.Primary}
                            handleOnClick={onApply}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

CodeModalMessage.propTypes = {
    onClose: PropTypes.func,
    order: PropTypes.string
}