import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import { Table } from 'components/global/Table2/Table/Table';
import Loader from "components/global/Loader/Loader";
import { useDispatch } from 'react-redux'
import {PaginationEnum} from "utils/helpers/tables.helper";
import {Sorting, sortingDirections} from 'utils/constants';

// todo temporary
const mockTable = {
    "columns": [
        {
            "name": "date",
            "value": "Date",
            "sorting": true
        },
        {
            "name": "user_process",
            "value": "User / System process",
            "sorting": true
        },
        {
            "name": "type",
            "value": "Log type",
            "sorting": true
        },
        {
            "name": "reason",
            "value": "Change reason",
            "sorting": true
        },
        {
            "name": "masquerade",
            "value": "Masquerade",
            "sorting": false
        }
    ],
    "filters": [
        {
            "name": "from",
            "placeholder": "From",
            "type": "date",
            "value": "09/01/2022"
        },
        {
            "name": "to",
            "placeholder": "To",
            "type": "date",
            "value": "07/02/2022"
        }
    ],
    "table_id": "audit_log",
    "rows": [
        {
            "id": 1,
            "date": "09/01/2022",
            "user_process": "Spirit administartor",
            "type": "Record amendment",
            "reason": "Correct system error",
            "masquerade": "No"
        },
        {
            "id": 2,
            "date": "10/01/2022",
            "user_process": "Spirit administartor",
            "type": "Record amendment",
            "reason": "Correct system error",
            "masquerade": "No"
        },
        {
            "id": 3,
            "date": "11/01/2022",
            "user_process": "Spirit administartor",
            "type": "Record amendment",
            "reason": "Correct system error",
            "masquerade": "No"
        },
        {
            "id": 4,
            "date": "12/01/2022",
            "user_process": "Spirit administartor",
            "type": "Record amendment",
            "reason": "Correct system error",
            "masquerade": "No"
        },
        {
            "id": 5,
            "date": "09/01/2022",
            "user_process": "Spirit administartor",
            "type": "Record amendment",
            "reason": "Correct system error",
            "masquerade": "Yes"
        },
        {
            "id": 6,
            "date": "09/01/2022",
            "user_process": "Spirit administartor",
            "type": "Record amendment",
            "reason": "Why not",
            "masquerade": "No"
        }
    ],
    "from": 1,
    "to": 5,
    "total": 6,
    "current_page": 1,
    "last_page": 2
}

export const AuditTable = ({ tableName, endpoint }) => {
    const [data, setData] = useState({});
    const [params, setParams] = useState({});
    const [tableParams, setTableParams] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        return () => dispatch.tables.setClearTableStore({ tableName })
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                // todo fetch data from the backend
                setData(mockTable);
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
            }
        }
        if (tableName && endpoint) fetchData();
    }, [tableName, endpoint]);

    const onPagination = (item) => {
        setParams({
            ...params,
            page: PaginationEnum[item.goToPage](item)
        });
    }

    const onPaginationDropdown = (item) => {
        setParams({
            ...params,
            per_page: item.value
        })
    }

    const onSort = (item) => {
        setTableParams({
            ...tableParams,
            sortingColumn: item?.field,
            sortingDirection: item?.direction
        })
        setParams({
            ...params,
            sort_by:`${item?.field} ${item?.direction === sortingDirections.UP ? Sorting.Desc : Sorting.Asc}`
        })
        dispatch.tables.setIsUpdated({ tableName, value: true })
    };

    const onFilter = (item) => {
        setTableParams({
            ...tableParams,
            filters: {
                ...tableParams.filters,
                [item.filterName]: item?.date
            }
        })
        setParams({
            ...params,
            [item.filterName]: item?.date
        })
    }

    if (isLoading) {
        return (
            <div className='loader-container'>
                <Loader />
            </div>
        );
    }

    return (
        <Table
            data={data}
            params={tableParams}
            tableName={tableName}
            onSort={onSort}
            onFilter={onFilter}
            onPagination={onPagination}
            onPaginationDropdown={onPaginationDropdown}
            dataTestId={tableName}
        />
    )
};

AuditTable.propTypes = {
    tableName: PropTypes.string.isRequired,
    endpoint: PropTypes.string.isRequired
}
