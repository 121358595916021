import React, {useEffect} from 'react';
import PropTypes from 'prop-types'
import {useDispatch, useSelector} from 'react-redux';
import './FormFields.css';
import {Dropdown} from '../commonFields/Dropdown/Dropdown';
import {getLocatorId} from 'utils/locator';
import {AsyncDropdown} from "components/commonFields/AsyncDropdown/AsyncDropdown";

const FormDropdown = ({
      label,
      isRequired,
      content,
      placeholder,
      actionType,
      fieldName,
      error,
      defaultValue,
      errorsSelector,
      ...props
}) =>
{
    const dispatch = useDispatch();
    const { [fieldName]: errors } = Boolean(error) ? { [fieldName]: {} } : useSelector(errorsSelector);

    useEffect(() => {
        if (defaultValue && !props?.infiniteScroll) {
            dispatch({
                type: actionType,
                payload: Array.isArray(defaultValue) ? defaultValue.map(item => item.value) : defaultValue?.value
            });
        }
    }, []);

    const handleChange = value => {
        dispatch({
            type: actionType,
            payload: props.isValueArray ? [value?.value] : value?.value // todo temporary
        });
    }

    return (
        <div className='form-field-container'>
            <div className='form-field-label'>
                <span>{label}</span>
                {isRequired && <sup>*</sup>}
            </div>
            {
                props?.infiniteScroll ? (
                    <AsyncDropdown
                        loadOptions={props?.loadOptions}
                        placeholder={placeholder}
                        isSearchable={props?.isSearchable}
                        handleDropdownChange={props?.handleDropdownChange}
                        dataTestId={getLocatorId('form-dropdown', label)}
                        infiniteScroll={props?.infiniteScroll}
                        defaultValue={defaultValue}
                        isReadOnly={props.isReadOnly}
                        isMulti={props.isMulti}
                    />
                ) : (
                    <Dropdown
                        options={content}
                        onChange={handleChange}
                        placeholder={placeholder}
                        dataTestId={getLocatorId('form-dropdown', label)}
                        defaultValue={defaultValue}
                        isDisabled={props.isDisabled}
                        isReadOnly={props.isReadOnly}
                        isMulti={props.isMulti}
                    />
                )
            }
            {errors?.length && errors.map((item, index) => {
                return (
                    <div
                        key={index}
                        className='form-field-error-container'
                    >
                        {item}
                    </div>
                )})
            }
            {Boolean(error) &&
                <div
                        key={error}
                        className='form-field-error-container'
                    >
                        {error}
                </div>
            }
        </div>
    )
}

export default FormDropdown;

FormDropdown.propTypes = {
    fieldName: PropTypes.string.isRequired,
    label: PropTypes.string,
    isRequired: PropTypes.bool,
    content: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]).isRequired,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]).isRequired
    })),
    placeholder: PropTypes.string,
    actionType: PropTypes.string,
    defaultValue: PropTypes.shape({
        label: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.any
        ]),
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.any
        ])
    }),
    errorsSelector: PropTypes.func
};

FormDropdown.defaultProps = {
    content: []
}