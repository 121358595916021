import React from 'react'
import './myProfile.css'
import Layout from 'components/container/Layout'
import {useSelector} from 'react-redux'
import Loader from 'components/global/Loader/Loader'
import { myProfile } from 'store/selectors/myProfile'
import { MyProfileSectionContainer } from 'components/forms/MyProfileSection/MyProfileSection'
import { PageTitles } from 'utils/constants'
import { Page } from 'components/global/Page/Page'
import {auth} from 'store/selectors/auth';

export const MyProfilePage = () => {
	const isLoading = useSelector(myProfile.isLoading)
	const { last_password_change_date } = useSelector(auth.userInfo)

	const myProfileSections = {
		info: {
			label: 'Info',
			subSection: {
				label: 'Info',
			},
		},

		auth: {
			label: 'Two factor authentication',
			content: [{ label: 'Two factor authentication' }],
			subSection: {
				label: 'Edit two factor authentication'
			},
		},

		password: {
			label: 'Password',
			subSection: {
				label: 'Change password',
				content: []
			},
			navigateLink: '/app/dashboard',
			content: [{ label: 'Last change on', value: last_password_change_date }],
		},
	}

	const ViewMyProfile = (
		<Page title={PageTitles.MyProfile}>
			{!isLoading ? (
				<MyProfileSectionContainer sections={myProfileSections} />
			) : (
				<Loader />
			)}
		</Page>
	)

	return <Layout component={ViewMyProfile} />
}
