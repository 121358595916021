import React from 'react'
import LoginPage from "components/container/LoginPage/LoginPage";
import {ResetPasswordSuccessForm} from "components/forms/ResetPasswordForm/ResetPasswordSuccess";

const ResetPasswordSuccess = () => {
    return (
        <LoginPage Form={ResetPasswordSuccessForm} />
    )
}
export default ResetPasswordSuccess;
