import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    currentPage,
    filterPage,
    searchPage,
    sortingData,
    userPerPage,
    updatedData
} from 'store/selectors/selectors';
import { GET_ROLES } from 'common/endpoints';
import {FetchMethods, Sorting, sortingDirections} from 'utils/constants';
import fetchApi from 'common/fetchApi';

export const useGetRolesList = () => {
    const dispatch = useDispatch();
    const current = useSelector(currentPage);
    const entriesPerPage = useSelector(userPerPage);
    const search = useSelector(searchPage);
    const filter = useSelector(filterPage);
    const isUpdated = useSelector(updatedData);
    const { direction, column } = useSelector(sortingData);

    const [isLoaded, setIsLoaded] = useState();

    const params = {
        page: current,
        per_page: entriesPerPage,
        sort_by: column,
        range: direction === sortingDirections.DOWN ? Sorting.Asc : Sorting.Desc,
        search: search ? search : '',
        user_type_id: filter && filter!=='all' ? filter : ''
    };

    useEffect(() => {
        setIsLoaded(false);

        fetchApi(GET_ROLES, {
            method: FetchMethods.Get,
            params
        })
            .then((response) => {
                dispatch.roles.setTableData(response.data);
                setIsLoaded(true);
            })
            .catch((error) => {
                setIsLoaded(true);
            })
            .finally(() => dispatch.roles.setIsUpdatedData(false))
    }, [current, entriesPerPage, direction, column, search, filter, isUpdated]);

    return { isLoaded };
};