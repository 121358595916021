import React from 'react';
import Header from '../global/Header/Header';
import Navigation from '../global/Navigation/Navigation';
import { Footer } from 'components/global/Footer';
import { UserInfo } from 'components/global/UserInfo/UserInfo';

const SideBar = () => (
    <div className="min-w-[284px] flex flex-col bg-white shadow-sidebar" data-testid='sidebar'>
        <Header />
        <UserInfo />
        <Navigation />
        <Footer />
    </div>
);

export default SideBar;