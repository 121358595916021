import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import './AddFeatures.css';
import { SwitcherItemsContainer } from 'components/global/SwitcherComponent/SwitcherItemsContainer/SwitcherItemsContainer';
import {
    BENEFIT_CONFIGURATION,
    CLIENT_MANAGEMENT,
    USER_MANAGEMENT,
    ADD_CHECKBOX,
    EDIT_CHECKBOX,
    READ_CHECKBOX,
    DELETE_CHECKBOX,
    USERS_GROUP,
    ROLES_GROUP,
    BENEFITS_GROUP
} from 'utils/messages';
import {ButtonLabels, ButtonSizes, ButtonTypes} from 'utils/constants';
import { newRoleErrors } from 'store/selectors/selectors';
import { getSwitcherComponents } from 'utils/helpers/features.helper';
import {Button} from "components/buttons/Button/Button";
import {ErrorMessage} from "components/global/ErrorMessage/ErrorMessage";

const getPreparedData = (checkedArray) => {
    return checkedArray?.reduce((acc, item) => {
        if (item === ADD_CHECKBOX) acc.create = 1;
        if (item === EDIT_CHECKBOX) acc.update = 1;
        if (item === READ_CHECKBOX) acc.view = 1;
        if (item === DELETE_CHECKBOX) acc.delete = 1;
        return acc;
    }, {});
};

export const AddFeatures = () => {
    const dispatch = useDispatch();
    const { handleSubmit } = useForm();
    const { actions } = useSelector(newRoleErrors);

    const handleUsersChanged = checked => {
        dispatch({
            type: 'newRole/setUsersActions',
            payload: getPreparedData(checked)
        });
    };

    const handleRolesChanged = checked => {
        dispatch({
            type: 'newRole/setRolesActions',
            payload: getPreparedData(checked)
        });
    };

    const handleBenefitChanged = checked => {
        dispatch({
            type: 'newRole/setBenefitsActions',
            payload: getPreparedData(checked)
        });
    };

    const items = [
        {
            label: BENEFIT_CONFIGURATION,
            content: null
        },
        {
            label: CLIENT_MANAGEMENT,
            content: getSwitcherComponents({
                items: [
                    {
                        name: BENEFITS_GROUP,
                        onChange: handleBenefitChanged
                    }
                ]
            })
        },
        {
            label: USER_MANAGEMENT,
            content: getSwitcherComponents({
                items: [
                    {
                        name: USERS_GROUP,
                        onChange: handleUsersChanged
                    },
                    {
                        name: ROLES_GROUP,
                        onChange: handleRolesChanged
                    }
                ]
            })
        }
    ];

    const handleBack = (event) => {
        event.preventDefault();
        dispatch({
            type: 'newRole/setStepInfo',
            payload: {
                step: 2,
                isExpanded: false
            }
        });
        dispatch({
            type: 'newRole/setStepInfo',
            payload: {
                step: 1,
                isExpanded: true
            }
        });
    };
    
    const onSubmit = () => {
        dispatch({
            type: 'newRole/setStepInfo',
            payload: {
                step: 2,
                isExpanded: false,
                isCompleted: true
            }
        });
        dispatch({
            type: 'newRole/setStepInfo',
            payload: {
                step: 3,
                isExpanded: true
            }
        });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='add-feature-form'>
            <SwitcherItemsContainer items={items} checkedItem={actions?.length ? 2 : null} />
            {actions?.length ?
                actions.map((item, index) => {
                    return <ErrorMessage key={index} message={item} />
                }) : null
            }
            <div className='add-feature-form-buttons'>
                <Button
                    handleOnClick={handleBack}
                    label={ButtonLabels.Back}
                    type={ButtonTypes.Secondary}
                    size={ButtonSizes.Medium}
                    dataTestId='button-step-back'
                />
                <div className='add-feature-form-buttons-next'>
                    <Button
                        handleOnClick={handleSubmit(onSubmit)}
                        label={ButtonLabels.Next}
                        type={ButtonTypes.Primary}
                        size={ButtonSizes.Medium}
                        dataTestId='button-step-next'
                    />
                </div>
            </div>
        </form>
    );
};