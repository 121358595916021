import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from 'gatsby';
import { Link, Element } from 'react-scroll'
import './editUser.css';
import Layout from 'components/container/Layout';
import PageHeader from 'components/global/PageHeader/PageHeader';
import {commonUserTypes, editUser} from 'store/selectors/selectors';
import Loader from 'components/global/Loader/Loader';
import { useForm } from 'react-hook-form';
import { EditUserInfo } from 'components/forms/EditUserInfo/EditUserInfo';
import {ButtonLabels, ButtonSizes, ButtonTypes} from 'utils/constants';
import { toast } from 'react-toastify'
import { EDIT_USER_INFO, EDIT_FEATURES, EDIT_2FA } from 'utils/messages'
import {useGetUserTypes} from 'hooks/useGetUserTypes';
import { prepareDate } from 'utils/helpers/format.helper'
import {Button} from "components/buttons/Button/Button";
import {Icons, IconsNames} from "utils/icons";
import { EditUserAuthentication } from 'components/forms/EditUserAuthentication/EditUserAuthentication'
import { EditUserFeatures } from 'components/forms/EditUserFeatures/EditUserFeatures'
import { camelize, removeSpec } from 'utils/helpers/tables.helper'

export const EditUserPage = () => {
    const dispatch = useDispatch();
    const { handleSubmit, register, reset, control } = useForm();

    const {
        headerLabel,
        rowData,
        role,
        status,
        data,
        user_type_id,
        isSending,
        successMessage,
        isError,
    } = useSelector(editUser)

    const { isLoaded: isUserTypesListLoaded } = useGetUserTypes();
    const userTypes = useSelector(commonUserTypes);
    const [activeLink, setActiveLink] = useState('info');

    useEffect(() => {
        return () =>
            dispatch({
                type: 'editUser/setClearStore'
            });
    }, []);

    useEffect(() => {
        reset({
            ...data
        })
    }, [data])

    useEffect(() => {
        if (Boolean(successMessage) && !isError) {
            const navigation = async () => await navigate('/app/users');

            navigation()
            toast.success(successMessage)
            dispatch({
                type: 'editUser/setClearStore'
            })
        }
        
    }, [successMessage, isError])

    const handleCLick = (e) => {
        setActiveLink(removeSpec(camelize(e.target.innerText)));
    };

    const handleBack = async (event) => {
        event.preventDefault()
        dispatch({
            type: 'editUser/setClearStore'
        });
        await navigate('/app/users');
    };

    const onSubmit = async (data) => {
        dispatch({
            type: 'editUser/setSubmittedData',
            payload: {
                rowData,
                role,
                status,
                user_type_id,
                data: {
                    ...data,
                    date_of_birth: prepareDate(data.date_of_birth)
                }
            }
        });
    };

    const EditUser = (
        <div className='edit-user-main-container'>
            <div className='edit-user-header-container'>
                <PageHeader title={headerLabel} />
                <ul className='edit-user-navigation-container' data-testid='edit-user-navigation-container'>
                    <Link
                        id='info'
                        to='info'
                        smooth={true}
                        duration={250}
                        containerId='containerElement'
                        onClick={handleCLick}
                        className={activeLink === 'info' ? 'active' : 'edit-user-navigation-link'}
                    >
                        <li data-testid='edit-user-navigation-info'>
                                {EDIT_USER_INFO}
                        </li>
                    </Link>
                    <Link
                        id='editTwoFactorAuthentication'
                        to='editTwoFactorAuthentication'
                        smooth={true}
                        duration={250}
                        containerId='containerElement'
                        onClick={handleCLick}
                        className={activeLink === 'editTwoFactorAuthentication' ? 'active' : 'edit-user-navigation-link'}
                    >
                        <li data-testid='edit-user-navigation-2fa'>
                            {EDIT_2FA}
                        </li>
                    </Link>
                    <Link
                        id='featuresDataAccess'
                        to='featuresDataAccess'
                        smooth={true}
                        duration={250}
                        containerId='containerElement'
                        onClick={handleCLick}
                        className={activeLink === 'featuresDataAccess' ? 'active' : 'edit-user-navigation-link'}
                    >
                        <li data-testid='edit-user-navigation-features'>
                                {EDIT_FEATURES}
                        </li>
                    </Link>
                </ul>
            </div>
            {isUserTypesListLoaded && !isSending ?
                <Element id='containerElement' className='edit-user-scroll-container'>
                    <form
                        className='edit-user-form-container'
                        data-testid='edit-user-form'
                    >
                        <>
                            <Element name='info'>
                                <EditUserInfo
                                    register={register}
                                    control={control}
                                    userTypes={userTypes}
                                />
                            </Element>
                            <Element name='editTwoFactorAuthentication'>
                                <EditUserAuthentication />
                            </Element>
                            <Element name='featuresDataAccess'>
                                <EditUserFeatures />
                            </Element>
                            <div className='edit-user-buttons-container'>
                                <Button
                                    handleOnClick={handleSubmit(onSubmit)}
                                    label={ButtonLabels.Save}
                                    type={ButtonTypes.Primary}
                                    size={ButtonSizes.Medium}
                                    icon={Icons[IconsNames.Check]}
                                    dataTestId='button-step-next'
                                />
                                <div className='edit-user-back-button'>
                                    <Button
                                        handleOnClick={handleBack}
                                        label={ButtonLabels.Back}
                                        type={ButtonTypes.Secondary}
                                        size={ButtonSizes.Medium}
                                        dataTestId='button-step-back'
                                    />
                                </div>
                            </div>
                        </>
                    </form>
                </Element>
                :
                <div className='h-screen'>
                    <Loader />
                </div>
            }
        </div>
    );

    return <Layout component={EditUser} />;
}