import React from 'react';
import PropTypes from 'prop-types';
import StepItem from './StepItem';
import StepCircle from './StepCircle';

const StepperContainer = ({ steps, storeLocation }) => (
    <div className="mt-[24px] h-full flex flex-col">
        {steps.map(item =>
            <div key={item.step} className="flex mt-[8px]">
                <StepCircle step={item.step} storeLocation={storeLocation} />
                <StepItem
                    step={item.step}
                    header={item.header}
                    storeLocation={storeLocation}
                    Component={item.content}
                />
            </div>
        )}
    </div>
);

export default StepperContainer;

StepperContainer.propTypes = {
    steps: PropTypes.arrayOf(PropTypes.shape({
        step: PropTypes.number,
        header: PropTypes.string,
        content: PropTypes.func
    })).isRequired,
    storeLocation: PropTypes.string.isRequired
}