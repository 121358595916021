import React, {useEffect, useState} from 'react';
import QRCode from "react-qr-code";
import {AUTHENTICATION_MESSAGE, SECRET_KEY} from 'utils/messages';
import { useSelector } from 'react-redux';
import { auth } from 'store/selectors/auth';
import { SET_MFA } from 'common/endpoints';
import fetchApi from 'common/fetchApi';
import Loader from 'components/global/Loader/Loader';
import {FetchMethods} from 'utils/constants';
import styled from 'styled-components/macro';
import tw from 'twin.macro';

const QrContainer = styled.div`
	${tw`mt-4 flex flex-col items-center justify-center`}
`

const QrInnerContainer = styled.div`
	${tw`my-4 break-all`}
`

export const QrModalMessage = () => {
    const { id: userID } = useSelector(auth.userInfo)
    const [mfaData, setMfaData] = useState();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchMfaData = async () => {
            try {
                const response = await fetchApi(SET_MFA(userID), {
                    method: FetchMethods.Post
                });
                setMfaData(response.data)
            } catch (error) {
                console.log(error)
            }
            finally {
                setIsLoading(false);
            }
        }

        if (!mfaData) {
            fetchMfaData();
        }
    }, [])

    return (
        <div>
            {AUTHENTICATION_MESSAGE}
            <QrContainer>
                {
                    isLoading ?
                        <Loader />
                        : <>
                            <QRCode
                                value={mfaData?.url}
                                bgColor='#EFEFEF'
                                fgColor='#7E22D6'
                            />
                            <QrInnerContainer>
                                {SECRET_KEY(mfaData?.secret)}
                            </QrInnerContainer>
                        </>
                }
            </QrContainer>
        </div>
    )
}
