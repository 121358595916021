import './SwitcherItem.css';
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { Switcher } from '../../../commonFields/Switcher/Switcher';

export const SwitcherItem = ({ label, content, isCheckedByDefault, onSwitcherItemChanged }) => {
    const [defaultValue, setDefaultValue] = useState(isCheckedByDefault);
    const [checked, setChecked] = useState(isCheckedByDefault);

    useEffect(() => {
        setDefaultValue(isCheckedByDefault);
    }, [isCheckedByDefault]);

    const onChange = () => {
        setChecked(!checked);
        setDefaultValue(false);
        onSwitcherItemChanged({ isActivated: !checked })
    };

    return (
        <div className='switcher-item-wrapper'>
            <div className='switcher-item'>
                <Switcher
                    label={label}
                    onChangeCallback={onChange}
                    isCheckedByDefault={checked || defaultValue}
                />
            </div>
            <div className={(checked || defaultValue) && content ? 'switcher-content' : 'switcher-content-hidden' }>
                {content}
            </div>
        </div>
    )
}

SwitcherItem.propTypes = {
    label: PropTypes.string,
    content: PropTypes.node,
    isCheckedByDefault: PropTypes.bool
}