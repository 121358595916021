import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
    ADD_USERS_DROPDOWN_LABEL,
    ADD_USERS_DROPDOWN_PLACEHOLDER,
    ADD_USERS_CHECKBOX_LIST_HEADER,
    EDIT_USERS
} from 'utils/messages';
import {
    convertOptionToCheckbox,
    convertCheckboxToOption,
    convertUserToCheckbox
} from './common/PrepareDataHelpers'
import { DropdownCheckboxList } from '../commonFields/DropdownCheckboxList/DropdownCheckboxList';
import { roleData } from 'store/selectors/selectors';
import {loadCallback} from "utils/helpers/usersList.helper";
import tw from 'twin.macro'
import styled from 'styled-components/macro'

const Header = styled.div`
    ${tw`font-base font-semibold text-big leading-big text-dark-black`}
`

export const EditUsers = ({ users }) => {
    const dispatch = useDispatch();

    const { checkboxes, options } = useSelector(roleData);

    useEffect(() => {
        if (users) {
            const currentCheckboxes = users.map((user) => {
                return convertUserToCheckbox(user)
            })
    
            dispatch({
                type: 'editRole/setCheckboxes',
                payload: {
                    checkboxes: [...currentCheckboxes]
                }
            })
        }
    }, [users])

    const handleDropdownChange = (dropdownOption) => {
        dispatch({
            type: 'editRole/setCheckboxes',
            payload: {
                checkboxes: [...checkboxes, convertOptionToCheckbox(dropdownOption)]
            }
        })

        dispatch({
            type: 'editRole/setOptions',
            payload: {
                options: [...options.filter((option) => option.value !== dropdownOption.value)]
            }
        })
    }

    const handleCheckboxListChange = (checkboxOption) => {
        if (!checkboxOption.selected) {
            dispatch({
                type: 'editRole/setCheckboxes',
                payload: {
                    checkboxes: [...checkboxes.filter((checkbox) => checkbox.id !== checkboxOption.id)]
                }
            })

            dispatch({
                type: 'editRole/setOptions',
                payload: {
                    options: [convertCheckboxToOption(checkboxOption), ...options]
                }
            })
        }
    }

    return (
        <div>
            <Header data-testid='edit-role-users-header'>{EDIT_USERS}</Header>
            <DropdownCheckboxList
                loadCallback={loadCallback}
                checkboxes={checkboxes}
                excludedOptions={checkboxes}
                placeholder={ADD_USERS_DROPDOWN_PLACEHOLDER}
                dropdownLabel={ADD_USERS_DROPDOWN_LABEL}
                checkboxListHeader={ADD_USERS_CHECKBOX_LIST_HEADER}
                handleDropdownChange={handleDropdownChange}
                onCheckboxListChange={handleCheckboxListChange}
                defaultOptions={options}
                isSearchable
                infiniteScroll
                dataTestId='edit-users-checkbox-list-dropdown'
            />
        </div>
    );
};

EditUsers.propTypes = {
    users: PropTypes.arrayOf(PropTypes.object)
}