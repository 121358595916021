
export const getCheckboxLabels = (labels) => getObjectKeys(labels)

export const getDefaultActions = (actions) => {
    if (!actions) {
        return {};
    }

	return Object.keys(actions).reduce(
		(checkboxes, checkbox) => ({ ...checkboxes, [checkbox]: 0 }),
		{}
	)
}

export const convertCheckboxesToActions = (checkedCheckboxes) => {
    if (Boolean(checkedCheckboxes) && !checkedCheckboxes.length) {
        return {};
    }

	return checkedCheckboxes.reduce((checkboxes, checkbox) => {
		return {
			...checkboxes,
			[checkbox]: 1,
		}
	}, {})
}

export const getObjectKeys = (object) => {
    if (!object) {
        return [];
    }

    return Object.keys(object);
}

export const getActivatedActions = (actions) => {
    const checkboxes = getObjectKeys(actions)

    return checkboxes.filter((action) => Boolean(actions[action]));
}