import React, {useEffect, useState} from 'react';
import './EditUserInfo.css';
import {
    EDIT_USER_FNAME,
    EDIT_USER_SNAME,
    EDIT_USER_BIRTHDAY,
    EDIT_USER_EMAIL,
    EDIT_USER_USER_TYPE,
    EDIT_USER_USER_TYPE_PLACEHOLDER,
    EDIT_USER_USER_ROLE,
    EDIT_USER_USER_ROLE_PLACEHOLDER,
    EDIT_USER_STATUS,
    EDIT_USER_USER_STATUS_PLACEHOLDER,
    EDIT_USER_INFO
} from 'utils/messages';
import { buildDropdownOption } from 'utils/helpers/dropdown.helper'
import FormInput from '../../formFields/FormInput';
import {editUserErrors, editUser} from 'store/selectors/selectors';
import FormDropdown from '../../formFields/FormDropdown';
import { prepareDropdownList } from '../common/PrepareDataHelpers';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {FormDatePicker} from '../../formFields/FormDatePicker';
import {loadCallback} from "utils/helpers/rolesList.helper";
import Loader from "components/global/Loader/Loader";

const userStatuses = [
    {
        value: 1,
        label: 'Active'
    },
    {
        value: 0,
        label: 'Disabled'
    }
];

const getDefaultValue = (array, field) => {
    return array?.find(item => {
        return item.label.toLowerCase() === field?.toLowerCase();
    });
}

export const EditUserInfo = ({ register, control, userTypes }) => {
    const { isLoading, data: user, errors } = useSelector(editUser);
    const dispatch = useDispatch();

    const [roleState, setRoleState] = useState(null)

    const [userType, setUserType] = useState()
    const [userTypeOptions, setUserTypeOptions] = useState()

    const [status, setStatus] = useState()
    const [statusOptions, setStatusOptions] = useState()

    useEffect(() => {
        if (!isLoading && user) {
            const role = buildDropdownOption(user.roles, user.roles);

            setRoleState(role)
        }
    }, [user, isLoading])

    useEffect(() => {
        if (!isLoading && user) {
            const userStatus = userStatuses.find((status) => status.value === user.status);

            setStatus(userStatus)
            setStatusOptions(userStatuses)
        }
    }, [user, isLoading])

    useEffect(() => {
        const name = userTypes?.find((type) => Boolean(type.isDefault))?.name
        const preparedOptions = prepareDropdownList({ array: userTypes })
        const defaultOption = getDefaultValue(preparedOptions, name)

        setUserType(defaultOption)
        setUserTypeOptions(preparedOptions)
	}, [userTypes])

    useEffect(() => {
        if (user?.roles && userType?.value) {
            dispatch({
                type: 'editUser/setRoleData',
                payload: { role: user?.roles[0], userTypeId: userType?.value }
            })
        }
    }, [user, userType])

    const handleRoleChange = value => {
        dispatch({
            type: 'editUser/setRole',
            payload: [value?.label]
        });
        dispatch({
            type: 'editUser/setRoleData',
            payload: { role: value?.label, userTypeId: userType?.value }
        })
    }

    return (
        <div className='edit-user-container'>
            <div className='edit-user-info-header' data-testid='edit-user-info-header'>{EDIT_USER_INFO}</div>
            {
                roleState && status ?
                    (
                        <div className='edit-user-columns'>
                            <div>
                                <FormInput
                                    label={EDIT_USER_FNAME}
                                    content={user?.firstname}
                                    isRequired
                                    register={register}
                                    fieldName='first_name'
                                    error={errors['first_name']}
                                    errorsSelector={editUserErrors}
                                />
                                <FormInput
                                    label={EDIT_USER_SNAME}
                                    content={user?.surname}
                                    isRequired
                                    register={register}
                                    error={errors['surname']}
                                    fieldName='surname'
                                    errorsSelector={editUserErrors}
                                />
                                <FormDatePicker
                                    label={EDIT_USER_BIRTHDAY}
                                    isRequired
                                    content={user?.date_of_birth}
                                    control={control}
                                    errors={errors['date_of_birth']}
                                    fieldName='date_of_birth'
                                    errorsSelector={editUserErrors}
                                />
                                <FormInput
                                    label={EDIT_USER_EMAIL}
                                    content={user?.email}
                                    isRequired
                                    register={register}
                                    error={errors['email']}
                                    fieldName='email'
                                    errorsSelector={editUserErrors}
                                />
                            </div>
                            <div className='edit-user-column'>
                                {Boolean(userType) && <FormDropdown
                                    label={EDIT_USER_USER_TYPE}
                                    content={userTypeOptions}
                                    defaultValue={userType}
                                    placeholder={EDIT_USER_USER_TYPE_PLACEHOLDER}
                                    register={register}
                                    fieldName='user_type_id'
                                    error={errors['user_type_id']}
                                    actionType='editUser/setUserType'
                                    errorsSelector={editUserErrors}
                                    isRequired
                                    isReadOnly
                                />}
                                {Boolean(roleState) && (
                                    <FormDropdown
                                        loadOptions={loadCallback(userType?.value)}
                                        placeholder={EDIT_USER_USER_ROLE_PLACEHOLDER}
                                        defaultValue={roleState}
                                        isSearchable
                                        label={EDIT_USER_USER_ROLE}
                                        handleDropdownChange={handleRoleChange}
                                        infiniteScroll
                                        errorsSelector={editUserErrors}
                                        fieldName='role_name'
                                        isRequired
                                    />
                                )}
                                {Boolean(status) && <FormDropdown
                                    label={EDIT_USER_STATUS}
                                    content={statusOptions}
                                    defaultValue={status}
                                    placeholder={EDIT_USER_USER_STATUS_PLACEHOLDER}
                                    register={register}
                                    error={errors['status']}
                                    fieldName='status'
                                    actionType='editUser/setStatus'
                                    errorsSelector={editUserErrors}
                                    isRequired
                                />}
                            </div>
                        </div>
                    ) : (
                        <Loader />
                    )
            }
        </div>
    );
}

EditUserInfo.propTypes = {
    userTypes: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired
    })),
    register: PropTypes.func.isRequired,
    control: PropTypes.object.isRequired,
}
