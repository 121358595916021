import React, { useState, useEffect } from 'react';
import './ExpandableNavigationItem.css';
import arrowActive from '../../../../icons/navigation_arrow_active.svg';
import arrow from '../../../../icons/navigation_arrow.svg';
import {NavigationItem} from '../NavigationItem/NavigationItem';
import PropTypes from "prop-types";

export const ExpandableNavigationItem = ({ label, icon, content, currentItem }) => {
    const [clicked, setClicked] = useState(false);

    useEffect(() => {
        const clicked = content.find(item => item.label === currentItem)
        if (clicked) {
            setClicked(true)
        }
    }, [])

    const handleClick = () => {
        setClicked(!clicked);
    };

    return (
        <>
            <div
                className={clicked ? 'expandable-navigation-item-container-opened' : 'expandable-navigation-item-container'}
                onClick={handleClick}
                data-testid={`navigation-menu-${label}`}
            >
                <div className='expandable-navigation-item-inner-container'>
                    <img
                        alt={label}
                        src={icon}
                        className='expandable-navigation-item-icon'
                    />
                    <div>{label}</div>
                </div>
                <img
                    alt={label}
                    src={clicked ? arrowActive : arrow}
                    className={clicked ? 'expandable-navigation-item-arrow-opened' : 'expandable-navigation-item-arrow'}
                />
            </div>
            {clicked &&
                <ul data-testid='navigation-menu-links'>
                    {content?.map(item => {
                        return (
                            <li key={item.label}>
                                <NavigationItem
                                    navigateLink={item.navigateLink}
                                    label={item.label}
                                    currentItem={currentItem}
                                />
                            </li>
                        )
                    })}
                </ul>
            }
        </>
    )
};

ExpandableNavigationItem.propTypes = {
    label: PropTypes.string.isRequired,
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    content: PropTypes.arrayOf(PropTypes.shape({
        navigateLink: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired
    })),
    currentItem: PropTypes.string.isRequired
}