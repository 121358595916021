import React, { useEffect, useState } from 'react'
import './PasswordSectionForm.css'
import PropTypes from 'prop-types'
import { Button } from 'components/buttons/Button/Button'
import { useForm } from 'react-hook-form'
import { ButtonLabels, ButtonTypes, FetchMethods } from 'utils/constants'
import { NEW_PASSWORD, OLD_PASSWORD } from 'utils/messages'
import { FormPassword } from 'components/formFields/FormPassword/FormPassword'
import { Icons, IconsNames } from 'utils/icons'
import { toast } from 'react-toastify'
import {CHANGE_PASSWORD, CHECK_THE_PASSWORD_CRITERIA, GET_USER_INFO} from 'common/endpoints'
import fetchApi from 'common/fetchApi'
import {useDispatch, useSelector} from 'react-redux';
import Loader from 'components/global/Loader/Loader';
import {myProfile} from 'store/selectors/myProfile';

const ChangePasswordForm = ({ handleOnSave, handleOnCancel, dataTestId }) => {
	const { handleSubmit, register } = useForm()

	const [newPasswordQuery, setNewPasswordQuery] = useState('')
	const [oldPasswordQuery, setOldPasswordQuery] = useState('')
	const [errors, setErrors] = useState()

	const fetchData = async (data) => {
		try {
			await fetchApi(CHECK_THE_PASSWORD_CRITERIA, {
				method: FetchMethods.Post,
				data,
			})

			setErrors(null)
		} catch (error) {
			const [{ checks }] = error.response.data
			setErrors(checks)
		}
	}

	useEffect(() => {
		const timeOutId = setTimeout(
			() => handlePasswordChange(newPasswordQuery),
			500
		)
		return () => clearTimeout(timeOutId)
	}, [newPasswordQuery])

	const handlePasswordChange = async (password) => {
		if (password) {
			await fetchData({ password })
		}
	}

	const onSubmit = (data) => {
		handleOnSave(data)
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="change-password-form-container">
				<FormPassword
					label={OLD_PASSWORD}
					fieldName="current_password"
					register={register}
					isRequired={false}
					onChange={setOldPasswordQuery}
					errors={errors}
					dataTestId={`${dataTestId}-old-password`}
				/>
				<FormPassword
					label={NEW_PASSWORD}
					fieldName="password"
					register={register}
					isRequired={false}
					onChange={setNewPasswordQuery}
					errors={errors}
					dataTestId={`${dataTestId}-new-password`}
				/>
				<ul className="change-password-criteria-container">
					{errors?.map((error) => (
						<li
							key={error.name}
							className={`change-password-criteria-item ${error.status}`}
						>
							{error.name}
						</li>
					))}
				</ul>
			</div>
			<div className="change-password-navigation-buttons">
				<Button
					label={ButtonLabels.Save}
					icon={Icons[IconsNames.Check]}
					type={ButtonTypes.Primary}
					handleOnClick={() => {}}
					dataTestId={`${dataTestId}-save-button`}
				/>
				<Button
					label={ButtonLabels.Cancel}
					type={ButtonTypes.Secondary}
					handleOnClick={handleOnCancel}
					dataTestId={`${dataTestId}-cancel-button`}
				/>
			</div>
		</form>
	)
}

const PasswordForm = ({ section }) => {
	return section.content.map(({ label, value }) => {
		return (
			<div key={label} className="password-form-field">
				<div className="password-form-label">{label}</div>
				<div className="password-form-value">{value}</div>
			</div>
		)
	})
}

export const PasswordSectionForm = ({ section, isEdit, onChange }) => {
	const dispatch = useDispatch()
	const isUserPasswordSending = useSelector(myProfile.isUserPasswordSending)

	const handleOnSave = async (data) => {
		dispatch.myProfile.setIsUserPasswordSending({ isSending: true })
		try {
			const response = await fetchApi(CHANGE_PASSWORD, {
				method: FetchMethods.Patch,
				data,
			})

			const userInfoResponse = await fetchApi(GET_USER_INFO);
			dispatch.auth.setUserInfo({...userInfoResponse.data});

			toast.success(response.data.message)
			onChange()
		} catch (error) {
			const {message} = error.response.data

			if (message) {
				toast.error(message)
			}
		}
		finally {
			dispatch.myProfile.setIsUserPasswordSending({ isSending: false })
		}
	}

	const handleOnCancel = (e) => {
		e.preventDefault()
		onChange()
	}

	if (isUserPasswordSending) {
		return <Loader />
	}

	return isEdit ? (
		<ChangePasswordForm
			handleOnCancel={handleOnCancel}
			handleOnSave={handleOnSave}
			dataTestId="change-password-form"
		/>
	) : (
		<PasswordForm section={section} />
	)
}

PasswordSectionForm.propTypes = {
	section: PropTypes.object,
	isEdit: PropTypes.bool,
	onChange: PropTypes.func,
}

PasswordForm.propTypes = {
	section: PropTypes.object,
}

ChangePasswordForm.propTypes = {
	handleOnSave: PropTypes.func,
	handleOnCancel: PropTypes.func,
	dataTestId: PropTypes.string,
}
