import React from 'react'
import { useSelector } from 'react-redux'
import { rightPanel } from '@/store/selectors/selectors'
import { TitleDescriptionLabel } from '@/components/commonFields/TitleDescriptionLabel/TitleDescriptionLabel'
import tw from 'twin.macro'
import styled from 'styled-components/macro'

const AboutThisRoleContainer = styled.div`
	${tw`items-center justify-start flex-col w-full h-full flex`}
`

const titles = [
	{
		label: 'Title',
		field: 'name',
	},
	{
		label: 'User type',
		field: 'user_type',
	},
	{
		label: 'Description',
		field: 'description',
	},
]

export const AboutThisRole = () => {
	const { data, isLoading } = useSelector(rightPanel)

	return (
		!isLoading && (
			<AboutThisRoleContainer>
				{titles.map((title) => {
					return (
						<TitleDescriptionLabel
							key={title.label}
							title={title.label}
							description={data.role[title.field]}
							dataTestId={`title-description-label-${title.label}`}
						/>
					)
				})}
			</AboutThisRoleContainer>
		)
	)
}
