import React from 'react';
import PropTypes from 'prop-types';
import TableRow from 'components/global/Table/TableRow/TableRow';
import noResultsPicture from '../../../../static/media/no-results.png';
import { NO_RESULTS_ALT } from 'utils/messages';
import Loader from 'components/global/Loader/Loader';
import './Table.css'

const Table = ({
   data,
   isLoaded,
   rows,
   headerComponent,
   linkIndex,
   renderPaginationComponent,
   menuItems,
   isRowMenuCollapsed,
   dataTestId,
   contextType,
   highlightedRowId,
   handleTableRowClick,
}) => {
    const TableContent =
			isLoaded && data?.length ? (
				data?.map((item, index) => (
					<TableRow
						key={index}
						rows={rows}
						rowData={item}
						linkIndex={linkIndex}
						contextType={contextType}
						highlightedRowId={highlightedRowId}
                        handleTableRowClick={handleTableRowClick}
                        menuItems={menuItems}
                        isRowMenuCollapsed={isRowMenuCollapsed}
                        dataTestId={dataTestId}
					/>
				))
			) : (
				<div className="flex justify-center items-center h-full w-full py-5 min-h-600">
					<img
						alt={NO_RESULTS_ALT}
						src={noResultsPicture}
						className="h-[410px] w-[403px]"
					/>
				</div>
			)

    return (
        <div className='table-content'
        >
            {headerComponent}
            {isLoaded ?
                TableContent
                : (
                    <div className="flex justify-center items-center min-h-600 w-full py-5">
                        <Loader />
                    </div>
                )
            }
            {renderPaginationComponent()}
        </div>
    );
}

export default Table;

Table.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    rows: PropTypes.arrayOf(PropTypes.string).isRequired,
    isLoaded: PropTypes.bool,
    headerComponent: PropTypes.element,
    renderPaginationComponent: PropTypes.func,
    highlightedRowId: PropTypes.number,
    menuItems: PropTypes.arrayOf(PropTypes.object),
    isRowMenuCollapsed: PropTypes.bool,
    dataTestId: PropTypes.string,
}