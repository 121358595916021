import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { commonUserTypes } from '../../../store/selectors/selectors';
import { Dropdown } from '../../commonFields/Dropdown/Dropdown';
import { prepareDropdownList } from '../../forms/common/PrepareDataHelpers';

const SearchRolesFilter = () => {
    const dispatch = useDispatch();
    const userTypes = useSelector(commonUserTypes);

    const defaultValue = [{ label: 'All', value: 'all' }];
    const preparedOptions = prepareDropdownList({ array: userTypes, defaultValues: defaultValue });

    const handleChange = value => {
        dispatch({
            type: 'roles/setFilterPage',
            payload: value?.value
        });
    }

    return(
        <Dropdown
            options={preparedOptions}
            onChange={handleChange}
            defaultValue={defaultValue}
            dataTestId='dropdown-filter-role'
        />
    );
}

export default SearchRolesFilter;