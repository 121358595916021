import React from 'react';
import PropTypes from 'prop-types';
import { SwitcherItem } from '../SwitcherItem/SwitcherItem';
import './SwitcherItemsContainer.css';

export const SwitcherItemsContainer = ({ items, checkedItems, onSwitcherContainerChanged}) => {
    const handleOnSwitcherChange = ({ isActivated }) => {
        if (onSwitcherContainerChanged) {
            onSwitcherContainerChanged({ isActivated })
        }
    }

    return (
        <div className='switcher-items-container'>
            {items.map((item, index) =>
                <SwitcherItem
                    key={item.label}
                    label={item.label}
                    content={item.content}
                    isCheckedByDefault={item?.checked || checkedItems?.includes(index)}
                    onSwitcherItemChanged={handleOnSwitcherChange}
                />
            )}
        </div>
    )
}

SwitcherItemsContainer.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        content: PropTypes.node
    })).isRequired,
    checkedItems: PropTypes.arrayOf(PropTypes.number)
}