import React, { useState } from 'react'
import './Menu.css'
import { MenuItem } from 'components/commonFields/MenuItem/MenuItem'
import PropTypes from 'prop-types'
import {NO_ITEMS} from 'utils/messages'
import {Tooltip} from 'components/global/Tooltip/Tooltip';
import {Icons, IconsNames} from 'utils/icons';
import tw from 'twin.macro'
import styled from 'styled-components/macro'

const InfoIconContainer = styled.div`
	${tw`absolute -left-7`}
`

const CollapsibleMenuItem = ({
	label,
	content,
    meta,
	defaultValue,
	handleMenuItemClick,
	dataTestId,
}) => {
	const [isExpanded, setIsExpanded] = useState(defaultValue)

	const handleClick = () => {
		setIsExpanded(!isExpanded)
	}

	return (
        <div className='menu-collapsible-container'>
            <div className='menu-collapsible-label-container'>
                {
                    meta?.suggested_info?.message &&
                    <InfoIconContainer>
                        <Tooltip
                            label={meta?.suggested_info?.message}
                            position='left'
                            Component={<span>{Icons[IconsNames.Info]()}</span>}
                        />
                    </InfoIconContainer>
                }
                <div className={isExpanded ? 'menu-collapsible-icon' : 'menu-collapsible-icon-collapsed'} />
                <div
                    onClick={handleClick}
                    className='menu-collapsible-label'
                    data-testid={`${dataTestId}-collapsibleMenu-${label}`}
                >
                    {label}
                </div>
            </div>
            {isExpanded && content.length ? content.map(item => {
                return <MenuItem
                    key={item?.label}
                    content={item}
                    onClick={() => handleMenuItemClick(item)}
                    dataTestId={dataTestId}
                />
            }) : null}
            {
                isExpanded && !content.length ?
                    <div className='menu-no-items'>{NO_ITEMS}</div>
                    : null
            }
        </div>
    )
};

export const Menu = ({ data, handleMenuItemClick, dataTestId }) => {
    return (
        <div className='menu-container'>
            {data.map((item, index) => {
                return <CollapsibleMenuItem
                    key={item?.label}
                    label={item?.label}
                    meta={item?.meta}
                    defaultValue={index === 0}
                    content={item?.content}
                    dataTestId={`${dataTestId}-menu`}
                    handleMenuItemClick={handleMenuItemClick}
                />
            })}
        </div>
    );
}

Menu.propTypes = {
    data: PropTypes.array.isRequired,
    defaultValue: PropTypes.bool
}

CollapsibleMenuItem.propTypes = {
    label: PropTypes.string.isRequired,
    content: PropTypes.array.isRequired,
    dataTestId: PropTypes.string.isRequired
}