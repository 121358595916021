import React from 'react'
import './TitleDescriptionLabel.css'
import PropTypes from 'prop-types';
import { Status } from 'utils/constants'
import { STATUS } from 'utils/messages'

const formateDescription = (title, description) => {
	if (title === STATUS) {
		return description === 1
			? Status.Active
			: Status.Disabled
	}

	if (!Boolean(description)) {
		return ''
	}

	return description
}

export const TitleDescriptionLabel = ({ title, description, dataTestId }) => {
	const formattedDescription = formateDescription(title, description)

	return (
		Boolean(formattedDescription) ? <div className="title-description-label-container">
			<span className="title" data-testid={`${dataTestId}-title`}>
				{title}
			</span>
			<span className="description" data-testid={`${dataTestId}-description`}>
				{formattedDescription}
			</span>
		</div> : null
	)
}

TitleDescriptionLabel.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.number,
		PropTypes.string,
	]),
    dataTestId: PropTypes.string
}