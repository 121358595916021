import React from 'react'
import './BenefitCategory.css'
import { BenefitComponentContainer } from 'components/benefit/BenefitComponent/BenefitComponent'
import PropTypes from 'prop-types';
import { camelize } from 'utils/helpers/tables.helper'
import { Element } from 'react-scroll'

const BenefitCategory = ({ label, content, viewId, header }) => {
	return (
		<div key={label} className="benefit-category">
			<div
				className="benefit-category-label"
				data-testid={`${label}-category-label`}
			>
				{label}
			</div>
			<div className="benefit-category-content">
				<BenefitComponentContainer
					components={content}
					viewId={viewId}
					header={header}
					dataTestId={label}
				/>
			</div>
		</div>
	)
}

export const BenefitCategoryContainer = ({ categories, viewId, header }) => {
	return (
		<div className="benefit-form-container">
			{categories.map(({ label, content }) => {
				return (
					<Element name={camelize(label)}>
						<BenefitCategory
							key={label}
							label={label}
							content={content}
							viewId={viewId}
							header={header}
						/>
					</Element>
				)
			})}
		</div>
	)
}

BenefitCategory.propTypes = {
    label: PropTypes.string.isRequired,
    content: PropTypes.array,
	viewId: PropTypes.string.isRequired,
	header: PropTypes.string.isRequired
}

BenefitCategoryContainer.propTypes = {
    categories: PropTypes.array.isRequired,
	viewId: PropTypes.string.isRequired,
	header: PropTypes.string.isRequired
}
