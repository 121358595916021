import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Layout from 'components/container/Layout';
import PageHeader from 'components/global/PageHeader/PageHeader';
import Table from 'components/global/Table/Table';
import { useGetRolesList } from 'hooks/useGetRolesList';
import { useGetUserTypes } from 'hooks/useGetUserTypes';
import { rolesData, sortingData } from 'store/selectors/selectors';
import TableHeader from "components/global/Table/TableHeader";
import { SearchInput } from "components/global/SearchInput/SearchInput";
import SearchRolesFilter from 'components/inputs/Roles/SearchRolesFilter';
import RolesTablePagination from 'components/global/Table/Pagination/RolesTablePagination';
import {
    ADD_ROLE,
    FIND_ROLE,
    ROLES_MENU_EDIT,
    ROLES_MENU_DELETE,
    DELETE_ROLE_MODAL_HEADER,
    DELETE_ROLE_MODAL_BODY,
    DELETE_ROLE_MODAL_APPLY,
    DELETE_ROLE_MODAL_CANCEL
} from 'utils/messages';
import { AddButton } from 'components/buttons/AddButton/AddButton';
import {navigate} from "gatsby";
import {Icons, IconsNames} from "utils/icons";
import {DELETE_ROLE} from "common/endpoints";
import fetchApi from "common/fetchApi";
import {toast} from "react-toastify";

const ModalMessage = <div>{DELETE_ROLE_MODAL_BODY}</div>

const RolesPage = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const { isLoaded: isRolesListLoaded } = useGetRolesList();
    const { isLoaded: isUserTypesLoaded } = useGetUserTypes();
    const tableData = useSelector(rolesData);
    const headerNames = {
        id:'ID',
        name: 'Name',
        user_type: 'User type',
        user_quantity: '# of users'
    };
    const rows = ['id', 'name', 'user_type', 'quantity_users'];
    const storeLocation = {
        selector: sortingData,
        actionType: 'roles/setSortingData'
    };

    const headerContent = (<>
        <div className="flex font-base font-semibold decoration-ultra-dark-black text-medium leading-medium
             pl-8 py-4 pr-6 border-solid border-b border-t border-light-beige">
            <SearchInput
                actionType='roles/setSearchPage'
                id='search-role'
                placeholder={FIND_ROLE}
                minLength={3}
            />
            <SearchRolesFilter />
        </div>
        <TableHeader
            header={headerNames}
            isLoaded={isRolesListLoaded}
            storeLocation={storeLocation}
        />
    </>);

    const handleTableRowClick = (event, contextType, rowData) => {
        event.preventDefault()
        if (contextType) {
            dispatch({
                type: 'rightPanel/setRightPanel',
                payload: { contextType, rowData, id: rowData?.user_id },
            })
        }
    }

    const handleEditRole = (rowData) => {
        dispatch.editRole.setEditRoleData(rowData)
        navigate('/app/roles/editRole')
    }

    const handleDeleteRow = (rowData) => {
        const onApply = async () => {
            setLoading(true)
            try {
                dispatch.modal.hideModal()
                const response = await fetchApi(DELETE_ROLE(rowData?.id), { method: 'DELETE' });
                setLoading(false)
                dispatch.roles.setIsUpdatedData(true)
                toast.success(response.data.message);
            } catch (error) {
                setLoading(false)
                toast.error(error.response.data?.message);
            }
        }

        dispatch.modal.showModal({
            data: {
                title: DELETE_ROLE_MODAL_HEADER,
                body: ModalMessage,
                primaryButtonLabel: DELETE_ROLE_MODAL_APPLY,
                onPrimary: onApply,
                secondaryButtonLabel: DELETE_ROLE_MODAL_CANCEL
            }
        });
    }

    const menuItems = [
        {
            label: ROLES_MENU_EDIT,
            icon: Icons[IconsNames.Edit],
            onClick: (rowData) => handleEditRole(rowData)
        },
        {
            label: ROLES_MENU_DELETE,
            icon: Icons[IconsNames.Delete],
            color: 'red',
            onClick: (rowData) => handleDeleteRow(rowData)
        }
    ]
    const Roles = (
        <div className="h-full">
            <div className="flex justify-between items-center pt-11 pl-16 pb-6 pr-8">
                <PageHeader title="Roles" />
                <AddButton link='/app/roles/addRole' label={ADD_ROLE} />
            </div>
            <Table
                data={tableData}
                isLoaded={isRolesListLoaded && isUserTypesLoaded && !loading}
                rows={rows}
                headerComponent={headerContent}
                linkIndex={1}
                renderPaginationComponent={RolesTablePagination}
                contextType='roles'
                handleTableRowClick={handleTableRowClick}
                menuItems={menuItems}
                dataTestId='roles-list'
            />
        </div>
    );

    useEffect(() => {
        return () =>
            dispatch({
                type: 'roles/setClearStore'
            });
    }, []);

    return <Layout component={Roles} />;
}

export default RolesPage;