import React, {useEffect} from 'react'
import './benefitBuilder.css'
import PropTypes from 'prop-types'
import Layout from 'components/container/Layout'
import { BENEFIT_BUILDER_BACK } from 'utils/messages'
import { Page } from 'components/global/Page/Page'
import { BenefitBuilder } from 'components/global/BenefitBuilder/BenefitBuilder'
import { useDispatch, useSelector } from 'react-redux'
import { PageTitles } from 'utils/constants'
import { Paths } from 'utils/constants'
import { benefitBuilder } from "store/selectors/benefitBuilder";
import fetchApi from "common/fetchApi";
import { getPreparedAdditionalTitle, getPreparedBadges } from "utils/helpers/benefitBuilder.helper";
import { GET_BENEFIT_BADGES_INFO } from "common/endpoints";

export const BenefitBuilderTemplate = ({ context }) => {
	const dispatch = useDispatch()

	const benefitBuilderRowData = useSelector(benefitBuilder.rowData)
	const benefitBuilderBadges = useSelector(benefitBuilder.badges)
	const pageTitle = benefitBuilderRowData?.id ? PageTitles.EditBenefit : PageTitles.BuildBenefit;

	const onBackLinkClick = () => {
		dispatch.benefitBuilder.setClearStore()
	}

	const preparedTitleAdditional = getPreparedAdditionalTitle(benefitBuilderRowData);
	const preparedBadges = getPreparedBadges(benefitBuilderBadges);

	useEffect(() => {
		const fetchData = async () => {
			const response = await fetchApi(GET_BENEFIT_BADGES_INFO(benefitBuilderRowData?.id))
			dispatch.benefitBuilder.setBadges({ data: response.data.badges })
		}

		if (!benefitBuilderRowData.internal_name || !benefitBuilderRowData.company_name || !benefitBuilderRowData.status) {
			benefitBuilderRowData.id && fetchData()
		}
	}, [benefitBuilderRowData])

	const benefitBuilderPage = (
		<Page
			title={pageTitle}
			titleAdditional={preparedTitleAdditional || preparedBadges}
			toBackLink={Paths.Benefits}
			toBackLinkTitle={BENEFIT_BUILDER_BACK}
			onBackLinkClick={onBackLinkClick}
			dataTestId={pageTitle}
		>
			<BenefitBuilder context={context} />
		</Page>
	)

	return <Layout component={benefitBuilderPage} />
}

BenefitBuilderTemplate.propTypes = {
	context: PropTypes.string,
}
