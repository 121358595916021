import React, { useEffect, useState, useRef } from 'react';
import Layout from 'components/container/Layout';
import fetchApi from 'common/fetchApi';
import { GET_USER_PASSWORD } from 'common/endpoints';
import Loader from "components/global/Loader/Loader";
import { useSelector } from "react-redux";
import { auth } from "store/selectors/selectors";

const cacheAuthState = authState => window.sessionStorage.setItem('cachedAuthState', JSON.stringify(authState))

const Frame = () => {
	const [password, setPassword] = useState();
	const [loaded, setLoaded] = useState(false);
	const frame = useRef(null);

	const authState = useSelector(auth);
	const { email } = authState

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetchApi(GET_USER_PASSWORD)
				setPassword(response.data.password)
			} catch (error) {
				console.log(error)
			}
		}

		if (!password) {
			fetchData()
		}
	}, [])

	useEffect(() => {
		cacheAuthState(authState)

		if (loaded && frame.current) {
			const iframe = document.getElementById('legacy-admin-frame').contentWindow;
			iframe.postMessage(JSON.stringify({ user: email, password }), process.env.GATSBY_LEGACY_ADMIN_URL);
		}
	}, [loaded, frame])

	if (!password) {
		return (
			<div className='bg-light-purple h-full'>
				<Loader />
			</div>
		)
	}

	return (
		<iframe
			src={process.env.GATSBY_LEGACY_ADMIN_URL}
			onLoad={() => setLoaded(true)}
			className='w-full h-full'
			ref={frame}
			id='legacy-admin-frame'
			sandbox='allow-forms allow-scripts allow-same-origin allow-top-navigation'
		/>
	)
}

export const LegacyAdminPage = () => {
	return <Layout component={Frame()} />
}
