import React, { useEffect, useLayoutEffect } from 'react'
import './Stepper2.css'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { stepper } from 'store/selectors/stepper'
import { StepperItem } from 'components/global/Stepper2/StepperItem/StepperItem'
import {isObjectEmpty} from "utils/helpers/common.helper";
import { BenefitBuilderKeys } from 'utils/helpers/benefitBuilder.helper'

const Test = () => {
	return (
		<div>Test component</div>
	)
}

const StepWrapper = ({ step, data, onChange }) => {
	if (!step.component) {
		return <Test />
	}

	return React.cloneElement(step.component, {
		data: data,
		onChange,
	})
}

const Stepper2 = ({
	steps,
	activeStep,
	dispatch,
	handleOnStepRemove,
	handleOnNext,
	errors,
	dataTestId,
}) => {
	const getPreviousStep = (currentIndex) => {
		if (!currentIndex) {
			return
		}

		return steps[currentIndex - 1]
	}

	const getNextStep = (currentIndex) => {
		if (currentIndex === steps.length - 1) {
			return
		}

		return steps[currentIndex + 1]
	}

	return (
		<>
			{steps?.map((step, index) => {
				const isErrors = errors[BenefitBuilderKeys[step.label]] && !isObjectEmpty(errors[BenefitBuilderKeys[step.label]])

				return (
					<StepperItem
						key={index}
						step={step}
						previousStep={getPreviousStep(index)}
						nextStep={getNextStep(index)}
						activeStep={activeStep}
						dispatch={dispatch}
						handleOnStepRemove={handleOnStepRemove}
						handleOnNext={handleOnNext}
						isErrors={isErrors}
						dataTestId={`${dataTestId}-item`}
					/>
				)
			})}
		</>
	)
}

export const Stepper2Container = ({
	steps,
	handleOnStepRemove,
	handleOnNext,
	errors,
	dataTestId,
}) => {
	const dispatch = useDispatch()

	const stepperSteps = useSelector(stepper.steps)
	const stepperActiveStep = useSelector(stepper.activeStep)

	const isOpen = (index) => {
		if (!stepperActiveStep && index === 1) {
			return true
		}

		if (steps.length > stepperSteps.length && stepperSteps.length !== 0) {
			return steps.length === index
		}

		if (stepperSteps.length > steps.length && stepperSteps.length !== 0) {
			return steps.length === index
		}

		return Boolean(stepperActiveStep) && stepperActiveStep.index + 1 === index
	}

	const handleOnComponentChange = (stepLabel, data) => {
		dispatch.stepper.setData({
			data: [stepLabel, data],
		})
	}

	const onStepRemoved = (step) => {
		handleOnStepRemove(step)
	}

	useLayoutEffect(() => {
		const stepperItems = steps.map((step, index) => {
			return {
				label: step.label,
				component: step?.component?.type ? (
					<StepWrapper
						key={step.label}
						step={step}
						onChange={handleOnComponentChange}
					/>
				) : null,
				index: index + 1,
				isDefault: step?.isDefault,
				isDisabled: step?.isDisabled,
				isValidated: step?.isValidated,
				isOpen: isOpen(index + 1),
				isCompleted: false,
				isFirst: index === 0,
				isLast: index === steps.length - 1,
			}
		})

		dispatch.stepper.setSteps({ steps: stepperItems })
	}, [steps])

	useEffect(() => {
		return () => dispatch.stepper.setClearStore()
	}, [])

	return (
		<div className="stepper-container">
			<Stepper2
				steps={stepperSteps}
				dispatch={dispatch}
				activeStep={stepperActiveStep}
				handleOnStepRemove={onStepRemoved}
				handleOnNext={handleOnNext}
				errors={errors}
				dataTestId={`${dataTestId}-stepper`}
			/>
		</div>
	)
}

Stepper2.propTypes = {
	steps: PropTypes.array.isRequired,
	activeStep: PropTypes.object,
	dispatch: PropTypes.func.isRequired,
	handleOnStepRemove: PropTypes.func.isRequired,
	errors: PropTypes.object
}

Stepper2Container.propTypes = {
	steps: PropTypes.array.isRequired,
	handleOnStepRemove: PropTypes.func.isRequired,
	errors: PropTypes.object
}

StepWrapper.propTypes = {
	step: PropTypes.object.isRequired,
	data: PropTypes.object,
	onChange: PropTypes.func,
}
