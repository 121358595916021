import React from 'react';
import PropTypes from 'prop-types';
import './PageHeader.css';

const PageHeader = ({ title, titleAdditional, dataTestId }) => (
    <div className='page-header-container'>
        <div className='page-header-title' data-testid={`page-header-${title}`}>
            {title}
        </div>
        {
            titleAdditional && titleAdditional?.length ?
                <div className='page-header-additional-container'>
                    {titleAdditional.map(item => {
                        return (
                            <div
                                key={item}
                                className='page-header-additional-item'
                                data-testid={`page-header-${title}-${item}`}
                            >
                                {item}
                            </div>
                        )
                    })}
                </div>
                : null
        }
    </div>
);

export default PageHeader;

PageHeader.propTypes = {
    title: PropTypes.string,
    titleAdditional: PropTypes.arrayOf(PropTypes.object),
    dataTestId: PropTypes.string
}