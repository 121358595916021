import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'components/buttons/Button/Button'
import { modal } from 'store/selectors/modal'
import { Icons, IconsNames } from 'utils/icons'
import './Modal.css'
import { ButtonTypes } from 'utils/constants'

export const Modal = () => {
	const dispatch = useDispatch()
	const modalData = useSelector(modal.data)
	const ref = useRef()
	const modalFooterBorderClass = modalData?.body ? 'modal-footer-border' : ''

	useEffect(() => {
		return () => dispatch.modal.reset()
	}, [])

	if (!modalData || !modalData?.title) {
		return null
	}

	const onSecondaryClick = () => {
		modalData.onSecondary()

		if(!modalData.disableDefaultBehavior) {
			dispatch.modal.hideModal()
		}
	}

	const onPrimaryClick = () => {
		modalData.onPrimary()
	}

	const onOptionalClick = () => {
		modalData.onOptional()
	}

	const onCloseClick = () => {
		modalData.onClose()

		if(!modalData.disableDefaultBehavior) {
			dispatch.modal.hideModal()
		}
	}

	return (
		<div className="modal-window-overlay modal-window-overlay-color">
			<div className={`${modalData?.width} modal-window`} ref={ref}>
				{modalData?.title && (
					<div className="modal-header">
						<div className="modal-header-container">
							<div className="modal-header-label">{modalData.title}</div>
							<div className="modal-header-small-cross-container">
								<div
									className="modal-header-small-cross"
									onClick={onCloseClick}
								>
									{Icons[IconsNames.Close]()}
								</div>
							</div>
						</div>
					</div>
				)}
				{modalData?.body && (
					<div className="modal-body">
						{modalData.body}
					</div>
				)}
				{
					modalData?.withFooterButtons && (
						<div className={`${modalFooterBorderClass} modal-footer`}>
							<div className="modal-footer-buttons-container">
								<div className="modal-footer-buttons-left-container">
									{modalData?.optionalButtonLabel && (
										<Button
											label={modalData.optionalButtonLabel}
											type={ButtonTypes.Edit}
											handleOnClick={onOptionalClick}
										/>
									)}
								</div>
								<div className="modal-footer-buttons-right-container">
									{modalData?.secondaryButtonLabel && (
										<Button
											label={modalData.secondaryButtonLabel}
											type={ButtonTypes.Secondary}
											handleOnClick={onSecondaryClick}
										/>
									)}
									{modalData?.primaryButtonLabel && (
										<Button
											label={modalData.primaryButtonLabel}
											type={ButtonTypes.Primary}
											handleOnClick={onPrimaryClick}
										/>
									)}
								</div>
							</div>
						</div>
					)
				}
			</div>
		</div>
	)
}
