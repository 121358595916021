import React from 'react'
import { useSelector } from 'react-redux'
import { FeaturesDataAccess } from '../FeaturesDataAccess/FeaturesDataAccess'
import { rightPanel } from 'store/selectors/selectors'

export const FeaturesDataAccessUser = () => {
	const { data, isLoading } = useSelector(rightPanel)

	return (
		<FeaturesDataAccess
			data={data}
			isLoading={isLoading}
		/>
	)
}
