import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import editIcon from 'icons/edit_white.svg'
import deleteIcon from 'icons/delete.svg'
import { CoupleButtons } from 'components/buttons/CoupleButtons/CoupleButtons'
import { ButtonLabels } from 'utils/constants'
import { rightPanel } from 'store/selectors/selectors'
import { navigate } from 'gatsby'
import fetchApi from "common/fetchApi";
import {DELETE_ROLE, DELETE_USER} from "common/endpoints";
import {toast} from "react-toastify";
import {
	DELETE_ROLE_MODAL_APPLY,
	DELETE_ROLE_MODAL_BODY,
	DELETE_ROLE_MODAL_CANCEL,
	DELETE_ROLE_MODAL_HEADER,
	DELETE_USER_MODAL_HEADER,
	DELETE_USER_MODAL_BODY,
	DELETE_USER_MODAL_APPLY,
	DELETE_USER_MODAL_CANCEL
} from "utils/messages";

const dispatchTypes = new Map([
	['roles', {
		edit: 'editRole/setEditRoleData',
		delete: 'roles/setIsUpdatedData'
	}],
	['users', {
		edit: 'editUser/setEditPage',
		delete: 'users/setIsUpdatedData'
	}],
])

const urls = new Map([
	['roles', {
		edit: '/app/roles/editRole',
		delete: (id) => DELETE_ROLE(id)
	}],
	['users', {
		edit: '/app/users/editUser',
		delete: (id) => DELETE_USER(id)
	}],
])

const messages = new Map([
	['roles', {
		body: DELETE_ROLE_MODAL_BODY,
		header: DELETE_ROLE_MODAL_HEADER,
		apply: DELETE_ROLE_MODAL_APPLY,
		cancel: DELETE_ROLE_MODAL_CANCEL
	}],
	['users', {
		body: DELETE_USER_MODAL_BODY,
		header: DELETE_USER_MODAL_HEADER,
		apply: DELETE_USER_MODAL_APPLY,
		cancel: DELETE_USER_MODAL_CANCEL
	}],
])

const ids = new Map([
	['roles', 'id'],
	['users', 'user_id'],
])

export const RightPanelFooter = () => {
	const dispatch = useDispatch()
	const { contextType, rowData } = useSelector(rightPanel)

	const ModalMessage = <div>{messages.get(contextType)?.body}</div>

	const handlePrimaryButtonClick = async () => {
		dispatch({
			type: dispatchTypes.get(contextType)?.edit,
			payload: rowData,
		})
		await navigate(urls.get(contextType)?.edit)
	}

	const handleSecondButtonClick = () => {
		const onApply = async () => {
			try {
				dispatch.rightPanel.setIsLoading({ isLoading: true })
				dispatch.modal.hideModal()
				const response = await fetchApi(
					urls.get(contextType)?.delete(rowData?.[ids.get(contextType)]),
					{ method: 'DELETE' }
					);
				dispatch.rightPanel.setCloseRightPanel()
				dispatch({
					type: dispatchTypes.get(contextType)?.delete,
					payload: true,
				})
				toast.success(response.data.message);
			} catch (error) {
				toast.error(error.response.data?.message);
			}
			finally {
				dispatch.rightPanel.setIsLoading({ isLoading: false })
			}
		}

		dispatch.modal.showModal({
			data: {
				title: messages.get(contextType)?.header,
				body: ModalMessage,
				primaryButtonLabel: messages.get(contextType)?.apply,
				onPrimary: onApply,
				secondaryButtonLabel: messages.get(contextType)?.cancel,
			}
		});
	}

	return (
		<CoupleButtons
			primaryButtonLabel={ButtonLabels.Edit}
			secondButtonLabel={ButtonLabels.Delete}
			primaryButtonIcon={editIcon}
			secondButtonIcon={deleteIcon}
			handlePrimaryButtonClick={handlePrimaryButtonClick}
			handleSecondButtonClick={handleSecondButtonClick}
			dataTestId={'right-panel'}
		/>
	)
}
