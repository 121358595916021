import React, {useEffect, useState} from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import FormInput from 'components/formFields/FormInput';
import { FormDatePicker } from 'components/formFields/FormDatePicker';
import FormDropdown from 'components/formFields/FormDropdown';
import {ButtonLabels, ButtonSizes, ButtonTypes} from 'utils/constants';
import {
    NEW_USER_FNAME,
    NEW_USER_SNAME,
    NEW_USER_BIRTHDAY,
    NEW_USER_EMAIL,
    NEW_USER_USER_TYPE,
    NEW_USER_USER_ROLE,
    NEW_USER_USER_TYPE_PLACEHOLDER,
    NEW_USER_USER_ROLE_PLACEHOLDER
} from 'utils/messages';
import { commonUserTypes , newUserErrors } from 'store/selectors/selectors';
import {Button} from "components/buttons/Button/Button";
import {loadCallback} from "utils/helpers/rolesList.helper";
import {FormDropdown2} from 'components/formFields/FormDropdown2';
import tw from 'twin.macro'
import styled from 'styled-components/macro'
import { prepareDropdownList } from '../common/PrepareDataHelpers';

const SecondColumnContainer = styled.div`
	${tw`ml-10 h-full mt-6 w-360`}
`

export const AddUserInfo = () => {
    const userTypes = useSelector(commonUserTypes);
    const [defaultUserType, setDefaultUserType] = useState({});
    const dispatch = useDispatch();
    const { handleSubmit, register, control } = useForm();
    const errors = useSelector(newUserErrors);

    useEffect(() => {
        // todo temporary
        const userType = userTypes?.find(item => !!item.isDefault);
        setDefaultUserType({
            label: userType?.name,
            value: userType?.id
        });
    }, [userTypes])

    useEffect(() => {
        dispatch({
            type: 'newUser/setNewUserType',
            payload: defaultUserType?.value
        });
    }, [defaultUserType])

    const onSubmit = (data) => {
        dispatch({
            type: 'newUser/setData',
            payload: { data }
        });
        dispatch({
            type: 'newUser/closeStep',
            payload: { step: 1 }
        });
        dispatch({
            type: 'newUser/openStep',
            payload: { step: 2 }
        });
    }

    const handleRoleChange = value => {
        dispatch({
            type: 'newUser/setNewUserRole',
            payload: [value?.label]
        });
        dispatch({
            type: 'newUser/setRoleData',
            payload: { role: value?.label, userTypeId: defaultUserType?.value }
        })
    }

    return (
        <form>
            <div className='flex'>
                <div>
                    <FormInput
                        label={NEW_USER_FNAME}
                        isRequired
                        register={register}
                        fieldName='first_name'
                        errorsSelector={newUserErrors}
                    />
                    <FormInput
                        label={NEW_USER_SNAME}
                        isRequired
                        register={register}
                        fieldName='surname'
                        errorsSelector={newUserErrors}
                    />
                    <FormDatePicker
                        label={NEW_USER_BIRTHDAY}
                        isRequired
                        control={control}
                        fieldName='date_of_birth'
                        errors={errors['date_of_birth']}
                    />
                    <FormInput
                        label={NEW_USER_EMAIL}
                        isRequired
                        register={register}
                        fieldName='email'
                        errorsSelector={newUserErrors}
                    />
                </div>
                <SecondColumnContainer>
                    <FormDropdown2
                        loadCallback={() => {}}
                        onChange={(value) => dispatch.benefits.setCompany({ company: value })}
                        label={NEW_USER_USER_TYPE}
                        placeholder={NEW_USER_USER_TYPE_PLACEHOLDER}
                        fieldName='user_type_id'
                        isRequired
                        fieldErrors={newUserErrors['user_type_id']}
                        hideFieldErrors
                        defaultOptions={prepareDropdownList({ array: userTypes })}
                        defaultValue={defaultUserType}
                        isReadOnly // todo temporary
                    />
                    <FormDropdown
                        loadOptions={loadCallback(defaultUserType?.value)}
                        placeholder={NEW_USER_USER_ROLE_PLACEHOLDER}
                        isSearchable
                        label={NEW_USER_USER_ROLE}
                        handleDropdownChange={handleRoleChange}
                        infiniteScroll
                        errorsSelector={newUserErrors}
                        fieldName='role_name'
                        isRequired
                    />
                </SecondColumnContainer>
            </div>
            <div className='mb-14 mt-11'>
                <Button
                    handleOnClick={handleSubmit(onSubmit)}
                    label={ButtonLabels.Next}
                    type={ButtonTypes.Primary}
                    size={ButtonSizes.Medium}
                    dataTestId='button-step-next'
                />
            </div>
        </form>
    );
};