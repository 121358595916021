import React from 'react';
import {
    NAVIGATION_TO_ROLES,
    NAVIGATION_TO_USERS,
	NAVIGATION_TO_BENEFITS,
    NAVIGATION_USER_MANAGEMENT,
	NAVIGATION_TO_DASHBOARD
} from 'utils/messages';
import peopleIcon from '../../../icons/people.svg';
import { Icons, IconsNames } from 'utils/icons'
import { Paths } from 'utils/constants'
import './Navigation.css'
import { NavigationItem } from './NavigationItem/NavigationItem';
import { ExpandableNavigationItem } from './ExpandableNavigationItem/ExpandableNavigationItem';
import {useSelector} from "react-redux";
import {mainNavigation} from "store/selectors/mainNavigation";

const Navigation = () => {
	const currentItem = useSelector(mainNavigation.currentItem);

    const userManagementContent = [
		{
			navigateLink: '/app/users',
			label: NAVIGATION_TO_USERS
		},
		{
			navigateLink: '/app/roles',
			label: NAVIGATION_TO_ROLES
		}
	];

    return (
			<div className='main-navigation-container'>
				<div data-testid='navigation-menu'>
					<NavigationItem
						icon={Icons[IconsNames.Dashboard]}
						navigateLink={Paths.Dashboard}
						label={NAVIGATION_TO_DASHBOARD}
						currentItem={currentItem}
					/>
					<ExpandableNavigationItem
						label={NAVIGATION_USER_MANAGEMENT}
						icon={peopleIcon}
						content={userManagementContent}
						currentItem={currentItem}
					/>
					<NavigationItem
						icon={Icons[IconsNames.Benefits]}
						navigateLink={Paths.Benefits}
						label={NAVIGATION_TO_BENEFITS}
						currentItem={currentItem}
					/>
				</div>
			</div>
		)
}

export default Navigation;