import React from 'react';
import { Link } from 'gatsby';

export const AddButton = ({ link, label }) => (
        <Link
            to={link}
            className='common-button-small pt-1.5'
            data-testid={`add-button-${label}`}
        >
            {label}
        </Link>
);