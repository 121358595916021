import React from 'react';
import { CheckboxGroup } from 'components/commonFields/CheckboxGroup/CheckboxGroup';
import { getLocatorId } from 'utils/locator';
import { ADD_CHECKBOX, DELETE_CHECKBOX, EDIT_CHECKBOX, READ_CHECKBOX } from 'utils/messages';

const checkboxes = [ADD_CHECKBOX, EDIT_CHECKBOX, READ_CHECKBOX, DELETE_CHECKBOX];

export const getSwitcherComponents = ({ items }) => {
    return (
        <>
            {
                items.map((item, index) => {
                    return (
                        <div
                            key={index}
                            className={index ? 'roles-group-container' : 'users-group-container'}
                        >
                            <CheckboxGroup
                                labels={checkboxes}
                                defaultChecked={item.checked}
                                checkboxGroupName={item.name}
                                onGroupChanged={item.onChange}
                                dataTestId={getLocatorId('checkbox-group', item.name)}
                            />
                        </div>
                    )
                })
            }
        </>
    )
}