import React, { useEffect } from 'react'
import './BenefitBuilder.css'
import PropTypes from 'prop-types'
import { Menu } from 'components/commonFields/Menu/Menu'
import { useDispatch, useSelector } from 'react-redux'
import { benefitBuilder } from 'store/selectors/benefitBuilder'
import { Stepper2Container } from 'components/global/Stepper2/Stepper2'
import { Button } from 'components/buttons/Button/Button'
import { toast } from 'react-toastify'
import { ButtonTypes, ButtonLabels } from 'utils/constants'
import { FaCheck } from 'react-icons/fa'
import Loader from 'components/global/Loader/Loader';
import {DropdownButton} from "components/buttons/DropdownButton/DropdownButton";
import {BenefitBuilderLabels, TableKeys} from "utils/helpers/benefitBuilder.helper";

const benefitBuilderDataTestId = 'benefit-bilder'

const BenefitLeftMenu = ({ benefitBuilderLeftMenuData, handleMenuItemClick }) => {
	return (
		<div className="benefit-builder-menu-part">
			<Menu
				data={benefitBuilderLeftMenuData}
				handleMenuItemClick={handleMenuItemClick}
				dataTestId={benefitBuilderDataTestId} 
			/>
		</div>
	)
}

export const BenefitBuilder = ({ context }) => {
	const dispatch = useDispatch()

	const benefitBuilderLeftMenuData = useSelector(benefitBuilder.leftMenuData)
	const benefitBuilderSteps = useSelector(benefitBuilder.steps)
	const events = useSelector(benefitBuilder.events)
	const isLoading = useSelector(benefitBuilder.isLoading)
	const isSending = useSelector(benefitBuilder.isSending)
	const isError = useSelector(benefitBuilder.isError)
	const errors = useSelector(benefitBuilder.errors)
	const successMessage = useSelector(benefitBuilder.successMessage)
	const errorMessage = useSelector(benefitBuilder.errorMessage)
	const tableSuccessMessage = useSelector(state => state.tables.successMessage)
	const tableErrorMessage = useSelector(state => state.tables.errorMessage)
	const benefitBuilderBenefit = useSelector(benefitBuilder.benefit)
	const benefitBuilderActiveStep = useSelector(benefitBuilder.activeStep)
	const benefitBuilderId = useSelector(benefitBuilder.rowData)?.id

	useEffect(() => {
		const fetchData = async () => {
			dispatch.benefitBuilder.fetchData({ context })
		}

		fetchData()
	}, [])

	useEffect(() => {
		const fetchMenu = async () => {
			dispatch.benefitBuilder.fetchMenu({ context })
		}

		if (events?.find(item => item === 'refresh_menu')) {
			fetchMenu()
		}
	}, [events])

	useEffect(() => {
		if (Boolean(successMessage) && !isError) {
			toast.success(successMessage)
			dispatch.benefitBuilder.setClearMessages()
		}
		if (Boolean(tableSuccessMessage)) {
			toast.success(tableSuccessMessage)
			dispatch.tables.setClearMessages()
		}
	}, [successMessage, tableSuccessMessage, isError])

	useEffect(() => {
		if (Boolean(errorMessage) && isError) {
			toast.error(errorMessage)
			dispatch.benefitBuilder.setClearMessages()
		}
		if (Boolean(tableErrorMessage)) {
			toast.error(tableErrorMessage)
			dispatch.tables.setClearMessages()
		}
	}, [errorMessage, isError, tableErrorMessage])

	useEffect(() => {
		dispatch.stepper.setIsSending({ isSending })
	}, [isSending])

	useEffect(() => {
		if(benefitBuilderBenefit) {
			dispatch.stepper.setActiveStep({ activeStep: benefitBuilderActiveStep })
		}
	}, [benefitBuilderBenefit])

	useEffect(() => {
		return () => {
			dispatch.tables.setClearTableStore({ tableName: TableKeys[BenefitBuilderLabels.LookupTable] })
			dispatch.benefitBuilder.clearStore()
		}
	}, [])

	const handleOnNext = (step, activeStep) => {
		dispatch.benefitBuilder.setActiveStep({ activeStep })
		dispatch.benefitBuilder.validateStep({ step })
	}

	const handleStepRemove = async (step) => {
		await dispatch.benefitBuilder.removeStep({ item: step })

		if (step?.component?.props?.step?.id.includes('pricing_lookup')) {
			dispatch.tables.setClearTableStore({ tableName: TableKeys[BenefitBuilderLabels.LookupTable] })
		}
	}

	const handleMenuItemClick = (menuItem) => {
		dispatch.benefitBuilder.menuItemClick({ item: menuItem })
	}

	const handlePublishClick = () => {
		dispatch.benefitBuilder.publishBenefit({ context })
	}

	const handleSaveAsDraftClick = () => {}

	const menuItems = [
		{
			label: ButtonLabels.SaveAsDraft,
			onClick: handleSaveAsDraftClick
		}
	];

	if (isLoading) {
		return (
			<div className="benefit-builder-loader-container">
				<Loader />
			</div>
		)
	}

	return (
		<div className="benefit-builder-main-container">
			<div className="benefit-builder-center-container">
				<BenefitLeftMenu
					benefitBuilderLeftMenuData={benefitBuilderLeftMenuData}
					handleMenuItemClick={handleMenuItemClick}
				/>
				<div className="benefit-builder-center-part">
					<div className="benefit-builder-button-container disabled">
						{
							benefitBuilderId ?
								<DropdownButton
									buttonLabel={ButtonLabels.Publish}
									buttonType={ButtonTypes.Primary}
									onButtonClick={handlePublishClick}
									dropdownItems={menuItems}
									dataTestId={benefitBuilderDataTestId}
								/>
								: (
									<>
										<Button
											label={ButtonLabels.SaveAsDraft}
											type={ButtonTypes.Secondary}
											handleOnClick={handleSaveAsDraftClick}
											dataTestId={benefitBuilderDataTestId}
										/>
										<Button
											icon={FaCheck}
											label={ButtonLabels.Publish}
											type={ButtonTypes.Primary}
											handleOnClick={handlePublishClick}
											dataTestId={benefitBuilderDataTestId}
										/>
									</>
								)
						}
					</div>

					<Stepper2Container
						steps={benefitBuilderSteps}
						handleOnStepRemove={handleStepRemove}
						handleOnNext={handleOnNext}
						errors={errors}
						dataTestId={benefitBuilderDataTestId}
					/>
				</div>
			</div>
		</div>
	)
}

BenefitLeftMenu.propTypes = {
	benefitBuilderLeftMenuData: PropTypes.array.isRequired,
	handleMenuItemClick: PropTypes.func.isRequired
}
