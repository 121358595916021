import React, { useState } from 'react'
import { FiChevronRight, FiChevronDown } from 'react-icons/fi'
import './AccordionElement.css'
import PropTypes from 'prop-types'

const AccordionHeader = ({ label, handleOnClick, additional, isActive }) => {
	return (
		<div className="accordion-header" onClick={handleOnClick}>
			{isActive ? (
				<FiChevronDown className="accordion-header-icon" data-testid={'accordion-header-icon'}/>
			) : (
				<FiChevronRight className="accordion-header-icon" data-testid={'accordion-header-icon'}/>
			)}
			<span 
				className="accordion-header-label" 
				data-testid={`accordion-header-label-${label}`}>{label}
			</span>
			{additional}
		</div>
	)
}

const AccordionBody = ({ bodyContent }) => {
	return <div className="accordion-body">{bodyContent}</div>
}

export const AccordionElement = ({ label, bodyContent, additional, dataTestId }) => {
	const [isActive, setIsActive] = useState(false)

	const handleOnClick = () => {
		setIsActive(!isActive)
	}

	return (
		<div
			className={`accordion-element ${isActive ? 'open' : ''}`}
			data-testid={`${dataTestId}-${label}`}
		>
			<AccordionHeader
				label={label}
				additional={additional}
				isActive={isActive}
				handleOnClick={handleOnClick}
			/>
			<AccordionBody bodyContent={bodyContent} />
		</div>
	)
}

AccordionHeader.propTypes = {
	label: PropTypes.string.isRequired,
	additional: PropTypes.node,
	handleOnClick: PropTypes.func.isRequired,
}

AccordionBody.propTypes = {
	bodyContent: PropTypes.element.isRequired,
}

AccordionElement.propTypes = {
	label: PropTypes.string.isRequired,
	additional: PropTypes.node,
	bodyContent: PropTypes.element.isRequired,
}
