import {useDispatch, useSelector} from 'react-redux';
import React, {useEffect, useState} from 'react';
import tw from 'twin.macro'
import styled from 'styled-components/macro'
import {benefits} from 'store/selectors/benefits';
import fetchApi from 'common/fetchApi';
import {COPY_BENEFIT as COPY_BENEFIT_API, GET_COMPANIES_LIST, GET_SCHEME_YEARS} from 'common/endpoints';
import {
    COPY_BENEFIT_MODAL_APPLY,
    COPY_BENEFIT_MODAL_BODY, COPY_BENEFIT_MODAL_CANCEL,
    COPY_BENEFIT_MODAL_COMPANY_FIELD,
    COPY_BENEFIT_MODAL_COMPANY_FIELD_PLACEHOLDER,
    COPY_BENEFIT_MODAL_SCHEME_FIELD,
    COPY_BENEFIT_MODAL_SCHEME_FIELD_PLACEHOLDER
} from 'utils/messages';
import Loader from 'components/global/Loader/Loader';
import {FormDropdown2} from 'components/formFields/FormDropdown2';
import {ButtonTypes, FetchMethods, FormInputFieldSizes, sortingDirections} from 'utils/constants';
import {prepareDropdownList} from 'components/forms/common/PrepareDataHelpers';
import {Button} from 'components/buttons/Button/Button';
import {toast} from 'react-toastify';

const LoaderContainer = styled.div`
	${tw`my-4`}
`

export const CopyBenefitModal = ({ id }) => {
    const dispatch = useDispatch();
    const { companiesList, schemesList, company, scheme } = useSelector(benefits.benefits);
    const { rowsPerPage, searchValue, searchCompany } = useSelector(benefits.benefits);
    const { direction, column } = useSelector(benefits.sortingData);
    const { current_page } = useSelector(benefits.data);
    const [isLoading, setIsLoading] = useState(false);
    const [ errors, setErrors ] = useState([])

    useEffect(() => {
        const fetchCompanies = async () => {
            try {
                setIsLoading(true);
                const response = await fetchApi(GET_COMPANIES_LIST);
                dispatch.benefits.setCompaniesList({
                    companiesList:  prepareDropdownList({ array: response.data })
                })
            } catch (error) {
                console.log(error)
            }
            finally {
                setIsLoading(false);
                setErrors([])
            }
        }

        if (!companiesList?.length) {
            fetchCompanies();
        }
    }, [companiesList])

    useEffect(() => {
        const fetchSchemes = async () => {
            try {
                setIsLoading(true);
                const response = await fetchApi(GET_SCHEME_YEARS, {
                    params: {
                        companyID: company?.value
                    }
                });
                dispatch.benefits.setSchemesList({
                    schemesList: prepareDropdownList({ array: response.data })
                })
            } catch (error) {
                console.log(error)
            }
            finally {
                setIsLoading(false);
                setErrors([])
            }
        }

        if (company) {
            fetchSchemes();
        }
    }, [company])

    const defaultCompanyValue = companiesList?.find(item => item.label === company?.label)

    const resetModalData = () => {
        dispatch.benefits.setScheme({ scheme: undefined })
        dispatch.benefits.setCompany({ company: undefined })
        dispatch.benefits.setCompaniesList({ companiesList: [] })
        dispatch.benefits.setSchemesList({ schemesList: [] })
        dispatch.modal.hideModal()
    }

    const onApply = async () => {
        setIsLoading(true);
        try {
            const response = await fetchApi(COPY_BENEFIT_API(id), {
                method: FetchMethods.Post,
                data: {
                    company_id: company?.value,
                    scheme_id: scheme?.value
                }
            });
            dispatch.modal.hideModal()
            resetModalData()
            dispatch.benefits.setBenefitsData({
                    page: current_page,
                    per_page: rowsPerPage,
                    sort_by: column,
                    range: direction === sortingDirections.DOWN ? 'asc' : 'desc',
                    search: searchValue,
                    company_id: searchCompany
                })
            toast.success(response.data.message);
        } catch (error) {
            toast.error(error.response.data?.message);
            setErrors(error.response?.data?.errors[0]);
        }
        finally {
            setIsLoading(false);
        }
    }

    const ModalBody = (
        <div className='copy-benefit-modal-fields'>
            <FormDropdown2
                loadCallback={() => {}}
                onChange={(value) => dispatch.benefits.setCompany({ company: value })}
                label={COPY_BENEFIT_MODAL_COMPANY_FIELD}
                placeholder={COPY_BENEFIT_MODAL_COMPANY_FIELD_PLACEHOLDER}
                fieldName='company'
                isRequired
                fieldSize={FormInputFieldSizes.Middle}
                defaultOptions={companiesList}
                defaultValue={defaultCompanyValue}
                fieldErrors={errors?.company_id}
            />
            <FormDropdown2
                loadCallback={() => {}}
                onChange={(value) => dispatch.benefits.setScheme({ scheme: value })}
                label={COPY_BENEFIT_MODAL_SCHEME_FIELD}
                placeholder={COPY_BENEFIT_MODAL_SCHEME_FIELD_PLACEHOLDER}
                fieldName='scheme'
                isRequired
                fieldSize={FormInputFieldSizes.Middle}
                defaultOptions={schemesList}
                fieldErrors={errors?.scheme_id}
            />
        </div>
    )

    return (
        <div className='copy-benefit-modal-container'>
            {COPY_BENEFIT_MODAL_BODY}
            {
                isLoading ?
                    <LoaderContainer>
                        <Loader />
                    </LoaderContainer>
                    : ModalBody
            }
            <div className={`modal-footer-border modal-footer`}>
                <div className="modal-footer-buttons-container">
                    <div className="modal-footer-buttons-right-container">
                        <Button
                            label={COPY_BENEFIT_MODAL_CANCEL}
                            type={ButtonTypes.Secondary}
                            handleOnClick={resetModalData}
                        />
                        <Button
                            label={COPY_BENEFIT_MODAL_APPLY}
                            type={ButtonTypes.Primary}
                            handleOnClick={onApply}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}