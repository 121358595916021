import React from 'react';
import { useDispatch } from 'react-redux';
import Layout from '../components/container/Layout';
import PageHeader from '../components/global/PageHeader/PageHeader';
import GoBackButton from '../components/buttons/GoBackButton/GoBackButton';
import StepperContainer from '../components/global/Stepper/StepperContainer';
import AddRoleInfo from '../components/forms/AddRoleInfo';
import { AddFeatures } from 'components/forms/AddFeatures/AddFeatures';
import {
    NEW_ROLE_STEP_1,
    NEW_ROLE_STEP_2,
    NEW_ROLE_STEP_3,
    NEW_ROLE_BACK,
    NEW_ROLE_HEADER
} from 'utils/messages';
import { AddUsers } from 'components/forms/AddUsers/AddUsers';

const stepperContent = [
    {
        step: 1,
        header: NEW_ROLE_STEP_1,
        content: AddRoleInfo
    },
    {
        step: 2,
        header: NEW_ROLE_STEP_2,
        content: AddFeatures
    },
    {
        step: 3,
        header: NEW_ROLE_STEP_3,
        content: AddUsers
    }
 ];

const AddRolePage = () => {
    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch({
            type: 'newRole/setClearStore'
        });
        dispatch({
            type: 'users/setClearStore'
        });
    };

    const AddRole = (
        <div className="h-full">
            <div className="flex flex-col pt-[32px] pl-[64px] pb-[40px] pr-[32px] h-full">
                <GoBackButton onClick={handleClick} title={NEW_ROLE_BACK} link="/app/roles" />
                <PageHeader title={NEW_ROLE_HEADER} />
                <StepperContainer steps={stepperContent} storeLocation='newRole' />
            </div>
        </div>
    );

    return <Layout component={AddRole} />;
}

export default AddRolePage;