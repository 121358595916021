import React from 'react';
import PropTypes from 'prop-types'
import './FormFields.css';
import { getLocatorId } from 'utils/locator';

const FormTextArea = ({ label, isRequired, content, register, fieldName, ...props }) => (
    <div className='form-field-container'>
        <div className='form-field-label'>
            <span>{label}</span>
            {isRequired && <sup>*</sup>}
        </div>
        <textarea
            data-testid={getLocatorId('textarea', label)}
            defaultValue={content || ''}
            className='form-field-textarea common-input'
            {...props}
            {...register(fieldName, { required: isRequired })}
        />
    </div>
);

export default FormTextArea;

FormTextArea.propTypes = {
    register: PropTypes.func.isRequired,
    fieldName: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    isRequired: PropTypes.bool,
    content: PropTypes.string
};