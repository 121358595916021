import React from "react";
import './DropdownButton.css';
import {Button} from "components/buttons/Button/Button";
import {ButtonTypes} from "utils/constants";
import {CollapsedMenu} from "components/global/CollapsedMenu/CollapsedMenu";
import {Icons, IconsNames} from "utils/icons";
import PropTypes from "prop-types";

export const DropdownButton = ({
    buttonLabel,
    buttonType,
    onButtonClick,
    dropdownItems,
    dataTestId
}) => {
    return (
        <div className={`dropdown-button-container ${buttonType}`}>
            <Button
                label={buttonLabel}
                type={buttonType}
                handleOnClick={onButtonClick}
                dataTestId={dataTestId}
            />
            <CollapsedMenu
                items={dropdownItems}
                type={ButtonTypes.Dropdown}
                buttonIcon={Icons[IconsNames.ArrowDown]}
                dataTestId={dataTestId}
            />
        </div>
    )
}

DropdownButton.propTypes = {
    buttonLabel: PropTypes.string.isRequired,
    buttonType: PropTypes.string.isRequired,
    onButtonClick: PropTypes.func,
    dropdownItems: PropTypes.array,
    dataTestId: PropTypes.string
}
