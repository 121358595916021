import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import { useDispatch, useSelector } from 'react-redux'
import { rightPanel } from 'store/selectors/selectors'
import { auth } from 'store/selectors/auth'
import { Paths } from 'utils/constants';

const PrivateRoute = ({ component: Component, location, ...rest }) => {
	const dispatch = useDispatch()
	const authData = useSelector(auth.authData)
	const isLoggedIn = useSelector(auth.isLoggedIn)
	const { isOpen } = useSelector(rightPanel)

	const cacheAuthState = authState => window.sessionStorage.setItem('cachedAuthState', JSON.stringify(authState))

	useEffect(() => {
		if (isOpen && location?.state?.prevPath !== Paths.AuditLog) {
			dispatch({ type: 'rightPanel/setCloseRightPanel' })
		}
	}, [location])

	useEffect(() => {
		if (isLoggedIn) {
			cacheAuthState({...authData, location: location?.pathname})
		}
	}, [isLoggedIn])

	if (!isLoggedIn && location.pathname !== `/app/login`) {
		navigate('/app/login')
		return null
	}

	return <Component {...rest} location={location} />
}

export default PrivateRoute
