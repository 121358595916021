import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './benefits.css';
import { navigate } from 'gatsby';
import Layout from 'components/container/Layout';
import PageHeader from 'components/global/PageHeader/PageHeader';
import Table from 'components/global/Table/Table';
import { benefits } from 'store/selectors/benefits';
import TableHeader from 'components/global/Table/TableHeader';
import {
    BENEFIT_ID,
    BENEFIT_INTERNAL_NAME,
    BENEFIT_COMPANY_NAME,
    BENEFIT_PROVIDER,
    BENEFIT_PRICING_MODEL,
    BENEFIT_STATUS,
    FIND_BENEFIT,
    ADD_BENEFIT,
    EDIT_BENEFIT,
    COPY_BENEFIT,
    COPY_BENEFIT_MODAL_HEADER
} from 'utils/messages'
import { BenefitsTablePagination } from 'components/global/Table/Pagination/BenefitsTablePagination';
import { SearchInput } from 'components/global/SearchInput/SearchInput';
import {
    sortingDirections,
    PageTitles,
    Sorting
} from 'utils/constants';
import { AddButton } from 'components/buttons/AddButton/AddButton';
import {Icons, IconsNames} from "utils/icons";
import { TableFilter } from 'components/global/Table2/TableFilter/TableFilter'
import {CopyBenefitModal} from 'components/benefit/CopyBenefitModal';

export const BenefitsPage = () => {
    const dispatch = useDispatch();
    const { data, current_page, filters } = useSelector(benefits.data);
    const { rowsPerPage, searchValue, searchCompany, isLoading } = useSelector(benefits.benefits);
    const { direction, column } = useSelector(benefits.sortingData);
    const companyFilterDefaultValue = {company_id: { label: 'All', value: ''}};

    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        if (data)
            setTableData(data)
    }, [data])

    useEffect(() => {
        dispatch({
            type: 'benefits/setBenefitsData',
            payload: {
                page: current_page,
                per_page: rowsPerPage,
                sort_by: column,
                range: direction === sortingDirections.DOWN ? Sorting.Asc : Sorting.Desc,
                search: searchValue,
                company_id: searchCompany
            }
        });
    }, [current_page, rowsPerPage, column, direction, searchValue, searchCompany])

    const headerNames = {
        id: BENEFIT_ID,
        internal_name: BENEFIT_INTERNAL_NAME,
        company_name: BENEFIT_COMPANY_NAME,
        provider: BENEFIT_PROVIDER,
        pricing_model: BENEFIT_PRICING_MODEL,
        status: BENEFIT_STATUS,
    };
    const rows = ['id', 'internal_name', 'company_name', 'provider', 'pricing_model', 'status'];

    const storeLocation = {
        selector: benefits.sortingData,
        actionType: 'benefits/setSortingData'
    };

    const setCompanyFilterValue = (e) => {
        dispatch.benefits.setSearchCompany(e?.value || '' );
    }

    const benefitFilterStyles = {width: '280px', paddingTop: '3px'};

    const headerContent = (
        <>
            <div className='benefits-search-container'>
                <SearchInput
                    actionType='benefits/setSearchPage'
                    id='search-benefits'
                    placeholder={FIND_BENEFIT}
                    minLength={1}
                />
                <TableFilter
                  filters={filters}
                  onFilter={(e) => setCompanyFilterValue(e)}
                  dataTestId='search-benefits-by-company'
                  params={companyFilterDefaultValue}
                  customFilterStyles={benefitFilterStyles}
                />
            </div>
            <TableHeader
                header={headerNames}
                isLoaded={!isLoading}
                storeLocation={storeLocation}
                isSortingDisabled
            />
        </>
    );

    const handleTableRowClick = async (event, contextType, rowData) => {
        event.preventDefault()

        await navigate('/app/benefits/viewBenefit')

        dispatch({
            type: 'benefits/setViewData',
            payload: {
                viewId: rowData.id,
                headerLabel: rowData.internal_name
            },
        })
    }

    const handleEditBenefit = async (rowData) => {
        dispatch.benefitBuilder.setRowData({ rowData })
        await navigate(`/app/benefits/edit/${rowData.id}`);
    };

    const handleCopyBenefit = async (rowData) => {
        const resetModalData = () => {
            dispatch.benefits.setScheme({ scheme: undefined })
            dispatch.benefits.setCompany({ company: undefined })
            dispatch.benefits.setCompaniesList({ companiesList: [] })
            dispatch.benefits.setSchemesList({ schemesList: [] })
        }

        dispatch.modal.showModal({
            data: {
                title: COPY_BENEFIT_MODAL_HEADER(rowData?.internal_name),
                body: <CopyBenefitModal id={rowData?.id } />,
                withFooterButtons: false,
                onClose: resetModalData
            }
        });
    };

    const menuItems = [
        {
            label: EDIT_BENEFIT,
            icon: Icons[IconsNames.Edit],
            onClick: (rowData) => handleEditBenefit(rowData)
        },
        {
            label: COPY_BENEFIT,
            icon: Icons[IconsNames.Copy],
            onClick: (rowData) => handleCopyBenefit(rowData)
        }
    ];

    const Benefits = (
        <div>
            <div className='benefits-header-container'>
                <PageHeader title={PageTitles.Benefits} />
                <AddButton link='/app/benefits/addBenefit' label={ADD_BENEFIT} />
            </div>
            <Table
                data={tableData}
                isLoaded={!isLoading}
                rows={rows}
                contextType='benefits'
                headerComponent={headerContent}
                renderPaginationComponent={BenefitsTablePagination}
                handleTableRowClick={handleTableRowClick}
                menuItems={menuItems}
                dataTestId='benefits-list'
            />
        </div>
    );

    useEffect(() => {
        return () => dispatch.benefits.setClearStore();
    }, [])

    return <Layout component={Benefits} />;
}