import React from 'react';
import Layout from '../components/container/Layout';
import notFoundPicture from '../../static/media/not-found.png';
import { NOT_FOUND_ALT } from '../utils/messages';

const NotFoundImage = (
    <div className="flex justify-center items-center h-full w-full">
        <img
            alt={NOT_FOUND_ALT}
            src={notFoundPicture}
            className="h-[410px] w-[403px]"
        />
    </div>
)

const NotFound = () => (
    <Layout component={NotFoundImage} />
);

export default NotFound;