import React from 'react'
import './Page.css'
import PropTypes from 'prop-types'
import GoBackButton from 'components/buttons/GoBackButton/GoBackButton'
import PageHeader from 'components/global/PageHeader/PageHeader'

export const Page = ({
	title,
	titleAdditional,
	toBackLink,
	toBackLinkTitle,
	children,
	onBackLinkClick,
	location,
	dataTestId,
}) => {
	return (
		<div className="page-container">
			<div className="page-header">
				{toBackLink && (
					<GoBackButton
						onClick={onBackLinkClick}
						title={toBackLinkTitle}
						location={location}
						link={toBackLink}
					/>
				)}
				<PageHeader
					title={title}
					titleAdditional={titleAdditional}
					dataTestId={dataTestId}
				/>
			</div>
			<div className="page-body">{children}</div>
		</div>
	)
}

Page.propTypes = {
	title: PropTypes.string.isRequired,
	toBackLink: PropTypes.string,
	toBackLinkTitle: PropTypes.string,
	children: PropTypes.object,
	dataTestId: PropTypes.string,
	location: PropTypes.object,
	titleAdditional: PropTypes.object
}
