import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import './addUser.css';
import Layout from 'components/container/Layout';
import PageHeader from 'components/global/PageHeader/PageHeader';
import GoBackButton from 'components/buttons/GoBackButton/GoBackButton';
import StepperContainer from 'components/global/Stepper/StepperContainer';
import { AddUserInfo } from 'components/forms/AddUserInfo/AddUserInfo';
import {
    NEW_USER_STEP_1,
    NEW_USER_STEP_2,
    NEW_USER_BACK,
    NEW_USER_HEADER
} from 'utils/messages';
import Loader from 'components/global/Loader/Loader';
import { useGetUserTypes } from 'hooks/useGetUserTypes';
import { FeatureDataAccess } from 'components/forms/addUser/FeatureDataAccess/FeatureDataAccess'

const stepperContent = [
    {
        step: 1,
        header: NEW_USER_STEP_1,
        content: AddUserInfo
    },
    {
        step: 2,
        header: NEW_USER_STEP_2,
        content: FeatureDataAccess
    }
 ];

export const AddUserPage = () => {
    const dispatch = useDispatch();
    const { isLoaded: isUserTypesListLoaded } = useGetUserTypes();

    const clearStores = () => {
        dispatch({
            type: 'newUser/setClearStore'
        });
        dispatch({
            type: 'users/setClearStore'
        });
        dispatch({
            type: 'roles/setClearStore'
        });
    };

    useEffect(() => {
        return () =>
            clearStores();
    }, []);

    const handleClick = () => {
        clearStores();
    };

    const AddUser = (
        <div className='add-user-main-container'>
            <GoBackButton onClick={handleClick} title={NEW_USER_BACK} link='/app/users' />
            <PageHeader title={NEW_USER_HEADER} />
            {
                isUserTypesListLoaded ?
                    <StepperContainer steps={stepperContent} storeLocation='newUser' />
                    : <Loader/>
            }
        </div>
    );

    return <Layout component={AddUser} />;
}