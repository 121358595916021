import React from 'react';
import PropTypes from 'prop-types';
import SideBar from './SideBar';
import Workplace from './Workplace';

const Layout = ({ component }) => {
    return (
        <div className="min-h-1060 flex w-full">
            <SideBar />
            <Workplace component={component}/>
        </div>
    );
}

export default Layout;

Layout.propTypes = {
    component: PropTypes.element,
}