import React from 'react'
import { useSelector } from 'react-redux'
import { FeaturesDataAccess } from '../FeaturesDataAccess/FeaturesDataAccess'
import { ROLE_USER_MANAGEMENT } from 'utils/messages'
import { rightPanel } from 'store/selectors/selectors'

export const FeaturesDataAccessRole = () => {
	const { data, isLoading } = useSelector(rightPanel)

	return (
		<FeaturesDataAccess
			data={data?.role}
			isLoading={isLoading}
			label={ROLE_USER_MANAGEMENT}
		/>
	)
}
