import React, { useEffect, useState } from 'react'
import { VscDebugStackframeDot } from 'react-icons/vsc'
import { reverseFirstLetterCapitalize } from 'utils/helpers/format.helper'
import PropTypes from 'prop-types'
import pluralize from 'pluralize'
import './FeaturesDataAccess.css'

const PermissionGroups = ({ permissionGroupNames, permissions }) => {
	return (
		<div className="features-data-access-management-groups-container">
			{permissionGroupNames?.map((groupName) => (
				<PermissionGroup
					key={groupName}
					groupName={groupName}
					permissions={permissions[groupName]}
				/>
			))}
		</div>
	)
}

const PermissionGroup = ({ groupName, permissions }) => {
	const { count, actions } = { ...permissions }

	const selectedPermissions = actions
		? Object.keys(actions).filter((action) => Boolean(actions[action]))
		: []

	return (
		<div
			className="features-data-access-permissions-group"
			data-testid={`features-data-access-${groupName}-permissions-group`}
		>
			<VscDebugStackframeDot />
			<ActionGroupLabel groupName={groupName} countLabel={count} />
			<div
				className="permissions-group"
				data-testid={`${groupName}-permissions-group`}
			>
				{selectedPermissions?.map((permissionLabel) => (
					<Action key={permissionLabel} label={permissionLabel} />
				))}
			</div>
		</div>
	)
}

const ActionGroupLabel = ({ groupName, countLabel }) => {
	const pluralizeGroupName =
		typeof countLabel === 'number'
			? pluralize(groupName, countLabel)
			: groupName

	const additional = `(${reverseFirstLetterCapitalize(
		countLabel.toString()
	)} ${pluralizeGroupName})`

	return (
		<div className="actions-group-label">
			<span
				className="actions-group-name"
				data-testid={`action-group-name-${groupName}`}
			>
				{reverseFirstLetterCapitalize(groupName)}
			</span>
			<span
				className="actions-group-count-label"
				data-testid={`action-group-count-label-${additional}`}
			>
				{additional}
			</span>
		</div>
	)
}

const Action = ({ label }) => {
	return (
		<div className="action-label-wrapper">
			<span className="action-label" data-testid={`action-label-${label}`}>
				{label}
			</span>
		</div>
	)
}

export const FeaturesDataAccess = ({ data, isLoading }) => {
	const [permissions, setPermissions] = useState()
	const [isEmpty, setIsEmpty] = useState(true)

	useEffect(() => {
		if (!isLoading) {
			prepareData()
		}
	}, [isLoading])

	const prepareData = () => {
		const { permissions } = data
		const groupNames = Object.keys(permissions)
		const isEmpty = !groupNames?.length

		setIsEmpty(isEmpty)
		setPermissions(permissions)
	}

	return (
		!isLoading &&
		!isEmpty && (
			<>
				{permissions?.map((permission) => {
					const permissionData = {}
					const permissionGroupNames = []

					permission?.features?.forEach((permissionType) => {
						if (permissionType?.content) {
							permissionData[permissionType?.label] = permissionType?.content
							permissionGroupNames.push(permissionType?.label)
						}
					})

					return (
						<>
							{Object.keys(permissionData).length !== 0 && (
								<>
									<div
										className="features-data-acceess-management-label"
										data-testid={`${permission.label}-management-label`}
									>
										{permission.label}
									</div>
									<PermissionGroups
										permissions={permissionData}
										permissionGroupNames={permissionGroupNames}
									/>
								</>
							)}
						</>
					)
				})}
			</>
		)
	)
}

FeaturesDataAccess.propTypes = {
	data: PropTypes.object.isRequired,
	isLoading: PropTypes.bool,
}

Action.propTypes = {
	label: PropTypes.string.isRequired,
}

ActionGroupLabel.propTypes = {
	groupName: PropTypes.string.isRequired,
	countLabel: PropTypes.any,
}

PermissionGroup.propTypes = {
	groupName: PropTypes.string.isRequired,
	permissions: PropTypes.object.isRequired,
}

PermissionGroups.propTypes = {
	permissionGroupNames: PropTypes.array.isRequired,
	permissions: PropTypes.object.isRequired,
}
