import React from 'react'
import tw from 'twin.macro'
import styled from 'styled-components/macro'
import { useDispatch, useSelector } from 'react-redux'
import { rightPanel } from 'store/selectors/selectors'
import { VscChromeClose } from 'react-icons/vsc'

const Container = styled.div`
	${tw`bg-gradient-to-r from-bright-purple to-dark-blue items-center w-full flex h-16 py-4 px-5`}
`

const Label = styled.span`
	${tw`text-white font-semibold leading-8 font-base text-lg w-full pl-7`}
`

const Icon = styled(VscChromeClose)`
	${tw`hover:cursor-pointer text-white`}
`

const Header = ({ label, handleOnClose, dataTestId }) => {
	return (
		<Container data-testid={`${dataTestId}-header-${label}`}>
			<Label data-testid={`${dataTestId}-label-${label}`}>{label}</Label>
			<Icon
				onClick={handleOnClose}
				data-testid={`${dataTestId}-${label}-close-icon`}
			/>
		</Container>
	)
}

const getHeaderLabelByRow = (type, rowData) => {
	const headers = new Map([
		['roles', `${rowData?.name}`],
		['users', `${rowData?.user_first_name} ${rowData?.user_sur_name}`],
	])

	return headers.get(type)
}

export const RightPanelHeader = () => {
	const dispatch = useDispatch()
	const { contextType, rowData } = useSelector(rightPanel)

	const handleOnClose = () => {
		dispatch({
			type: 'rightPanel/setCloseRightPanel',
		})
	}

	return (
		<Header
			label={getHeaderLabelByRow(contextType, rowData)}
			handleOnClose={handleOnClose}
			dataTestId='right-panel'
		/>
	)
}
