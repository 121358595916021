import React from 'react'
import './StepperCircle.css'
import PropTypes from 'prop-types'
import {Icons, IconsNames} from "utils/icons";

export const StepperCircle = ({ step, isErrors, dataTestId }) => {
	return (
		<div className="stepper-navigation-container">
			<div className={`stepper-circle-container ${step?.isDisabled ? 'disabled' : ''}`}>
				{
					isErrors ?
						<div
							className='stepper-circle stepper-circle-error'
							data-testid={`${dataTestId}-${step.index}`}
						>
							{Icons[IconsNames.Exclamation]()}
						</div>
						: (
							<div
								className={`stepper-circle ${
									(!step.isCompleted && step.isOpen) || step.isValidated
										? 'stepper-circle-selected'
										: ''
								}`}
								data-testid={`${dataTestId}-${step.index}`}
							>
								{step.isValidated && !step.isOpen ? Icons[IconsNames.Check]() : step.index}
							</div>
						)
				}
			</div>
			{!step.isLast && step.isOpen && (
				<div
					className="stepper-line"
					data-testid={`${dataTestId}-circle-line`}
				/>
			)}
		</div>
	)
}

StepperCircle.propTypes = {
	step: PropTypes.object.isRequired,
	isErrors: PropTypes.bool,
	dataTestId: PropTypes.string,
}
