import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    currentPageUsers,
    searchPageUsers,
    sortingUsersData,
    updatedUsersData,
    userPerPageUsers
} from 'store/selectors/selectors';
import {GET_USERS} from 'common/endpoints';
import {FetchMethods, Sorting, sortingDirections} from 'utils/constants';
import fetchApi from 'common/fetchApi';

export const useGetUsersList = ({ excludeCurrentUser }) => {
    const dispatch = useDispatch();
    const current = useSelector(currentPageUsers);
    const entriesPerPage = useSelector(userPerPageUsers);
    const search = useSelector(searchPageUsers);
    const isUpdated = useSelector(updatedUsersData);
    const { direction, column } = useSelector(sortingUsersData);

    const [isLoaded, setIsLoaded] = useState();

    const params = {
        page: current,
        per_page: entriesPerPage,
        sort_by: column,
        range: direction === sortingDirections.DOWN ? Sorting.Asc : Sorting.Desc,
        search: search ? search : '',
        exclude_current_user: excludeCurrentUser && '1'
    };

    useEffect(() => {
        setIsLoaded(false);

        fetchApi(GET_USERS, {
            method: FetchMethods.Get,
            params
        })
            .then((response) => {
                dispatch.users.setTableData(response.data);
                setIsLoaded(true);
            })
            .catch((error) => {
                setIsLoaded(true);
            })
            .finally(() => dispatch.users.setIsUpdatedData(false))
    }, [current, dispatch, entriesPerPage, direction, column, search, isUpdated]);

    return { isLoaded };
};