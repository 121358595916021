import React from 'react'
import { useSelector } from 'react-redux'
import './RightPanel.css'
import { rightPanel } from 'store/selectors/selectors'
import { RightPanelBody } from 'components/rightPanel/RightPanelBody'
import { RightPanelFooter } from 'components/rightPanel/RightPanelFooter'
import { RightPanelHeader } from 'components/rightPanel/RightPanelHeader'

export const RightPanel = () => {
	const { isOpen } = useSelector(rightPanel)

	return (
		isOpen && (
			<section className="right-panel" data-testid='right-panel'>
                <div className='right-panel-container'> 
					<RightPanelHeader />
                    <RightPanelBody />
                </div>
				<RightPanelFooter />
			</section>
		)
	)
}