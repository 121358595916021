import React, { useEffect, useState } from 'react'
import 'components/forms/AuthenticationSectionForm/AuthenticationSectionForm.css'
import PropTypes from 'prop-types'
import { Switcher } from 'components/commonFields/Switcher/Switcher'
import {
	AUTHENTICATION_DISABLE_MESSAGE,
	AUTHENTICATION_DISABLE_MODAL_APPLY,
	AUTHENTICATION_DISABLE_MODAL_CANCEL,
	AUTHENTICATION_DISABLE_TITLE,
	AUTHENTICATION_MODAL_APPLY,
	AUTHENTICATION_MODAL_CANCEL,
	AUTHENTICATION_TITLE,
	AUTHENTICATION_DISABLE_REASON,
	AUTHENTICATION_SECTION_LABEL,
	DISABLE_2FA_SUCCESS,
	EMPTY_REASON_MESSAGE,
} from 'utils/messages'
import { useDispatch, useSelector } from 'react-redux'
import FormTextArea from 'components/formFields/FormTextArea'
import { useForm } from 'react-hook-form'
import { QrModalMessage } from 'components/forms/AuthenticationSectionForm/QrModalMessage'
import { CodeModalMessage } from 'components/forms/AuthenticationSectionForm/CodeModalMessage'
import { FetchMethods, Order } from 'utils/constants'
import { auth } from 'store/selectors/auth'
import { myProfile } from 'store/selectors/myProfile'
import fetchApi from 'common/fetchApi'
import { GET_USER_INFO, SET_MFA } from 'common/endpoints'
import Loader from 'components/global/Loader/Loader'
import { toast } from 'react-toastify'

const AuthDisableModalMessage = ({ register }) => (
	<div>
		{AUTHENTICATION_DISABLE_MESSAGE}
		<FormTextArea
			fieldName="reason"
			register={register}
			label={AUTHENTICATION_DISABLE_REASON}
		/>
	</div>
)

export const AuthenticationSectionForm = ({ isEdit, onChange, section }) => {
	const dispatch = useDispatch()
	const { register, handleSubmit } = useForm()
	const [authSwitcherValue, setAuthSwitcherValue] = useState()
	const [{ label }] = section.content
	const { is_mfa_enabled, id: userID } = useSelector(auth.userInfo)
	const isMFASending = useSelector(myProfile.isMFASending)

	useEffect(() => {
		const fetchDetails = async () => {
			try {
				const userInfoResponse = await fetchApi(GET_USER_INFO)
				dispatch.auth.setUserInfo({ ...userInfoResponse.data })
			} catch (error) {
				console.log(error)
			} finally {
				dispatch.myProfile.setIsMFASending({ isSending: false })
			}
		}

		if (isMFASending) {
			fetchDetails()
		}
	}, [isMFASending])

	useEffect(() => {
		setAuthSwitcherValue(is_mfa_enabled)
	}, [is_mfa_enabled])

	const onClose = (value) => {
		setAuthSwitcherValue(value)
		onChange()
		dispatch.modal.hideModal()
	}

	const showCodeModal = () => {
		dispatch.modal.showModal({
			data: {
				title: AUTHENTICATION_TITLE,
				body: <CodeModalMessage onClose={onClose} order={Order.First} />,
				withFooterButtons: false,
				onClose: () => onClose(false),
			},
		})
	}

	const disableAuthentication = async (data) => {
		if (!data?.reason) {
			return toast.error(EMPTY_REASON_MESSAGE)
		}

		onChange()

		try {
			await fetchApi(SET_MFA(userID), {
				method: FetchMethods.Delete,
				data: {
					reason: data?.reason,
				},
			})
			setAuthSwitcherValue(false)
			dispatch.modal.hideModal()
			toast.success(DISABLE_2FA_SUCCESS)
		} catch (error) {
			const errors = error.response.data.errors
			console.log(errors)
		}

		dispatch.myProfile.setIsMFASending({ isSending: true })
	}

	const switchAuthentication = () => {
		if (authSwitcherValue) {
			setAuthSwitcherValue(false)
			dispatch.modal.showModal({
				data: {
					title: AUTHENTICATION_DISABLE_TITLE,
					body: <AuthDisableModalMessage register={register} />,
					primaryButtonLabel: AUTHENTICATION_DISABLE_MODAL_APPLY,
					onPrimary: handleSubmit(disableAuthentication),
					secondaryButtonLabel: AUTHENTICATION_DISABLE_MODAL_CANCEL,
					onSecondary: () => onClose(true),
					onClose: () => onClose(true),
				},
			})
		} else {
			setAuthSwitcherValue(true)
			dispatch.modal.showModal({
				data: {
					title: AUTHENTICATION_TITLE,
					body: <QrModalMessage />,
					primaryButtonLabel: AUTHENTICATION_MODAL_APPLY,
					onPrimary: showCodeModal,
					secondaryButtonLabel: AUTHENTICATION_MODAL_CANCEL,
					onClose: () => onClose(false),
					onSecondary: () => onClose(false),
				},
			})
		}
	}

	if (isMFASending) {
		return <Loader />
	}

	if (!isEdit) {
		return <div>{AUTHENTICATION_SECTION_LABEL(is_mfa_enabled)}</div>
	}

	return (
		<Switcher
			key={label}
			label={label}
			onChangeCallback={switchAuthentication}
			placement="lt"
			isCheckedByDefault={is_mfa_enabled}
			isChecked={authSwitcherValue}
		/>
	)
}

AuthenticationSectionForm.propTypes = {
	section: PropTypes.object,
}
