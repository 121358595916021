import React from 'react';
import PropTypes from 'prop-types';
import './EditRoleInfo.css';
import {
    EDIT_ROLE_DESCRIPTION,
    EDIT_ROLE_INFO,
    EDIT_ROLE_NAME,
    EDIT_ROLE_USER_TYPE,
    EDIT_ROLE_USER_TYPE_PLACEHOLDER
} from '../../../utils/messages';
import FormInput from '../../formFields/FormInput';
import { editRoleErrors } from '../../../store/selectors/selectors';
import FormDropdown from '../../formFields/FormDropdown';
import FormTextArea from '../../formFields/FormTextArea';
import {prepareDropdownList} from '../common/PrepareDataHelpers';

export const EditRoleInfo = ({ role, userTypes, register }) => {
    const preparedOptions = prepareDropdownList({ array: userTypes });

    const defaultUserType = preparedOptions.find(item => {
        return item.label.toLowerCase() === role?.user_type?.toLowerCase();
    });

    return (
        <>
            <div className='edit-role-info-header' data-testid='edit-role-info-header'>{EDIT_ROLE_INFO}</div>
            <FormInput
                label={EDIT_ROLE_NAME}
                content={role?.name}
                isRequired
                register={register}
                fieldName='name'
                errorsSelector={editRoleErrors}
            />
            <FormDropdown
                label={EDIT_ROLE_USER_TYPE}
                content={preparedOptions}
                defaultValue={defaultUserType}
                placeholder={EDIT_ROLE_USER_TYPE_PLACEHOLDER}
                register={register}
                fieldName='user_type_id'
                actionType='editRole/setEditRoleUserType'
                errorsSelector={editRoleErrors}
                isRequired
            />
            <FormTextArea
                label={EDIT_ROLE_DESCRIPTION}
                content={role?.description}
                register={register}
                fieldName='description'
            />
        </>
    )
}

EditRoleInfo.propTypes = {
    role: PropTypes.object.isRequired,
    userTypes: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired
    })).isRequired,
    register: PropTypes.func.isRequired
}