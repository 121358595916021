import React, {useEffect, useState} from 'react';
import Switch from 'react-switch';
import './Switcher.css';
import PropTypes from 'prop-types';
import { getLocatorId } from 'utils/locator';

export const Switcher = ({ label, onChangeCallback, isCheckedByDefault, placement = 'rt', isChecked, disabled }) => {
    const [defaultValue, setDefaultValue] = useState(isCheckedByDefault);
    const [checked, setChecked] = useState(isCheckedByDefault);

    useEffect(() => {
        setDefaultValue(isCheckedByDefault);
    }, [isCheckedByDefault]);

    const handleChange = () => {
        setChecked(!checked);
        setDefaultValue(false);
        onChangeCallback();
    };

	const checkedValue =
		typeof isChecked === 'boolean' ? isChecked : checked || defaultValue

    return (
        <div className='switcher'>
            { placement === 'lt' && <span className='switcher-label'>{label}</span> }
            <Switch
                onChange={handleChange}
                checked={checkedValue}
                onColor='#fff'
                offColor='#fff'
                onHandleColor='#071B3F'
                offHandleColor='#A6AAAC'
                handleDiameter={20}
                uncheckedIcon={false}
                checkedIcon={false}
                activeBoxShadow='0px 0px 1px 10px rgba(36, 54, 101, 0.1)'
                height={20}
                width={48}
                disabled={disabled}
                data-testid={getLocatorId('switcher', `${label}-${checked ? 'on' : 'off'}`)}
            />
            { placement === 'rt' && <span className='switcher-label'>{label}</span> }
        </div>
    )
}

Switcher.propTypes = {
    label: PropTypes.string,
    onChangeCallback: PropTypes.func,
    isCheckedByDefault: PropTypes.bool,
    placement: PropTypes.oneOf([ 'lt', 'rt' ]),
};