import React from 'react'
import './StepperContent.css'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import deleteIconRed from 'icons/deleteIconRed.svg'
import { Button } from 'components/buttons/Button/Button'
import { ButtonTypes, ButtonLabels } from 'utils/constants'
import { stepper } from 'store/selectors/stepper'
import { STEP_LABEL } from 'utils/messages'

export const StepperContent = ({
	step,
	headerText,
	handleOnRemove,
	handleOnBack,
	handleOnNext,
	children,
	dataTestId,
}) => {
	const okButtonLabel = step.isLast ? ButtonLabels.Save : ButtonLabels.Next;

	const stepperData = useSelector(stepper.data)
	const isSending = useSelector(stepper.isSending)

	return (
		<div className="stepper-content-container">
			<div className="stepper-content-header-container">
				<div className="stepper-content-header-labels">
					<span
						className="stepper-content-header-counter"
						data-testid={`${dataTestId}-header-${STEP_LABEL(step.index)}`}
					>
						{STEP_LABEL(step.index)}
					</span>
					<span
						className="stepper-content-header-label"
						data-testid={`${dataTestId}-header-${headerText}`}
					>
						{headerText}
					</span>
				</div>
				{!step?.isDefault && (
					<Button
						icon={deleteIconRed}
						alt={ButtonLabels.Remove}
						label={ButtonLabels.Remove}
						type={ButtonTypes.Remove}
						handleOnClick={handleOnRemove}
						dataTestId={dataTestId}
					/>
				)}
			</div>

			<div
				className="stepper-content-component"
				data-testid={`${dataTestId}-component`}
			>
				{children?.type ?
					React.cloneElement(children, {data: stepperData[step.label]})
					: null
				}
			</div>

			<div className="stepper-content-navigation-buttons">
				{!step.isFirst && (
					<Button
						label={ButtonLabels.Back}
						type={ButtonTypes.Secondary}
						handleOnClick={handleOnBack}
						dataTestId={dataTestId}
					/>
				)}
				{(
					<Button
						label={okButtonLabel}
						type={ButtonTypes.Primary}
						disabled={isSending}
						handleOnClick={handleOnNext}
						dataTestId={dataTestId}
					/>
				)}
			</div>
		</div>
	)
}

StepperContent.propTypes = {
	step: PropTypes.object.isRequired,
	handleOnRemove: PropTypes.func.isRequired,
	handleOnBack: PropTypes.func.isRequired,
	handleOnNext: PropTypes.func.isRequired,
	dataTestId: PropTypes.string,
}
