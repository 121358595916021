import React, { useState } from 'react'
import './UserInfoSectionForm.css'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'components/buttons/Button/Button'
import { useForm } from 'react-hook-form'
import {
	ButtonLabels,
	ButtonTypes,
	FetchMethods,
	FormInputFieldSizes,
	ProfileInfoKeys,
	ProfileInfoLabels
} from 'utils/constants'
import { Icons, IconsNames } from 'utils/icons'
import { auth } from 'store/selectors/auth'
import { FormInput2 } from 'components/formFields/FormInput2/FormInput2'
import { FormDatePicker2 } from 'components/formFields/FormDatePicker2/FormDatePicker2'
import fetchApi from 'common/fetchApi'
import { prepareDate } from 'utils/helpers/format.helper'
import Loader from 'components/global/Loader/Loader'
import {myProfile} from 'store/selectors/myProfile';

const ChangeUserInfoForm = ({
	handleOnSubmit,
	handleOnCancel,
	errors,
	userInfo,
	dataTestId,
}) => {
	const { handleSubmit, register, control } = useForm()

	const onSubmit = async (data) => {
		handleOnSubmit(data)
	}

	const FormInputTemplate = ({ profileInfoFieldKey }) => {
		return (
			<FormInput2
				key={profileInfoFieldKey}
				label={ProfileInfoLabels[profileInfoFieldKey]}
				register={register}
				isValidationDisable
				defaultValue={userInfo[profileInfoFieldKey]}
				control={control}
				fieldErrors={errors[profileInfoFieldKey]}
				fieldName={profileInfoFieldKey}
				fieldSize={FormInputFieldSizes.Middle}
				isRequired
				dataTestId={`${dataTestId}-${profileInfoFieldKey}`}
			/>
		)
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="user-info-form-container">
				<FormInputTemplate profileInfoFieldKey={ProfileInfoKeys.Firstname} />
				<FormInputTemplate profileInfoFieldKey={ProfileInfoKeys.Lastname} />
				<FormDatePicker2
					label={ProfileInfoLabels[ProfileInfoKeys.DateOfBirth]}
					fieldSize={FormInputFieldSizes.Middle}
					content={userInfo[ProfileInfoKeys.DateOfBirth]}
					register={register}
					isRequired
					fieldErrors={errors[ProfileInfoKeys.DateOfBirth]}
					fieldName={ProfileInfoKeys.DateOfBirth}
					control={control}
					dataTestId={`${dataTestId}-${ProfileInfoKeys.DateOfBirth}`}
				/>
				<FormInputTemplate profileInfoFieldKey={ProfileInfoKeys.Email} />
			</div>
			<div className="change-user-info-form-buttons">
				<Button
					label={ButtonLabels.Save}
					icon={Icons[IconsNames.Check]}
					handleOnClick={() => {}}
					type={ButtonTypes.Primary}
					dataTestId={`${dataTestId}-save-button`}
				/>
				<Button
					label={ButtonLabels.Cancel}
					type={ButtonTypes.Secondary}
					handleOnClick={handleOnCancel}
					dataTestId={`${dataTestId}-cancel-button`}
				/>
			</div>
		</form>
	)
}

const UserInfoForm = ({ userInfo, dataTestId }) => {
	return Object.values(ProfileInfoKeys).map((key) => {
		const keyLabel = ProfileInfoLabels[key]
		const keyValue = userInfo[key]

		const formattedId = (value) => `${dataTestId}-${value}`

		return (
			<div key={keyLabel} className="user-info-form-field">
				<div
					className="user-info-form-label"
					data-testid={formattedId(keyLabel)}
				>
					{keyLabel}
				</div>
				<div
					className="user-info-form-value"
					data-testid={formattedId(keyValue)}
				>
					{keyValue}
				</div>
			</div>
		)
	})
}

export const UserInfoSectionForm = ({ isEdit, onChange, dataTestId }) => {
	const dispatch = useDispatch()

	const isUserInfoSending = useSelector(myProfile.isUserInfoSending)
	const authErrors = useSelector(auth.errors)
	const authUserInfo = useSelector(auth.userInfo)

	const [userInfo, setUserInfo] = useState(authUserInfo)

	const handleOnSubmit = async (data) => {
		dispatch.myProfile.setIsUserInfoSending({ isSending: true })

		setUserInfo({
			...data,
			date_of_birth: prepareDate(data.date_of_birth),
		})

		try {
			const response = await fetchApi(`api/v2/user/current/details`, {
				method: FetchMethods.Patch,
				data: {
					...data,
					date_of_birth: prepareDate(data.date_of_birth),
				},
			})

			const { user } = response.data

			onChange()
			dispatch.auth.setUserInfo(user)
			dispatch.auth.setErrors({ errors: {} })
		} catch (error) {
			const { errors } = error.response.data
			dispatch.auth.setErrors({ errors })
		}
		finally {
			dispatch.myProfile.setIsUserInfoSending({ isSending: false })
		}
	}

	const handleOnCancel = (e) => {
		e.preventDefault()
		onChange()
		setUserInfo({
			...authUserInfo,
		})
		dispatch.auth.setErrors({ errors: {} })
	}

	if (isUserInfoSending) {
		return <Loader />
	}

	return isEdit ? (
		<ChangeUserInfoForm
			handleOnSubmit={handleOnSubmit}
			handleOnCancel={handleOnCancel}
			errors={authErrors}
			userInfo={userInfo}
			dataTestId={`${dataTestId}-change-form`}
		/>
	) : (
		<UserInfoForm userInfo={userInfo} dataTestId={`${dataTestId}-form`} />
	)
}

UserInfoSectionForm.propTypes = {
	isEdit: PropTypes.bool,
	onChange: PropTypes.func,
}

UserInfoForm.propTypes = {
	userInfo: PropTypes.object,
}

ChangeUserInfoForm.propTypes = {
	handleOnSave: PropTypes.func,
	handleOnCancel: PropTypes.func,
	userInfo: PropTypes.object,
	dataTestId: PropTypes.string,
}
