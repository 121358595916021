import React from 'react';
import './Pagination.css';
import PageStatistics from 'components/global/Table/PageStatistics';
import { PaginationDropdown } from 'components/global/Table/PaginationDropdown';
import {PaginationButtons} from "components/global/Table2/TablePagination/PaginationButtons";
import {useDispatch, useSelector} from 'react-redux';
import { benefits } from 'store/selectors/benefits';
import {PaginationEnum} from "utils/helpers/tables.helper";

export const BenefitsTablePagination = () => {
    const dispatch = useDispatch()

    const {
        total,
        from,
        to,
        current_page,
        last_page
    } = useSelector(benefits.data);

    const onPagination = (data) => {
        dispatch({
            type: 'benefits/setCurrentPage',
            payload: PaginationEnum[data.goToPage](data)
        })
    }

    const onPaginationDropdown = (data) => {
        dispatch({
            type: 'benefits/setRowsPerPage',
            payload: data?.value
        })
    }

    return (
        <div className="footer-container">
            <PageStatistics totalEntries={total} fromEntry={from} toEntry={to} />
            <div className="footer-pagination-container">
                <PaginationDropdown onChange={onPaginationDropdown} defaultValue={25} />
                <div className='footer-pagination-buttons'>
                    <PaginationButtons
                        onPagination={onPagination}
                        currentPage={current_page}
                        lastPage={last_page}
                        dataTestId='benefits'
                    />
                </div>
            </div>
        </div>
    );
}