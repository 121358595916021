import React from 'react';
import PropTypes from 'prop-types'
import { STEP_LABEL } from '../../../utils/messages';

const StepLabel = ({ step }) => (
    <div className="text-ultra-dark-black font-secondary
       text-tiny leading-small tracking-medium"
    >
        {STEP_LABEL(step)}
    </div>
);

export default StepLabel;

StepLabel.propTypes = {
    step: PropTypes.number.isRequired,
};