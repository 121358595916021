import React, { useEffect, useState } from 'react'
import 'components/forms/EditUserAuthentication/EditUserAuthentication.css'
import PropTypes from 'prop-types'
import { Switcher } from 'components/commonFields/Switcher/Switcher'
import {
	AUTHENTICATION_DISABLE_MESSAGE,
	AUTHENTICATION_DISABLE_MODAL_APPLY,
	AUTHENTICATION_DISABLE_MODAL_CANCEL,
	AUTHENTICATION_DISABLE_TITLE,
	AUTHENTICATION_DISABLE_REASON,
	EDIT_2FA,
	LABEL_2FA,
	DISABLE_2FA_SUCCESS,
	EMPTY_REASON_MESSAGE,
} from 'utils/messages'
import { useDispatch, useSelector } from 'react-redux'
import FormTextArea from 'components/formFields/FormTextArea'
import { useForm } from 'react-hook-form'
import Loader from 'components/global/Loader/Loader'
import { editUser } from 'store/selectors/selectors'
import fetchApi from 'common/fetchApi'
import { SET_MFA } from 'common/endpoints'
import { FetchMethods } from 'utils/constants'
import { toast } from 'react-toastify'

const AuthDisableModalMessage = ({ register }) => (
	<div>
		{AUTHENTICATION_DISABLE_MESSAGE}
		<FormTextArea
			fieldName="reason"
			register={register}
			label={AUTHENTICATION_DISABLE_REASON}
		/>
	</div>
)

export const EditUserAuthentication = () => {
	const dispatch = useDispatch()
	const { register, handleSubmit } = useForm()
	const [authSwitcherValue, setAuthSwitcherValue] = useState()
	const {
		isLoading,
		data: { id },
	} = useSelector(editUser)
	const defaultValue = true

	const disableAuthentication = async (data) => {
		if (!data?.reason) {
			return toast.error(EMPTY_REASON_MESSAGE)
		}

		try {
			await fetchApi(SET_MFA(id), {
				method: FetchMethods.Delete,
				data: {
					reason: data?.reason,
				},
			})
			setAuthSwitcherValue(false)
			dispatch.modal.hideModal()
			toast.success(DISABLE_2FA_SUCCESS)
		} catch (error) {
			const errors = error.response.data.errors
			console.log(errors)
		}
	}

	useEffect(() => {
		setAuthSwitcherValue(defaultValue)
	}, [defaultValue])

	const onClose = (value) => {
		setAuthSwitcherValue(value)
	}

	const switchAuthentication = () => {
		setAuthSwitcherValue(false)
		dispatch.modal.showModal({
			data: {
				title: AUTHENTICATION_DISABLE_TITLE,
				body: <AuthDisableModalMessage register={register} />,
				primaryButtonLabel: AUTHENTICATION_DISABLE_MODAL_APPLY,
				onPrimary: handleSubmit(disableAuthentication),
				secondaryButtonLabel: AUTHENTICATION_DISABLE_MODAL_CANCEL,
				onSecondary: () => onClose(true),
				onClose: () => onClose(true),
			},
		})
	}

	return (
		<>
			<div className="edit-user-2fa-header" data-testid="edit-user-2fa-header">
				{EDIT_2FA}
			</div>
			{isLoading ? (
				<div className="loader-container">
					<Loader />
				</div>
			) : (
				<Switcher
					key={LABEL_2FA}
					label={LABEL_2FA}
					onChangeCallback={switchAuthentication}
					placement="lt"
					isCheckedByDefault={defaultValue}
					isChecked={authSwitcherValue}
					disabled={!authSwitcherValue}
				/>
			)}
		</>
	)
}

EditUserAuthentication.propTypes = {
	section: PropTypes.object,
}
