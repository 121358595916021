import React from 'react'
import { AccordionElement } from './AccordionElement/AccordionElement'
import PropTypes from 'prop-types'
import tw from 'twin.macro'
import styled from 'styled-components/macro'

const Container = styled.div`
	${tw`w-full`}
`

export const Accordion = ({ accordionElements, dataTestId }) => {
	return (
		<Container data-testid={dataTestId}>
			{accordionElements.map((element) => (
				<AccordionElement
					key={element.label}
					label={element.label}
					additional={element.additional}
					bodyContent={element.bodyContent}
					dataTestId={dataTestId}
				/>
			))}
		</Container>
	)
}

Accordion.propTypes = {
	accordionElements: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			bodyContent: PropTypes.element.isRequired,
		})
	),
}
