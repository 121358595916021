import fetchApi from "common/fetchApi";
import {GET_USERS} from "common/endpoints";
import {FetchMethods} from "utils/constants";
import {prepareUsersList} from "components/forms/common/PrepareDataHelpers";

const timeout = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const loadCallback = async (searchQuery, loadedOptions, { page }) => {
    if (searchQuery) {
        await timeout(1000);
    }

    const response = await fetchApi(GET_USERS, {
        method: FetchMethods.Get,
        params: {
            search: searchQuery || '',
            page: searchQuery ? '' : page,
            exclude_current_user: '1'
        }
    });
    const users = response.data?.data;
    const preparedUsers = prepareUsersList(users);

    return {
        options: preparedUsers,
        hasMore: response.data?.last_page > response.data?.current_page,
        additional: {
            page: searchQuery ? page : page + 1
        },
    };
};