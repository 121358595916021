import React from 'react';
import notificationBell from '../../../icons/notifications.svg';
import { NOTIFICATIONS_ALT } from '../../../utils/messages';

const Notifications = () => (
    <div className="h-[20px] w-[20px]">
        <img
            alt={NOTIFICATIONS_ALT}
            src={notificationBell}
            className='h-[20px] w-[20px]'
        />
    </div>
);

export default Notifications;