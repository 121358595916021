import './CheckboxGroup.css'
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Checkbox } from '../Checkbox/Checkbox'

export const CheckboxGroup = ({
	labels,
	defaultChecked,
	checkboxGroupName,
	defaultDisabled,
	onGroupChanged,
	dataTestId = 'checkbox-group',
}) => {
	const [checkboxes, setCheckboxes] = useState()
	const [activatedCheckboxes, setActivatedCheckboxes] = useState([])

	useEffect(() => {
		const newCheckboxes = labels.map((label, index) => {
			return {
				id: index,
				label,
				selected: !!defaultChecked?.includes(label),
				disabled: !!defaultDisabled?.includes(label),
			}
		})

		setCheckboxes(newCheckboxes);
		setActivatedCheckboxes(newCheckboxes.filter(item => {
			return item.selected;
		}))
	}, [labels, defaultChecked])

	useEffect(() => {
		onGroupChanged(
			activatedCheckboxes.map((checkbox) => {
				return checkbox.label  
			})
		)
	}, [activatedCheckboxes])

	const onCheckboxChanged = (selectedCheckbox) => {
		const newCheckboxes = activatedCheckboxes.filter((checkbox) => {
			return checkbox.id !== selectedCheckbox.id
		})

		if (selectedCheckbox.selected) {
			newCheckboxes.push(selectedCheckbox)
		}

		setActivatedCheckboxes(newCheckboxes)
	}

	return (
		<div className="checkboxes-container" data-testid={dataTestId}>
			{checkboxGroupName && (
				<span className="checkboxes-group-label">{checkboxGroupName}</span>
			)}
			<div className='checkbox-group-wrapper'>
				{checkboxes?.map((checkbox) => (
					<div key={checkbox.id} className="checkbox-wrapper">
						<Checkbox checkboxData={checkbox} onCheckboxChanged={onCheckboxChanged} />
					</div>
				))}
			</div>
		</div>
	)
}

CheckboxGroup.propTypes = {
	labels: PropTypes.arrayOf(PropTypes.string),
	checkboxGroupName: PropTypes.string,
	onGroupChanged: PropTypes.func.isRequired,
	dataTestId: PropTypes.string,
	defaultChecked: PropTypes.arrayOf(PropTypes.string),
	defaultDisabled: PropTypes.arrayOf(PropTypes.string)
}
