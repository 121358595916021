import React, { useEffect, useState } from 'react'
import {
	getCheckboxLabels,
	convertCheckboxesToActions,
} from 'utils/helpers/actions.helper'
import { CheckboxGroup } from 'components/commonFields/CheckboxGroup/CheckboxGroup'
import { getActivatedActions } from 'utils/helpers/actions.helper'
import { reverseFirstLetterCapitalize } from 'utils/helpers/format.helper'
import PropTypes from 'prop-types'
import './Feature.css'

const reverseFirstLetterInArray = (array) => {
	return array.map((item) => reverseFirstLetterCapitalize(item))
}

export const Feature = ({ feature, checkboxGroupName, featureChanged }) => {
	const [labels, setLabels] = useState()
	const [isPreparing, setIsPreparing] = useState(true)
	const [checkedCheckboxes, setCheckedCheckboxes] = useState([])
	const [groupName, setGroupName] = useState()

	const handleCheckboxChanged = (checkedChechboxes) => {
		const actions = {
			...feature.actions,
			...convertCheckboxesToActions(
				reverseFirstLetterInArray(checkedChechboxes)
			),
		}

		featureChanged(groupName, actions)
	}

	useEffect(() => {
		if (Boolean(feature)) {
			const { actions } = feature

			setCheckedCheckboxes(
				reverseFirstLetterInArray(getActivatedActions(actions))
			)
			setLabels(reverseFirstLetterInArray(getCheckboxLabels(actions)))
			setGroupName(reverseFirstLetterCapitalize(checkboxGroupName))
			setIsPreparing(false)
		}
	}, [feature])

	return (
		!isPreparing && (
			<div className='checkbox-group-container'>
				<CheckboxGroup
					labels={labels}
					defaultChecked={checkedCheckboxes}
					defaultDisabled={labels} // by default for add user step 2
					checkboxGroupName={groupName}
					onGroupChanged={handleCheckboxChanged}
				/>
			</div>
		)
	)
}

Feature.propTypes = {
	feature: PropTypes.object,
	checkboxGroupName: PropTypes.string,
	featureChanged: PropTypes.func.isRequired,
}
