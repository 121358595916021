import React, {useEffect} from 'react'
import Layout from 'components/container/Layout'
import {
	AUDIT_LOG_PAGE_HEADER,
	GO_BACK_TO_BENEFIT_VIEW,
	GO_BACK_TO_ROLE_VIEW,
	GO_BACK_TO_USER_VIEW
} from 'utils/messages'
import {Page} from 'components/global/Page/Page';
import {useDispatch, useSelector} from 'react-redux';
import { audit } from 'store/selectors/auditLogs';
import {AuditTable} from 'components/audit/AuditTable';

export const AuditLogsPage = (location) => {
	const dispatch = useDispatch();
	const data = useSelector(audit.data)
	const rowData = useSelector(audit.rowData)
	const backLink = useSelector(audit.backLink)
	const contextType = useSelector(audit.contextType)

	const endpoint = data?.endpoint || 'app/v2/audit' // todo temporary

	useEffect(() => {
		return () => dispatch.auditLogs.setClearStore()
	}, [])

	const contextData = {
		users: {
			toBackLinkTitle: GO_BACK_TO_USER_VIEW,
			onBackLinkClick: () => {
				dispatch.rightPanel.setRightPanel({
					contextType: data?.contextType,
					id: data?.id,
					rowData
				})
			}
		},
		roles: {
			toBackLinkTitle: GO_BACK_TO_ROLE_VIEW,
			onBackLinkClick: () => {
				dispatch.rightPanel.setRightPanel({
					contextType: data?.contextType,
					rowData
				})
			}
		},
		benefits: {
			toBackLinkTitle: GO_BACK_TO_BENEFIT_VIEW,
			onBackLinkClick: () => {
				dispatch.benefits.setViewData({
					viewId: data?.viewId,
					headerLabel: data?.headerLabel
				})
			}
		}
	}

	const auditLogs = (
		<Page
			title={AUDIT_LOG_PAGE_HEADER}
			toBackLink={backLink}
			onBackLinkClick={contextData[contextType]?.onBackLinkClick}
			toBackLinkTitle={contextData[contextType]?.toBackLinkTitle}
			location={location}
			dataTestId={AUDIT_LOG_PAGE_HEADER}
		>
			<AuditTable endpoint={endpoint} tableName='audit_log' />
		</Page>
	)

	return <Layout component={auditLogs} />
}
