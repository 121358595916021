import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { navigate } from 'gatsby';
import Layout from 'components/container/Layout';
import PageHeader from 'components/global/PageHeader/PageHeader';
import Table from 'components/global/Table/Table';
import {sortingUsersData, usersData, rightPanel } from 'store/selectors/selectors';
import TableHeader from 'components/global/Table/TableHeader';
import {useGetUsersList} from 'hooks/useGetUsersList';
import {
    USER_EMAIL,
    USER_FIRSTNAME,
    USER_LASTNAME,
    USER_USER_TYPE,
    FIND_USER,
    ADD_USER,
    USERS_MENU_EDIT,
    USERS_MENU_DELETE,
    DELETE_USER_MODAL_HEADER,
    DELETE_USER_MODAL_BODY,
    DELETE_USER_MODAL_APPLY,
    DELETE_USER_MODAL_CANCEL
} from 'utils/messages';
import { UsersTablePagination } from 'components/global/Table/Pagination/UsersTablePagination';
import { SearchInput } from 'components/global/SearchInput/SearchInput';
import { AddButton } from 'components/buttons/AddButton/AddButton';
import {Icons, IconsNames} from "utils/icons";
import fetchApi from "common/fetchApi";
import {DELETE_USER} from "common/endpoints";
import {toast} from "react-toastify";

const ModalMessage = <div>{DELETE_USER_MODAL_BODY}</div>

export const UsersPage = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const { id: highlightedRowId } = useSelector(rightPanel);
    const { isLoaded: isUsersListLoaded } = useGetUsersList({ excludeCurrentUser: false });
    const tableData = useSelector(usersData);
    const headerNames = {
        person_fname: USER_FIRSTNAME,
        person_sname: USER_LASTNAME,
        person_email: USER_EMAIL,
        user_type: USER_USER_TYPE
    };
    const rows = ['user_first_name', 'user_sur_name', 'user_email', 'user_type'];

    const storeLocation = {
        selector: sortingUsersData,
        actionType: 'users/setSortingData'
    };

    const headerContent = (
        <>
            <div className="flex font-base font-semibold decoration-ultra-dark-black text-medium leading-medium
             pl-8 py-4 pr-6 border-solid border-b border-t border-light-beige">
                <SearchInput
                    actionType='users/setSearchPage'
                    id='search-users'
                    placeholder={FIND_USER}
                    minLength={3}
                />
            </div>
            <TableHeader
                header={headerNames}
                isLoaded={isUsersListLoaded}
                storeLocation={storeLocation}
            />
        </>
    );

    const handleTableRowClick = (event, contextType, rowData) => {
		event.preventDefault()
		if (contextType) {
			dispatch({
				type: 'rightPanel/setRightPanel',
				payload: { contextType, rowData, id: rowData?.user_id },
			})
		}
	}

	const handleEditUser = (rowData) => {
        dispatch.editUser.setEditPage(rowData)
        navigate('/app/users/editUser')
    }

    const handleDeleteRow = (rowData) => {
        const onApply = async () => {
            setLoading(true)
            try {
                dispatch.modal.hideModal()
                const response = await fetchApi(DELETE_USER(rowData?.user_id), { method: 'DELETE' });
                setLoading(false)
                dispatch.users.setIsUpdatedData(true)
                toast.success(response.data.message);
            } catch (error) {
                setLoading(false)
                toast.error(error.response.data?.message);
            }
        }

        dispatch.modal.showModal({
            data: {
                title: DELETE_USER_MODAL_HEADER,
                body: ModalMessage,
                primaryButtonLabel: DELETE_USER_MODAL_APPLY,
                onPrimary: onApply,
                secondaryButtonLabel: DELETE_USER_MODAL_CANCEL
            }
        });
    }

	const menuItems = [
        {
            label: USERS_MENU_EDIT,
            icon: Icons[IconsNames.Edit],
            onClick: (rowData) => handleEditUser(rowData)
        },
        {
            label: USERS_MENU_DELETE,
            icon: Icons[IconsNames.Delete],
            color: 'red',
            onClick: (rowData) => handleDeleteRow(rowData)
        }
    ]

    const Users = (
        <div>
            <div className="flex justify-between items-center pt-11 pl-16 pb-6 pr-8">
                <PageHeader title="Users" />
                <AddButton link='/app/users/addUser' label={ADD_USER} />
            </div>
            <Table
                data={tableData}
                isLoaded={isUsersListLoaded && !loading}
                rows={rows}
                headerComponent={headerContent}
                renderPaginationComponent={UsersTablePagination}
                contextType='users'
                highlightedRowId={highlightedRowId}
                handleTableRowClick={handleTableRowClick}
                menuItems={menuItems}
                dataTestId='users-list'
            />
        </div>
    );

    useEffect(() => {
        return () =>
            dispatch({
                type: 'users/setClearStore'
            });
    }, []);

    return <Layout component={Users} />;
}