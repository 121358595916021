import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { SORTING_ALT } from '../../../utils/messages';
import sortingAscending from '../../../icons/sorting_ascending.svg';
import sortingDescending from '../../../icons/sorting_descending.svg';
import { sortingDirections } from '../../../utils/constants';
import {getLocatorId} from '../../../utils/locator';

const TableHeader = ({ header, isLoaded, storeLocation, isSortingDisabled = false }) => {
    const dispatch = useDispatch();
    const { selector, actionType } = storeLocation;
    const { direction, column } = useSelector(selector);

    const handleClick = useCallback((event) => {
        if (isLoaded && !isSortingDisabled) {
            dispatch({
                type: actionType,
                payload: {
                    column: event.target.id,
                    direction: direction === sortingDirections.DOWN ? sortingDirections.UP : sortingDirections.DOWN
                }
            });
        }
    }, [actionType, direction, dispatch, isLoaded]);

    return (
        <div className={`font-base font-semibold text-medium leading-medium
        grid ${Object.keys(header).length === 4 ? 'grid-cols-fourRows' : 'grid-cols-sixRows'} 
        px-[32px] pt-[12px] pb-[13px] border-solid 
        border-b border-t border-light-beige`}>
            {Object.entries(header).map(([key, value]) =>
                <div
                    key={key}
                    className="hover:cursor-pointer flex"
                    onClick={handleClick}
                    id={key}
                    data-testid={getLocatorId('table-header', value)}
                >
                    <div
                        className={`text-${isLoaded ? 'ultra-dark-black' : 'semi-gray'}`}
                        id={key}
                    >
                        {value}
                    </div>
                    {column === key && !isSortingDisabled ?
                        (
                            <img
                                alt={SORTING_ALT}
                                src={direction === sortingDirections.DOWN ? sortingDescending : sortingAscending}
                                className="h-[24px] w-[24px] ml-[13px]"
                                id={key}
                                data-testid={getLocatorId('sorting-icon', direction)}
                            />
                        )
                        : null
                    }
                </div>
            )}
        </div>
    );
}

export default TableHeader;

TableHeader.propTypes = {
    header: PropTypes.object.isRequired,
    isLoaded: PropTypes.bool,
    storeLocation: PropTypes.shape({
        selector: PropTypes.func.isRequired,
        actionType: PropTypes.string.isRequired
    }),
    isSortingDisabled: PropTypes.bool
}