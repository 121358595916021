import React from 'react';
import PropTypes from 'prop-types';

const Workplace = ({ component }) => (
        <div className="max-h-max min-h-1060 w-full bg-light-purple">
            {component}
        </div>
    )

export default Workplace;

Workplace.propTypes = {
    component: PropTypes.element,
}