import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { navigate } from 'gatsby';
import { toast } from 'react-toastify';
import fetchApi from '../common/fetchApi';
import { FetchMethods } from 'utils/constants';

export const useUpdateForm = () => {
    const dispatch = useDispatch();

    const callback = useCallback( async ({
          path,
          data,
          errorsActionType,
          navigatePath
      }) => {
        try {
            const response = await fetchApi(path, {
                method: FetchMethods.Patch,
                data
            });

            dispatch({
                type: errorsActionType,
                payload: {}
            });

            navigatePath && await navigate(navigatePath);
            toast.success(response.data.message);
        } catch (error) {
            dispatch({
                type: errorsActionType,
                payload: error.response.data.errors
            });
        }
    }, [])

    return { callback }
};