import React from 'react';
import './ResetPasswordForm.css';
import {
    RESET_PASSWORD_HEADER,
    RESET_PASSWORD_SUCCESS_TEXT,
    RESET_PASSWORD_SUCCESS_CONTACT,
    RESET_PASSWORD_SUCCESS_CONTACT_LINK
} from "utils/messages";
import ResetPassword from "icons/reset_password.svg";
import {Button} from "components/buttons/Button/Button";
import {navigate} from "gatsby";
import {ButtonLabels, ButtonSizes, ButtonTypes} from "utils/constants";

export const ResetPasswordSuccessForm = () => {
    const handleBackToLogin = (event) => {
        event.preventDefault();
        navigate('/app/login')
    }

    return (
        <form className='reset-password-form'>
            <img
                src={ResetPassword}
                alt={RESET_PASSWORD_HEADER}
                className='reset-password-icon'
            />
            <div className='reset-password-header'>{RESET_PASSWORD_HEADER}</div>
            <div className='reset-password-success-text'>{RESET_PASSWORD_SUCCESS_TEXT}</div>
            <div className='reset-password-success-contact'>
                {RESET_PASSWORD_SUCCESS_CONTACT}
                <a href={`mailto:${process.env.GATSBY_SUPPORT_EMAIL}`} className='reset-password-success-link'>
                    {RESET_PASSWORD_SUCCESS_CONTACT_LINK}
                </a>
            </div>
            <Button
                handleOnClick={handleBackToLogin}
                type={ButtonTypes.Secondary}
                size={ButtonSizes.Large}
                label={ButtonLabels.BackToLogin}
                dataTestId='reset-password-back-button'
            />
        </form>
    )
}