import React, { useContext } from 'react'
import './StepperHeader.css'
import PropTypes from 'prop-types'
import editIcon from 'icons/edit.svg'
import { Button } from 'components/buttons/Button/Button'
import { ButtonTypes, ButtonLabels } from 'utils/constants'
import { STEP_LABEL } from 'utils/messages'

export const StepperHeader = ({ step, headerText, onHeaderClick, dataTestId }) => {
	return (
		<div 
			className={`stepper-header ${step.isDisabled ? 'disabled' : ''}`} 
			onClick={() => onHeaderClick()}
		>
			<div className="stepper-header-labels-container">
				<span
					className="stepper-header-counter"
					data-testid={`${dataTestId}-${STEP_LABEL(step.index)}`}
				>
					{STEP_LABEL(step.index)}
				</span>
				<span
					className="stepper-header-label"
					data-testid={`${dataTestId}-${headerText}`}
				>
					{headerText}
				</span>
			</div>
			<Button
				icon={editIcon}
				alt={ButtonLabels.Edit}
				label={ButtonLabels.Edit}
				type={ButtonTypes.Edit}
				handleOnClick={() => {}}
				dataTestId={dataTestId}
			/>
		</div>
	)
}

StepperHeader.propTypes = {
	step: PropTypes.object.isRequired,
	headerText: PropTypes.string,
	onHeaderClick: PropTypes.func.isRequired,
	dataTestId: PropTypes.string,
}
