import React, { useEffect, useState } from 'react';
import './BenefitTable.css';
import PropTypes from 'prop-types';
import { Table } from 'components/global/Table2/Table/Table';
import {camelize, PaginationEnum} from 'utils/helpers/tables.helper';
import fetchApi from 'common/fetchApi';
import Loader from 'components/global/Loader/Loader';
import {Sorting, sortingDirections} from 'utils/constants';
import {useDispatch} from "react-redux";

export const BenefitTable = ({ tableName, endpoint, menuItems, rowData }) => {
    const [data, setData] = useState({});
    const [params, setParams] = useState({});
    const [tableParams, setTableParams] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();
    useEffect(() => {
        return () => dispatch.tables.setClearTableStore({ tableName: camelize(tableName) })
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const { data } = await fetchApi(endpoint, { params });
                setData(data);
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
            }
        }
        if (tableName && endpoint) fetchData();
    }, [tableName, endpoint, params]);

    const onSort = (item) => {
        setTableParams({
            ...tableParams,
            sortingColumn: item?.field,
            sortingDirection: item?.direction
        });
        setParams({
            ...params,
            sort_by:`${item?.field} ${item?.direction === sortingDirections.UP ? Sorting.Desc : Sorting.Asc}`
        })
    };

    const onFilter = (item) => {
        setTableParams({
            ...tableParams,
            filters: {
                ...tableParams.filters,
                [item.filterName]: {
                    label: item.label,
                    value: item.value
                }
            }
        });
        setParams({
            ...params,
            [`filter[${item.filterName}]`]: item.value
        })
    }

    const onPagination = (item) => {
        setParams({
            ...params,
            page: PaginationEnum[item.goToPage](item)
        });
    }

    if (isLoading) {
        return (
            <div className='loader-container'>
                <Loader />
            </div>
        );
    }

    return (
        <Table
            data={data}
            params={tableParams}
            tableName={camelize(tableName)}
            menuItems={menuItems}
            rowData={rowData}
            onSort={onSort}
            onFilter={onFilter}
            onPagination={onPagination}
            isRowMenuCollapsed
            dataTestId={tableName}
        />
    )
};

BenefitTable.propTypes = {
    tableName: PropTypes.string.isRequired,
    endpoint: PropTypes.string.isRequired,
    menuItems: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        icon: PropTypes.node,
        onClick: PropTypes.func
    })),
    rowData: PropTypes.arrayOf(PropTypes.object)
}
