import React from 'react';
import './GoBackButton.css';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { getLocatorId } from 'utils/locator';
import arrowLeft from '../../../icons/arrow-left.svg';

const GoBackButton = ({ title, link, onClick, location }) => (
        <Link
            to={link}
            onClick={onClick}
            data-testid={getLocatorId('link', title)}
            className="go-back-button-container"
            state={{ prevPath: location?.pathname || location?.location.pathname }}
        >
            <div className="go-back-button-content-wrapper">
                <img
                    alt={title}
                    src={arrowLeft}
                    className="go-back-button-arrow"
                />
                <span className="go-back-button-text"
                >
                    {title}
                </span>
            </div>
        </Link>
);

export default GoBackButton;

GoBackButton.propTypes = {
        title: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
        onClick: PropTypes.func,
        location: PropTypes.object
}