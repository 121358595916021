import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import './AddUsers.css';
import {ButtonLabels, ButtonSizes, ButtonTypes, FetchMethods} from 'utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import {
    ADD_USERS_DROPDOWN_LABEL,
    ADD_USERS_DROPDOWN_PLACEHOLDER,
    ADD_USERS_CHECKBOX_LIST_HEADER
} from 'utils/messages';
import {
    convertOptionToCheckbox,
    convertCheckboxToOption
} from 'components/forms/common/PrepareDataHelpers'
import { DropdownCheckboxList } from 'components/commonFields/DropdownCheckboxList/DropdownCheckboxList';
import { newRole } from 'store/selectors/selectors';
import fetchApi from 'common/fetchApi';
import {ADD_NEW_ROLE} from 'common/endpoints';
import Loader from 'components/global/Loader/Loader';
import {Button} from "components/buttons/Button/Button";
import {loadCallback} from "utils/helpers/usersList.helper";

export const AddUsers = () => {
    const dispatch = useDispatch();
    const roleObject = useSelector(newRole);

    const [isLoaded, setIsLoaded] = useState(true);

    const { checkboxes, options } = useSelector(newRole);
    const { handleSubmit } = useForm();

    const handleDropdownChange = (dropdownOption) => {
        dispatch({
            type: 'newRole/setCheckboxes',
            payload: {
                checkboxes: [...checkboxes, convertOptionToCheckbox(dropdownOption)]
            }
        })

        dispatch({
            type: 'newRole/setOptions',
            payload: {
                options: [...options.filter((option) => option.value !== dropdownOption.value)]
            }
        })
    }

    const handleCheckboxListChange = (checkboxOption) => {
        if (!checkboxOption.selected) {
            dispatch({
                type: 'newRole/setCheckboxes',
                payload: {
                    checkboxes: [...checkboxes.filter((checkbox) => checkbox.id !== checkboxOption.id)]
                }
            })

            dispatch({
                type: 'newRole/setOptions',
                payload: {
                    options: [convertCheckboxToOption(checkboxOption), ...options]
                }
            })
        }
    }

    const handleBack = (event) => {
        event.preventDefault();
        dispatch({
            type: 'newRole/setStepInfo',
            payload: {
                step: 3,
                isExpanded: false
            }
        });
        dispatch({
            type: 'newRole/setStepInfo',
            payload: {
                step: 2,
                isExpanded: true
            }
        });
    };

    const onSubmit = async () => {
        const checkedIds = checkboxes.map(item => item.id);

        try {
            setIsLoaded(false);

            const response = await fetchApi(ADD_NEW_ROLE, {
                method: FetchMethods.Post,
                data: {...roleObject, users: checkedIds}
            });

            dispatch({
                type: 'newRole/setErrors',
                payload: {}
            });
            setIsLoaded(true);
            dispatch({
                type: 'newRole/setStepInfo',
                payload: {
                    step: 3,
                    isExpanded: false,
                    isCompleted: true
                }
            });
            await navigate('/app/roles');
            toast.success(response.data.message);
        } catch (error) {
            setIsLoaded(true);

            dispatch({
                type: 'newRole/setErrors',
                payload: error.response.data.errors
            });

            if (error.response.data.errors?.name?.length || error.response.data.errors?.user_type_id?.length) {
                dispatch({
                    type: 'newRole/setStepInfo',
                    payload: {
                        step: 1,
                        isExpanded: true
                    }
                });
                dispatch({
                    type: 'newRole/setStepInfo',
                    payload: {
                        step: 3,
                        isExpanded: false
                    }
                });
            }
            else if (error.response.data.errors?.actions.length) {
                dispatch({
                    type: 'newRole/setStepInfo',
                    payload: {
                        step: 2,
                        isExpanded: true
                    }
                });
                dispatch({
                    type: 'newRole/setStepInfo',
                    payload: {
                        step: 3,
                        isExpanded: false
                    }
                });
            }
        }
    }

    if (!isLoaded) {
        return (
            <div className='add-users-main-container'>
                <Loader/>
            </div>
        );
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className='add-users-main-container'>
                <DropdownCheckboxList
                    loadCallback={loadCallback}
                    checkboxes={checkboxes}
                    excludedOptions={checkboxes}
                    placeholder={ADD_USERS_DROPDOWN_PLACEHOLDER}
                    dropdownLabel={ADD_USERS_DROPDOWN_LABEL}
                    checkboxListHeader={ADD_USERS_CHECKBOX_LIST_HEADER}
                    handleDropdownChange={handleDropdownChange}
                    onCheckboxListChange={handleCheckboxListChange}
                    defaultOptions={options}
                    isSearchable
                    infiniteScroll
                    dataTestId='add-users-checkbox-list-dropdown'
                />
            </div>
            <div className='add-users-buttons-container'>
                <Button
                    handleOnClick={handleBack}
                    label={ButtonLabels.Back}
                    type={ButtonTypes.Secondary}
                    size={ButtonSizes.Medium}
                    dataTestId='button-step-back'
                />
                <div className='add-users-save-button'>
                    <Button
                        handleOnClick={handleSubmit(onSubmit)}
                        label={ButtonLabels.Next}
                        type={ButtonTypes.Primary}
                        size={ButtonSizes.Medium}
                        dataTestId='button-step-next'
                    />
                </div>
            </div>
        </form>
    );
};